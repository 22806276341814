/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const AVAILABLE_ROLES = {
	SYSTEM_ADMINISTRATOR: { id: 1, listPosition: 1 },
	ADMINISTRATOR: { id: 2, listPosition: 2 },
	BUSINESS_UNIT_MANAGER: { id: 3, listPosition: 3 },
	USERS_MANAGER: { id: 4, listPosition: 4 },
	CSC_APPROVERS: { id: 5, listPosition: 5 },
	CSC_USER: { id: 6, listPosition: 6 },
	COLLABORATION_PROPOSAL_USER: { id: 7, listPosition: 7 },
	FORMS_USER: { id: 8, listPosition: 8 },
	SKILLS_USER: { id: 9, listPosition: 9 },
	BUDGET_ADMINISTRATOR: { id: 10, listPosition: 10 },
	BUDGET_USER: { id: 11, listPosition: 11 },
	ADMISSIONS_USER: { id: 12, listPosition: 12 },
	ACCESS_CONTROLLER: { id: 13, listPosition: 13 },
	SKILLS_ADMINISTRATOR: { id: 14, listPosition: 14 },
	RESUMES_USER: { id: 15, listPosition: 15 },
	PLANNER_USER: { id: 16, listPosition: 16 },
	BOOKINGS_ADMINISTRATOR: { id: 17, listPosition: 17 }
};

// ADMISSION MANAGEMENT STATUS HELPER
export const availableRoles = (): any[] => [
	{
		id: AVAILABLE_ROLES.SYSTEM_ADMINISTRATOR.id,
		listPosition: AVAILABLE_ROLES.SYSTEM_ADMINISTRATOR.listPosition,
		name: 'systemAdministrator',
		label: 'SYSTEM ADMINISTRATOR'
	},
	{
		id: AVAILABLE_ROLES.ADMINISTRATOR.id,
		listPosition: AVAILABLE_ROLES.ADMINISTRATOR.listPosition,
		name: 'administrator',
		label: 'ADMINISTRATOR'
	},
	{
		id: AVAILABLE_ROLES.BUSINESS_UNIT_MANAGER.id,
		listPosition: AVAILABLE_ROLES.BUSINESS_UNIT_MANAGER.listPosition,
		name: 'businessUnitManager',
		label: 'BUSINESS UNIT MANAGER'
	},
	{
		id: AVAILABLE_ROLES.USERS_MANAGER.id,
		listPosition: AVAILABLE_ROLES.USERS_MANAGER.listPosition,
		name: 'usersManager',
		label: 'USERS MANAGER'
	},
	{
		id: AVAILABLE_ROLES.CSC_APPROVERS.id,
		listPosition: AVAILABLE_ROLES.CSC_APPROVERS.listPosition,
		name: 'cscApprovers',
		label: 'CSC APPROVERS'
	},
	{
		id: AVAILABLE_ROLES.CSC_USER.id,
		listPosition: AVAILABLE_ROLES.CSC_USER.listPosition,
		name: 'cscUser',
		label: 'CSC USER'
	},
	{
		id: AVAILABLE_ROLES.COLLABORATION_PROPOSAL_USER.id,
		listPosition: AVAILABLE_ROLES.COLLABORATION_PROPOSAL_USER.listPosition,
		name: 'collaborationProposalUser',
		label: 'COLLABORATION PROPOSAL USER'
	},
	{
		id: AVAILABLE_ROLES.FORMS_USER.id,
		listPosition: AVAILABLE_ROLES.FORMS_USER.listPosition,
		name: 'formsUser',
		label: 'FORMS USER'
	},
	{
		id: AVAILABLE_ROLES.SKILLS_USER.id,
		listPosition: AVAILABLE_ROLES.SKILLS_USER.listPosition,
		name: 'skillsUser',
		label: 'SKILLS USER'
	},
	{
		id: AVAILABLE_ROLES.BUDGET_ADMINISTRATOR.id,
		listPosition: AVAILABLE_ROLES.BUDGET_ADMINISTRATOR.listPosition,
		name: 'budgetAdministrator',
		label: 'BUDGET ADMINISTRATOR'
	},
	{
		id: AVAILABLE_ROLES.BUDGET_USER.id,
		listPosition: AVAILABLE_ROLES.BUDGET_USER.listPosition,
		name: 'budgetUser',
		label: 'BUDGET USER'
	},
	{
		id: AVAILABLE_ROLES.ADMISSIONS_USER.id,
		listPosition: AVAILABLE_ROLES.ADMISSIONS_USER.listPosition,
		name: 'admissionsUser',
		label: 'ADMISSIONS USER'
	},
	{
		id: AVAILABLE_ROLES.ACCESS_CONTROLLER.id,
		listPosition: AVAILABLE_ROLES.ACCESS_CONTROLLER.listPosition,
		name: 'accessController',
		label: 'ACCESS CONTROLLER'
	},
	{
		id: AVAILABLE_ROLES.SKILLS_ADMINISTRATOR.id,
		listPosition: AVAILABLE_ROLES.SKILLS_ADMINISTRATOR.listPosition,
		name: 'skillsAdministrator',
		label: 'SKILLS ADMINISTRATOR'
	},
	{
		id: AVAILABLE_ROLES.RESUMES_USER.id,
		listPosition: AVAILABLE_ROLES.RESUMES_USER.listPosition,
		name: 'resumesUser',
		label: 'RESUMES USER'
	},
	{
		id: AVAILABLE_ROLES.PLANNER_USER.id,
		listPosition: AVAILABLE_ROLES.PLANNER_USER.listPosition,
		name: 'plannerUser',
		label: 'PLANNER USER'
	},
	{
		id: AVAILABLE_ROLES.BOOKINGS_ADMINISTRATOR.id,
		listPosition: AVAILABLE_ROLES.BOOKINGS_ADMINISTRATOR.listPosition,
		name: 'bookingsAdministrator',
		label: 'BOOKINGS ADMINISTRATOR'
	}
];
