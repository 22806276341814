import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getAppRoles, deleteAppRoleById } from 'store/administration/administration/thunks';
import { AppState } from 'typings/state';

import Roles from './Roles';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getAppRoles,
			deleteAppRoleById
		},
		dispatch
	);

export type RolesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
