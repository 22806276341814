//* EXTERNAL LIBS
import React, { useState, useCallback, Dispatch, SetStateAction, FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
//* EXTERNAL LIBS --> Mobiscroll
import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	Datepicker,
	Segmented,
	SegmentedGroup,
	momentTimezone,
	setOptions
} from '@mobiscroll/react';
import moment from 'moment-timezone';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay } from 'xpand-ui/core';
//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';
import { ITitle } from 'components/App/TitleProvider';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyTimeoffProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './MyTimeoff.css';

//* COMPONENT INTERFACES
// Interface for MyTimeoff component props
interface IMyTimeoff extends MyTimeoffProps {
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	title: ITitle;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}

// Set Mobiscroll options
setOptions({
	theme: 'material',
	themeVariant: 'light'
});

//* COMPONENT
const MyTimeoff: FC<IMyTimeoff> = ({ personalInfo }) => {
	const classes = useStyles();
	momentTimezone.moment = moment;
	// const { loading, error } = personalInfo;

	// Available Credits for user timeoffs
	const [availableCredits, setAvailableCredits] = useState(168);

	// useState used for all timeOff ranges and customize style of any cell
	const [ranges, setRanges] = useState([
		{
			recurring: {
				repeat: 'weekly',
				weekDays: 'SA,SU'
			},
			cellCssClass: 'weekend'
		}
	]);

	//----------- INVALID EXAMPLE = disable date-----------
	const [invalids, setInvalids] = useState([
		{
			recurring: {
				repeat: 'yearly',
				day: 1,
				month: 1
			}
		}
		// ,{
		// 	recurring: {
		// 		repeat: 'weekly',
		// 		weekDays: 'SA,SU'
		// 	}
		// }
	]);

	//------------- Exemple of Label-------------
	const [labels, setLabels] = useState([
		{
			date: new Date(2024, 0, 1),
			text: 'New Year'
		}
	]);

	// ----------MARKED EXAMPLE = LITTLE DOT BELLOW THE DATE - Just appear where there are no labels on component-------------
	// const [marked, setMarked] = useState([
	// 	{
	// 		date: new Date(2024, 3, 3),
	// 		color: '#FAC15F'
	// 	}
	// ]);

	//--------EXAMPLE TWO MONTHS VIEW --------------
	// const [calendarType, setCalendarType] = useState('year');
	// const changeView = useCallback(event => {
	// 	setCalendarType(event.target.value);
	// }, []);

	function isTwoDaysAfter(firstDate: Date, secondDate: Date): boolean {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		const differenceInDays = Math.round((secondDate.getTime() - firstDate.getTime()) / millisecondsPerDay);
		return differenceInDays >= 2;
	}

	// Handle change event for Datepicker
	const onChange = useCallback(event => {
		// Just After the Second Date is selected
		if (event.value[1] !== null) {
			const startDate = new Date(event.value[0]);
			const endDate = new Date(event.value[1]);
			endDate.setDate(endDate.getDate() - 1);
			const startEvent = { date: new Date(event.value[0]), highlight: '#FAC15F', cellCssClass: '' };
			const endEvent = { date: endDate, cellCssClass: 'end-range', highlight: '#FAC15F' };

			if (startDate.getTime() !== endDate.getTime()) {
				startEvent.cellCssClass = 'start-range';
			}

			setRanges(prevData => {
				const newData = [...prevData, startEvent];
				if (startDate.getTime() !== endDate.getTime()) {
					newData.push(endEvent);
				}

				if (isTwoDaysAfter(startEvent.date, endDate)) {
					const rangeStart = new Date(startEvent.date);
					rangeStart.setDate(rangeStart.getDate() + 1);
					const rangeEnd = new Date(endDate);
					rangeEnd.setDate(rangeEnd.getDate());
					newData.push({
						start: rangeStart,
						end: rangeEnd,
						cellCssClass: 'range'
					});
				}

				return newData;
			});
		}
	}, []);

	// Render header for Datepicker
	const calendarHeader = useCallback(
		() => (
			<>
				<Grid container direction="row" alignItems="center" className={classes.totalHours}>
					<Grid item xs={9}>
						<Typography className={classes.availableCredits}>
							Available credits <span className={classes.creditNumber}>{availableCredits}</span>{' '}
						</Typography>
					</Grid>
					<Grid item container direction="row" xs={3} justifyContent="flex-end">
						<Grid item className={classes.calendarNavs}>
							<CalendarPrev />
						</Grid>
						<Grid item className={classes.calendarNavs}>
							<CalendarNext />
						</Grid>
						<Grid item>
							<CalendarNav />
						</Grid>
					</Grid>
				</Grid>
				{/* EXAMPLE TWO MONTHS VIEW */}
				{/* <SegmentedGroup value={calendarType} onChange={changeView}>
					<Segmented value="twoMonths">Two Months</Segmented>
					<Segmented value="year">Year</Segmented>
				</SegmentedGroup> */}
			</>
		),
		[]
		// [calendarType]
	);
	// const isLoading = weekViewData === null || loading || loadAfterMove;

	// MyTimeoff component return
	return (
		// 	{isLoading && <LoadingOverlay />}
		<Datepicker
			controls={['calendar']}
			select="range"
			display="inline"
			dataTimezone="utc"
			displayTimezone="Europe/London"
			timezonePlugin={momentTimezone}
			// EXAMPLE TWO MONTHS VIEW
			// calendarType={calendarType === 'year' ? 'year' : 'month'}
			// calendarSize={calendarType === 'year' ? 1 : 2}
			calendarType={'year'}
			calendarSize={1}
			renderCalendarHeader={calendarHeader}
			dayNamesMin={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
			firstDay={1}
			showRangeLabels={false}
			onChange={onChange}
			colors={ranges}
			invalid={invalids}
			labels={labels}
			// marked={marked}
		/>
	);
};

export default withLayout(MyTimeoff);
