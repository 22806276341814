/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { LoadingOverlay, ISidebarGroup } from 'xpand-ui/core';
import { Page, ErrorPage } from 'xpand-ui/lab';

import { getLSField, removeLSField } from 'lib/utils/cookies';
import { ENVIRONMENT, RELEASE_VERSION, START_API_PATH } from 'lib/utils/constants';
import Routes from 'lib/routes';
import { generic, personalInfo, administration } from 'lib/routes/sidebar';
import { IAzureAccount } from 'lib/hocs/ProjectProviders/@types';

import { clearCacheLogout, useIdToken } from 'pages/General/Login/utils';

import { pca } from 'lib/hocs/AzureMSAL/authProvider';
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useDispatch, useSelector } from 'react-redux';
import { pages } from 'lib/routes/pages';
import { getSystemSkills } from 'store/personalInfo/thunks';
import { INSTANCE } from 'lib/utils/constants';
import { addNotification } from 'lib/utils/notifications';
import { getTitle, updateUserInfo, checkAvailableMenu, loadAccountInfo } from './utils';
import { FCProps } from './index';
import { TitleContext, ITitle, IPageTitle } from './TitleProvider';
// SVG TITLE STYLES

const App: FC<FCProps> = ({
	router,
	system,
	company,
	notifications,
	users,
	checkUserPermissions,
	clearCache,
	setCompanyInformation,
	setClearAllFilters,
	getUserNotifications,
	sendPostImpersonateEmail,
	getUserNotificationsTimesheets
}) => {
	const [title, setTitle] = useState<ITitle>({ title: '', icon: '' });
	const { userPermissions, isExternal } = system;
	const { notificationsList } = notifications;
	const { accounts } = useMsal();
	const currentAccount = pca.getAccountByUsername(accounts.username);
	const [accountInfo, setAccountInfo] = useState<IAzureAccount>();
	const [notificationStarted, setNotificationStarted] = useState(false);
	const skills = useSelector(state => state.personalInfo.systemSkills);
	const dispatch = useDispatch();

	// Get idToken to guarantee user was logged in succesfully
	const isAuthed = useIdToken();

	useEffect(() => {
		if (!accountInfo) {
			setAccountInfo(loadAccountInfo());
		}
	});

	useEffect(() => {
		if (accountInfo && isAuthed) {
			getUserNotifications(accountInfo.username);
			getUserNotificationsTimesheets(accountInfo.username);
		}
	}, [accountInfo, isAuthed]);

	useEffect(() => {
		if (accountInfo) {
			getUserNotifications(accountInfo?.username);
			getUserNotificationsTimesheets(accountInfo.username);
		}
	}, []);

	useEffect(() => {
		if (accountInfo && !notificationStarted) {
			const intervalId = setInterval(() => {
				if (accountInfo) {
					getUserNotifications(accountInfo.username);
				}
			}, 30000);
			const intervalId2 = setInterval(() => {
				if (accountInfo) {
					getUserNotificationsTimesheets(accountInfo.username);
				}
			}, 600000);
			setNotificationStarted(true);
		}
	}, [accountInfo]);

	const logoutUser = async () => {
		clearCacheLogout();
		await pca.logoutRedirect({
			account: currentAccount
		});
	};

	const clearFilters = async () => {
		setClearAllFilters();
		addNotification('success', 'All your filters were cleared successfully', 5);
	};

	const closeImpersonate = async () => {
		const obj = JSON.parse(getLSField('impersonate_userInfo')!);
		removeLSField('impersonate_userInfo');
		removeLSField('impersonate_userActions');
		removeLSField('impersonate_userPermissions');
		sendPostImpersonateEmail('ended', obj.username);
		addNotification('success', 'Impersonate was successfully closed!', 5);
		setTimeout(function () {
			goToPage('/apps');
			window.location.reload();
		}, 2000);
	};
	const userPermStorage = getLSField('impersonate_userPermissions')
		? getLSField('impersonate_userPermissions')
		: getLSField('userPermissions');

	let userPerms = (userPermStorage && JSON.parse(userPermStorage)) || null;
	// User is considered to be external when Azure Company field does not contain value on INSTANCE environment variable
	const isExternalUser = getLSField('impersonate_userPermissions') ? false : isExternal;

	const providerValue = useMemo<IPageTitle>(() => ({ title, setTitle }), [title, setTitle]);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	updateUserInfo(isAuthed!, accountInfo!);

	// Check If Impersonate Time has expired
	const impersonate_userInfo = getLSField('impersonate_userInfo');
	let imp_userInfo = (impersonate_userInfo && JSON.parse(impersonate_userInfo)) || null;
	if (imp_userInfo?.expires != null) {
		if (imp_userInfo.expires < new Date().getTime()) {
			closeImpersonate();
		}
	}

	/* Setting the title of the page. */
	useEffect(() => {
		setTitle(getTitle(router.location.pathname) || '');
	}, [router.location.pathname]);
	/* Checking if the user is logged in and if the user has permissions. */
	useEffect(() => {
		if (isAuthed && accountInfo && !userPermissions) {
			checkUserPermissions(system);
		}
	}, [checkUserPermissions, isAuthed, accountInfo, userPermissions]);

	useEffect(() => {
		if (userPerms === null) userPerms = userPermissions;
	}, [userPermissions]);

	useEffect(() => {
		if (isAuthed && !skills && !isExternalUser) {
			dispatch(getSystemSkills());
		}
	}, [isAuthed, skills, isExternalUser]);

	//Responsable to check the user company and change the logos respectivly, dynamizing cp
	useEffect(() => {
		const userInfo = sessionStorage.getItem('auxStorage');
		//const userCompany = JSON.parse(userInfo!);
		if (!INSTANCE.toLowerCase().includes('xpand')) {
			// if (userCompany?.idTokenClaims?.companyName?.toLowerCase().includes('balwurk')) {
			setCompanyInformation();
		}
	}, []);
	const [personalInfoEdited, setPersonalInfo] = useState<ISidebarGroupItem[]>();

	//Loads personalInfo menu in order to be changed
	useEffect(() => {
		setPersonalInfo(personalInfo);
	}, []);

	useEffect(() => {
		for (let menu in personalInfoEdited) {
			//If the menu has the path /skills then it resets the his submenu and adds the created/deleted/edited skills
			if (personalInfoEdited[menu].path === '/profile') {
				for (let menu2 in personalInfoEdited[menu].subMenu) {
					if (personalInfoEdited[menu].subMenu[menu2].path === '/profile/skills') {
						personalInfoEdited[menu].subMenu[menu2].subMenu = [];

						for (let page in pages) {
							if (pages[page].path.includes('/skills/')) {
								personalInfoEdited[menu].subMenu[menu2].subMenu = [
									...personalInfoEdited[menu].subMenu[menu2].subMenu,
									{
										label: pages[page].label,
										path: pages[page].path,
										roles: pages[page].roles,
										submenuLevel: 3
									}
								];
							}
						}
					}
				}
			}
		}
	}, [skills]);
	const history = useHistory();
	const goToPage = (path: string) => history.push(path);

	// user actions for the userAvatar in the top right in the navbar
	const userActions = getLSField('impersonate_userInfo')
		? [
				{
					label: 'Profile',
					action: () => {
						goToPage('/profile/personalFiscal');
					}
				},
				{
					label: 'Close Impersonate',
					action: () => {
						closeImpersonate();
					}
				}
		  ]
		: [
				{
					label: 'Profile',
					action: () => {
						goToPage('/profile/personalFiscal');
					}
				},
				{
					label: 'Logout',
					action: logoutUser
				},
				{
					label: 'Refresh User Data',
					action: clearCache
				},
				{
					label: 'Clear All Filters',
					action: clearFilters
				}
		  ];

	// sidebar items groups
	const sidebarGroups: ISidebarGroup[] = useMemo(
		() => [
			{
				id: 'generic',
				groupName: '',
				items:
					checkAvailableMenu(
						generic,
						userPerms,
						userPerms &&
							userPerms.length > 0 &&
							(userPerms[0].name === 'SYSTEM ADMINISTRATOR' ||
								userPerms.some(item => item.name === 'EXTERNAL USER FREE'))
							? false
							: isExternalUser
					) || []
			},
			{
				id: 'personalInfo',
				groupName: '',
				items:
					checkAvailableMenu(
						personalInfo,
						userPerms,
						userPerms &&
							userPerms.length > 0 &&
							(userPerms[0].name === 'SYSTEM ADMINISTRATOR' ||
								userPerms.some(item => item.name === 'EXTERNAL USER FREE'))
							? false
							: isExternalUser
					) || []
			},
			{
				id: 'administration',
				groupName: '',
				items:
					checkAvailableMenu(
						administration,
						userPerms,
						userPerms &&
							userPerms.length > 0 &&
							(userPerms[0].name === 'SYSTEM ADMINISTRATOR' ||
								userPerms.some(item => item.name === 'EXTERNAL USER FREE'))
							? false
							: isExternalUser
					) || []
			}
		],
		[userPerms]
	);

	const getPhoto = () => {
		const userInfoStorage = getLSField('impersonate_userInfo');
		let userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
		if (userInfo?.username) {
			return `${START_API_PATH}/admin/users/${userInfo.username}/photo`;
		} else {
			return `${START_API_PATH}/user/photo`;
		}
	};

	// const notifications = [{ id: '1', area: 'My Profile', isRead: false }];
	const pageProps = useMemo(
		() => ({
			version: RELEASE_VERSION,
			title,
			goToPage,
			avatar: getPhoto(),
			currentPath: router.location.pathname,
			renderNavbar: Boolean(isAuthed),
			sidebarGroups,
			userActions,
			notifications: notificationsList,
			homePage: '/cp/apps',
			url: company.companyLogo,
			urlSmall: company.smallCompanyLogo,
			companyName: company.companyText,
			cpEnvironment: ENVIRONMENT
		}),
		[router, isAuthed, title, sidebarGroups, notificationsList]
	);

	const AppLoading = isAuthed ? !userPerms : false;

	return (
		<>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				{isAuthed && (
					<Page {...pageProps}>
						{AppLoading || users.loading ? (
							<LoadingOverlay />
						) : (
							// @ts-ignore
							<ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[router.location.pathname]}>
								<TitleContext.Provider value={providerValue}>
									<Routes />
								</TitleContext.Provider>
							</ErrorBoundary>
						)}
					</Page>
				)}
			</MsalAuthenticationTemplate>
		</>
	);
};

export default App;
