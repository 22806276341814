import { createReducer } from 'typesafe-actions';
import { ProposalActions as Actions, ProposalState } from 'typings/store/admin/proposals';

const INITIAL_STATE: ProposalState = {
	loading: false,
	error: null,
	proposalsList: null,
	userProposalsList: null,
	newProposal: null,
	proposalsActions: null,
	sendEmailPayload: null,
	proposalDownload: null,
	proposalAnnualGross: null,
	proposalSummary: null,
	irsValue: null,
	proposalAuditLog: null
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const proposalActions = (state: any, { payload }: any) => ({
	...state,
	...INITIAL_STATE,
	proposalsActions: payload.payload
});

const ProposalsReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_PROPOSALS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalsList: payload,
		proposalsActions: null
	}),
	[Actions.GET_USER_PROPOSALS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		...(payload.token
			? { userProposalsList: { ...state.userProposalsList, [payload.token]: payload.payload } }
			: { userProposalsList: null })
	}),
	// PROPOSAL ACTION
	[Actions.SET_PROPOSAL_SEND_EMAIL]: (state, { payload }) => ({
		...state,
		loading: false,
		sendEmailPayload: payload,
		proposalsActions: null
	}),
	[Actions.SET_POST_PROPOSAL_SEND_EMAIL]: proposalActions,
	[Actions.SET_PROPOSAL_ACCEPT]: proposalActions,
	[Actions.SET_PROPOSAL_REFUSE]: proposalActions,
	[Actions.SET_PROPOSAL_CLOSE]: proposalActions,
	[Actions.SET_PROPOSAL_CANCEL]: proposalActions,
	[Actions.SET_PROPOSAL_REQUEST_APPROVAL]: proposalActions,
	[Actions.SET_PROPOSAL_APPROVE]: proposalActions,
	[Actions.SET_PROPOSAL_REJECT]: proposalActions,
	// PROPOSAL FORM GETS
	[Actions.GET_NEW_PROPOSAL]: (state, { payload }) => ({
		...state,
		loading: false,
		newProposal: payload,
		proposalsActions: null
	}),
	[Actions.GET_NEW_PROPOSAL_WITH_TOKEN]: (state, { payload }) => ({
		...state,
		loading: false,
		newProposal: payload,
		proposalsActions: null
	}),
	[Actions.GET_EDIT_PROPOSAL]: (state, { payload }) => ({
		...state,
		loading: false,
		newProposal: payload,
		proposalsActions: null
	}),

	// PROPOSAL FORM SUBMITS
	[Actions.SUBMIT_NEW_PROPOSAL]: (state, { payload }) => ({
		...state,
		...INITIAL_STATE,
		proposalsActions: payload
	}),
	[Actions.SUBMIT_PUT_UPDATE_PROPOSAL]: (state, { payload }) => ({
		...state,
		...INITIAL_STATE,
		proposalsActions: payload
	}),

	// CALCULATIONS
	[Actions.SET_PROPOSAL_ANNUAL_GROSS_VALUE]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalAnnualGross: payload
	}),
	[Actions.SET_IRS_VALUE]: (state, { payload }) => ({ ...state, loading: false, irsValue: payload }),

	// SUMMARY
	[Actions.SET_PROPOSAL_SUMMARY]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalSummary: payload
	}),
	[Actions.SET_EXISTING_PROPOSAL_SUMMARY]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalSummary: payload
	}),
	[Actions.CLEAR_PROPOSAL_SUMMARY]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalSummary: payload
	}),
	[Actions.SET_PROPOSAL_EXPORT]: state => ({
		...state,
		loading: false,
		proposalDownload: null
	}),
	[Actions.SET_PROPOSAL_EXPORT_WITH_ID]: state => ({
		...state,
		loading: false,
		proposalDownload: null
	}),
	[Actions.RESET_FORM_DATA]: state => ({
		...state,
		loading: false,
		newProposal: null,
		proposalsActions: null
	}),
	[Actions.GET_PROPOSAL_AUDIT_LOG]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalAuditLog: payload
	})
});

export default ProposalsReducer;
