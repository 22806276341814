import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	animation: {
		visibility: 'hidden',
		opacity: '0',
		height: 0,
		padding: '0 !important',
		transition: 'all 0.3s ease'
	},
	show: {
		visibility: 'visible',
		height: 'auto',
		padding: '40px !important',
		opacity: 1
	},
	iconGrayed: {
		color: theme.inactiveText,
		fontSize: '12px'
	},
	actions: {
		display: 'flex',
		flexDirection: 'row-reverse',
		minWidth: '900px',
		width: '100%',
		maxWidth: '83%',
		margin: 'auto'
	}
}));

export { useStyles };
