//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, TabPanel, FormSteps } from 'xpand-ui/core';

//* TYPINGS
import {
	ICollaborationProposal,
	IProposalFormCareerHelper,
	IProposalPayload,
	IReferenceData,
	IProposalProject
} from 'typings/store/admin/proposals';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';
//*
import CareerFinancial from 'pages/Admin/_ProposalsFormUtils/CareerFinancial';
import ContractCompany from 'pages/Admin/_ProposalsFormUtils/ContractCompany';
import OtherBenefits from 'pages/Admin/_ProposalsFormUtils/OtherBenefits';
import { PROPOSAL } from 'pages/Admin/_ProposalsFormUtils/constants';
import Projects from 'pages/Admin/_ProposalsFormUtils/Projects';
import General from 'pages/Admin/_ProposalsFormUtils/General';
import Summary from 'pages/Admin/_ProposalsFormUtils/Summary';
import {
	getInitialFoodAllowanceValues,
	proposalsGetSelectOptions,
	proposalsGetFixedSelectOptions,
	updateSelectsInCascade,
	getDefaultProjectsList,
	setValuesFromProposal,
	getProposalApiMethods,
	getMissingFieldsNames
} from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { createYupSchema, createDefaultValues } from './yupSchema';

import { useStyles } from './styles';
import { NewProposalProps } from '.';
import {
	BALWURK_CONTRACTOR_ID,
	INSTANCE,
	XPAND_CONTRACTOR_ID,
	XPAND_CONTRACTOR_ID_SE,
	XPAND_CONTRACTOR_ID_DE,
	XPAND_CONTRACTOR_ID_UK,
	XPAND_CONTRACTOR_ID_HR
} from 'lib/utils/constants';
import { getLSField } from 'lib/utils/cookies';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import { useDispatch, useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface INewProposal extends NewProposalProps {
	match: Match<{
		token: string;
		proposalId: string;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const NewProposal: FC<INewProposal> = ({
	match,
	goToPage,
	proposals,
	getNewProposal,
	getNewProposalWToken,
	submitNewProposal,
	getProposalAnnualGrossValue,
	getIrsValue,
	getEditProposal,
	submitUpdateProposal,
	clearProposalsFormsData,
	clearProposalsError,
	company
}) => {
	const classes = useStyles();
	const { loading, newProposal, proposalsActions, proposalAnnualGross, irsValue, error } = proposals;
	const { defaultCompanyKey, companyDomain } = company;
	const { params } = match;
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const pageToken = params?.token;
	const isEdit = params?.proposalId;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const [lastFieldChanged, setLastFieldChanged] = useState({ field: '', value: '' });
	const [isContractor, setIsContractor] = useState('Employee');
	const [isEditProposalLoaded, setIsEditProposalLoaded] = useState(false);
	const [currentYupSchema, setCurrentYupSchema] = useState(() => createYupSchema('PT'));
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() => createDefaultValues('PT'));
	const [isSubmitted, setIsSubmitting] = useState(false);
	const dispatch = useDispatch();
	const [tabs, setTabs] = useState([
		{
			id: 0,
			label: 'General'
		},
		{
			id: 1,
			label: 'Career & Financial'
		},
		{
			id: 2,
			label: 'Other Benefits'
		},
		{
			id: 3,
			label: 'Contract & Company'
		},
		{
			id: 4,
			label: 'Projects'
		},
		{
			id: 5,
			label: 'Summary'
		}
	]);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const {
		handleSubmit,
		control,
		formState: { errors, touchedFields },
		reset,
		resetField,
		watch,
		setValue,
		getValues,
		clearErrors,
		trigger
	} = useForm<IProposalPayload>({
		mode: 'onTouched',
		resolver: yupResolver(currentYupSchema),
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});

	//* when the proposal is submitted and there is no error, redirect to the corresponding page
	useEffect(() => {
		if (!error && proposalsActions && !proposalsActions.code && isSubmitted) {
			goToPage(`/admin/recruitment/proposals${pageToken ? `/${pageToken}` : ''}`);
		}
	}, [proposalsActions]);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [errorCheck, setErrorCheck] = useState(false);
	const [selectHelper, setSelectHelper] = useState<IProposalFormCareerHelper>({
		company: defaultCompanyKey,
		businessUnit: currentDefaultValues?.businessUnit,
		startDate: currentDefaultValues?.startDate,
		division: currentDefaultValues?.division,
		department: currentDefaultValues?.department,
		careerPosition: currentDefaultValues?.careerPosition,
		careerJobRoleLevel: currentDefaultValues?.careerJobRoleLevel,
		careerJobRole: currentDefaultValues?.careerJobRole
	});

	// UseEffect required to get the new formFields, avoiding null errors.
	useEffect(() => {
		if (watch('company') !== null && watch('company') !== '') {
			dispatch(getFormFieldsConfig(isContractor, watch('company'), 'Collaboration Proposal'));
		}
	}, [watch('company'), isContractor]);

	const selectedCompany = useMemo(() => {
		if (!newProposal || newProposal?.code) {
			return undefined;
		}
		return selectHelper.company
			? newProposal.companies.find(company => company.searchKey === selectHelper.company)
			: undefined;
	}, [newProposal, selectHelper.company]);

	const referenceData: IReferenceData | null = useMemo(
		() => selectedCompany?.referenceData || null,
		[selectedCompany]
	);

	// UseEffect required to adjust the schemas and defaultValues whenever the company changes
	useEffect(() => {
		if (formFields) {
			setCurrentYupSchema(createYupSchema(formFields?.formFieldsConfig?.country));
			setCurrentDefaultValues(createDefaultValues(formFields?.formFieldsConfig?.country, referenceData));
		}
	}, [formFields, referenceData]);

	// UseEffect required to reset the form fields.
	useEffect(() => {
		if (!isEdit) {
			reset({
				...currentDefaultValues,
				company: watch('company'),
				employeeCategory: watch('employeeCategory')
			});
		}
	}, [currentDefaultValues]);

	const [initialBaseValue, setInitialBaseValue] = useState(watch().baseValue);

	/** //* GET API CALCULATIONS HELPERS
	 * @function initFinancialValues CALCULATE TAXABLE / GROSS VALUES / TWELFTHS
	 * @function updateIRSTax CALCULATE IRS
	 * @function getAnnualGrossValue
	 * @function getCareerFields CAREER NAMES HELPERS
	 */
	const { initFinancialValues, updateIRSTax, getAnnualGrossValue, getCareerFields } = getProposalApiMethods(
		reset,
		getIrsValue,
		getProposalAnnualGrossValue
	);

	useEffect(() => {
		if (Object.keys(errors).length > 0 && errorCheck) {
			const { names } = getMissingFieldsNames(errors, isContractor);

			addNotification('danger', `The following required fields are required: ${names.join(', ')}`, 5);

			setErrorCheck(false);
		}
	}, [errorCheck, errors]);

	/** Set food Allowance value when foodAllowance type changes
	 * @onchange of the following fields
	 * @field1 - foodAllowanceType
	 */
	const updateFoodAllowanceValue = useMemo(
		() => (data: IProposalPayload) => {
			if (referenceData) {
				const { foodAllowanceType, foodAllowance } = data;
				setValue(
					'foodAllowance',
					foodAllowanceType === 'Cash'
						? foodAllowance || referenceData.xphrFoodAllowValue
						: foodAllowance || referenceData.xphrCrFoodAllowValue
				);
			}
		},
		[referenceData]
	);

	const updateFormAfterLoad = (proposal: ICollaborationProposal) => {
		// assign helpers values
		setSelectHelper({
			company: proposal.company,
			businessUnit: proposal.businessUnit,
			division: proposal.division,
			department: proposal.department,
			startDate: proposal.startDate,
			careerPosition: proposal.careerPosition,
			careerJobRole: proposal.careerJobRole,
			careerJobRoleLevel: proposal.careerJobRoleLevel
		});

		const payload = watch();

		if (payload.prizeBonus === '0.00') {
			setValue('eligiblePrizeValue', 0, { shouldValidate: true });
			setValue('prizeBonusPercentage', 0, { shouldValidate: true });
			setValue('prizeBonusGoals', [], { shouldValidate: true });
		} else {
			setValue('eligiblePrizeValue', (Number(payload.prizeBonus) * Number(payload.monthsApplicable)) / 12 || 0, {
				shouldValidate: true
			});
			setValue(
				'prizeBonusPercentage',
				(Number(payload.prizeBonus) / (Number(payload.prizeBonus) + Number(payload.annualGrossValue))) * 100 ||
					0,
				{ shouldValidate: true }
			);
		}
		// load api related fields after form is filled
		initFinancialValues(watch());
		getAnnualGrossValue(watch());
		updateFoodAllowanceValue(watch());
		updateIRSTax(watch());
		setInitialBaseValue(watch().baseValue);
	};

	//* USE EFFECT TO LOAD INITIAL DATA
	useEffect(() => {
		if (!newProposal) {
			// load data on edit
			if (isEdit && !error) {
				getEditProposal(pageToken, isEdit);
				setIsEditProposalLoaded(true);
			} else if (pageToken && !error) {
				// load data on new with token
				getNewProposalWToken(pageToken);
			} else {
				// load data on new without token
				getNewProposal();
			}
		} else if (selectedCompany?.referenceData) {
			const formData = getValues();

			const defaultProjects = getDefaultProjectsList(
				formData.company,
				formData.division,
				newProposal.defaultProjects,
				newProposal.defaultDivisionProjects
			);
			reset({
				...formData,
				...getInitialFoodAllowanceValues(selectedCompany.referenceData),
				projects: newProposal?.projects
					?.filter(e => defaultProjects.includes(e.name))
					.map(e => ({ projectId: e.id, projectName: e.name }))
			});
			setValue('healthInsuranceValue', referenceData?.xphrMonStdHeaInsCost);
			if (isEdit) {
				if (typeof newProposal.proposal === 'undefined' || !isEditProposalLoaded) {
					getEditProposal(pageToken, isEdit);
					setIsEditProposalLoaded(true);
				} else {
					// update individually each field with the new data
					setValuesFromProposal(newProposal.proposal, reset, watch, true);

					if (
						newProposal.proposal.employeeCategory !==
						(XPAND_CONTRACTOR_ID ||
							XPAND_CONTRACTOR_ID_SE ||
							XPAND_CONTRACTOR_ID_DE ||
							XPAND_CONTRACTOR_ID_UK ||
							XPAND_CONTRACTOR_ID_HR ||
							BALWURK_CONTRACTOR_ID)
					) {
						// update the form selects and remake the calculations API requests
						updateFormAfterLoad(newProposal.proposal);
					} else {
						setValue('hourlyRate', newProposal.proposal.budget / 8 / 18, { shouldValidate: true });
						setValue('budget', newProposal.proposal.budget, { shouldValidate: true });
						setIsContractor('Contractor');
						setSelectHelper({
							company: newProposal.proposal.company,
							businessUnit: newProposal.proposal.businessUnit,
							division: newProposal.proposal.division,
							department: newProposal.proposal.department,
							startDate: newProposal.proposal.startDate,
							careerPosition: newProposal.proposal.careerPosition,
							careerJobRole: newProposal.proposal.careerJobRole,
							careerJobRoleLevel: newProposal.proposal.careerJobRoleLevel
						});
					}
				}
			}
		}
	}, [newProposal, pageToken]);

	useEffect(() => {
		if (newProposal) {
			// load data on edit
			if (!isEdit && !error && !pageToken) {
				// load data on new without token
				getNewProposal();
				reset(currentDefaultValues, {
					keepDirty: false,
					keepErrors: false,
					keepDirtyValues: false
				});
				setInitialBaseValue(watch().baseValue);
			}
		}
	}, [isEdit, pageToken]);

	//* USE EFFECT TO UPDATE FORM WHEN THE IRS IS CALCULATED
	useEffect(() => {
		if (irsValue) {
			setValue('irsToApply', irsValue.irs, { shouldValidate: true });
			setValue('annualPrizeIRS', Number(irsValue.irsPrize), { shouldValidate: true });
			setValue('irsTwelfth', irsValue.irsTwelfth, { shouldValidate: true });
		}
	}, [irsValue]);

	//* USE EFFECT TO FORCE THE UPDATE OF IRS TO 0 WHEN baseValue is 0 | "+" is used to convert the value of data.baseValue into a number
	useEffect(() => {
		if (+watch('baseValue') === 0) {
			setValue('irsToApply', 0, { shouldValidate: true });
			setValue('annualPrizeIRS', 0, { shouldValidate: true });
			setValue('irsTwelfth', 0, { shouldValidate: true });
		}
	}, [watch('baseValue')]);

	//* USE EFFECT TO UPDATE ANNUAL GROSS VALUE
	useEffect(() => {
		if (proposalAnnualGross) {
			const currentForm = watch();
			const { annualGrossValue } = proposalAnnualGross;
			setValue('annualGrossValue', annualGrossValue, { shouldValidate: true });
			setValue(
				'prizeBonusPercentage',
				(Number(currentForm.prizeBonus) / (Number(currentForm.prizeBonus) + Number(annualGrossValue))) * 100 ||
					0,
				{ shouldValidate: true }
			);
		}
	}, [proposalAnnualGross]);

	// ? SUBMIT PROPOSAL
	const onSubmit = (data: IProposalPayload) => {
		data.formFields = formFields.formFieldsConfig;
		if (data.company == 'PT' || data.company == 'BW') {
			if (isContractor === 'Contractor') {
				data.commsPlafond = 0;
				data.commsPlafondFlag = false;
				data.foodAllowance = 0;
				data.foodAllowanceDays = 0;
				data.hasTwelfths = false;
				data.healthInsuranceCheck = false;
				data.healthInsuranceValue = 0;
				data.ihtPercentage = 0;
				data.monthsApplicable = 0;
				data.otherbenefits = false;
				data.otherBenefitsList = [];
				data.additionalExpenses = '0';
				if (isEdit) {
					submitUpdateProposal(data, pageToken, Number(isEdit));
				} else {
					submitNewProposal(data, pageToken && pageToken.trim() !== '' && pageToken);
				}
			} else {
				if (isEdit) {
					submitUpdateProposal(data, pageToken, Number(isEdit));
				} else {
					submitNewProposal(data, pageToken && pageToken.trim() !== '' && pageToken);
				}
			}
		} else if (data.company == 'HR') {
			data.foodAllowance = 0;
			data.foodAllowanceDays = 0;
			data.hasTwelfths = false;
			data.christmasTwelfth = 0;
			data.vacationsTwelfth = 0;
			data.ihtPercentage = 0;
			data.ihtValue = 0;
			data.monthsApplicable = 0;
			data.additionalExpenses = '0';
			if (isContractor === 'Contractor') {
				data.otherbenefits = false;
				data.otherBenefitsList = [];
				data.hasTwelfths = false;
				data.commsPlafondFlag = false;
				data.commsPlafond = 0;
				data.healthInsuranceCheck = false;
				data.healthInsuranceValue = 0;
				if (isEdit) {
					submitUpdateProposal(data, pageToken, Number(isEdit));
				} else {
					submitNewProposal(data, pageToken && pageToken.trim() !== '' && pageToken);
				}
			} else {
				if (isEdit) {
					submitUpdateProposal(data, pageToken, Number(isEdit));
				} else {
					submitNewProposal(data, pageToken && pageToken.trim() !== '' && pageToken);
				}
			}
		}
		setIsSubmitting(true);
	};
	// SELECT FIELDS DEPENDENT OF COMPANY - CAREER NAMES
	const careerFields = useMemo(() => {
		const payload = getCareerFields(selectedCompany, selectHelper);

		if (selectHelper.department && payload.careerPosition?.length === 0 && proposalsActions === null) {
			addNotification('warning', 'There are no Careers for this department', 5);
		}
		return payload;
	}, [newProposal, selectedCompany, selectHelper]);

	// SELECT FIELDS DEPENDENT OF COMPANY
	const selectOptions = useMemo(
		() =>
			selectedCompany &&
			newProposal &&
			proposalsGetSelectOptions(classes, newProposal, selectHelper, selectedCompany),
		[newProposal, selectHelper, selectedCompany]
	);

	// FIXED SELECT FIELDS
	const selectFixedOptions = useMemo(() => newProposal && proposalsGetFixedSelectOptions(newProposal), [newProposal]);

	// UPDATE SELECT CHILD'S IN CASCADE WHEN NEEDED
	const handleSelectHelper = (field: string, value: string | null) => {
		updateSelectsInCascade(setSelectHelper, setValue, field, value);
		setLastFieldChanged({
			field: field,
			value: value!
		});
	};

	//Responsable to load project to the respective tab (Projects)
	useEffect(() => {
		//When company changes the base project are loaded
		if (lastFieldChanged.field === 'company' && !isEdit) {
			const defaultProjects = getDefaultProjectsList(
				selectedCompany?.searchKey as string,
				lastFieldChanged.value as string,
				newProposal?.defaultProjects as Record<string, string[]>,
				newProposal?.defaultDivisionProjects as Record<string, string[]>
			);
			setValue(
				'projects',
				newProposal?.projects
					?.filter(e => defaultProjects.includes(e.name))
					.map(e => ({ projectId: e.id, projectName: e.name })) as IProposalProject[]
			);
		}
		//When division is changed the base projects of the division are loaded
		if (lastFieldChanged.field === 'division' && !isEdit) {
			const defaultProjects = getDefaultProjectsList(
				selectedCompany?.searchKey as string,
				lastFieldChanged.value as string,
				newProposal?.defaultProjects as Record<string, string[]>,
				newProposal?.defaultDivisionProjects as Record<string, string[]>
			);
			setValue(
				'projects',
				newProposal?.projects
					?.filter(e => defaultProjects.includes(e.name))
					.map(e => ({ projectId: e.id, projectName: e.name })) as IProposalProject[]
			);
		}
	}, [lastFieldChanged]);

	const populateFormWithProposal = useMemo(
		() => (value: string | number) => {
			if (!selectOptions) return;
			if (selectOptions?.collaborationProposals) {
				// find selected proposal in list
				const { proposal } = selectOptions.collaborationProposals.find(
					(e: ICollaborationProposal) => e.id === value
				);

				// remove unwanted fields by destructuring them
				const { id, proposalId, token, name, email, collaborationType, ...proposalToCopy } = proposal;

				// update individually each field with the new data
				setValuesFromProposal(proposalToCopy, reset, watch, false);

				// update the form selects and remake the calculations API requests
				updateFormAfterLoad(proposalToCopy);
			}
		},
		[selectOptions]
	);

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'cancel',
				label: 'Cancel',
				left: true,
				onClick: () => {
					goToPage(`/admin/recruitment/proposals${pageToken ? `/${pageToken}` : ''}`);
					clearProposalsFormsData();
				},
				variant: 'outlined',
				disabled: false
			},
			...(tabSelected !== 0
				? [
						{
							id: 'previous',
							label: 'Previous',
							// left: true,
							onClick: () => {
								setTabSelected(tabSelected - 1);
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
							},
							variant: 'outlined',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected !== tabs.length - 1
				? [
						{
							id: 'next',
							label: 'Next',
							onClick: async () => {
								setTabSelected(tabSelected + 1);
								/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
								the array. */
								if (formWarnings.includes(tabSelected)) {
									setFormWarnings(formWarnings.filter(e => e !== tabSelected));
								}
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
								//VALIDATES THE PRIZEBONUSGOAL GROUP
								await trigger('prizeBonusGoals');
							},
							variant: 'contained',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected === tabs.length - 1
				? [
						{
							id: 'submit',
							label: 'Submit',
							onClick: () => ({}),
							type: 'submit',
							form: 'form-new-proposal',
							variant: 'contained',
							disabled: getLSField('impersonate_userInfo')
						}
				  ]
				: [])
		],
		[tabSelected, pageToken]
	);

	let hasBaseValueChanges = false;
	if (watch().baseValue !== initialBaseValue) {
		hasBaseValueChanges = true;
	}

	if (error) return handleErrorPage(error, clearProposalsError);
	const isLoading = newProposal === null || selectedCompany === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<form
				id="form-new-proposal"
				ref={scrollToTopRef}
				className={classes.pageForm}
				onSubmit={e => {
					e.preventDefault();
					setErrorCheck(true);
					return handleSubmit(() => onSubmit(watch()))(e);
				}}>
				<FormSteps steps={tabs} selected={tabSelected} errors={formWarnings} />
				<Scrollbars
					ref={scrollbarRef}
					style={{ height: 'calc(100vh - 300px)', overflowX: 'hidden' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					{tabs.map(tab => {
						const commonProps = {
							type: PROPOSAL,
							selectOptions: {
								...selectOptions,
								...careerFields,
								...selectFixedOptions
							},
							referenceData,
							getAnnualGrossValue,
							initFinancialValues,
							updateFoodAllowanceValue,
							populateFormWithProposal,
							handleSelectHelper,
							// react-hook-form helpers
							control,
							errors,
							reset,
							resetField,
							getValues,
							setValue,
							watch,
							clearErrors
						};

						return (
							<>
								{isContractor !== 'Contractor' ? (
									<TabPanel
										key={`proposal_tab_container_${tab.id}`}
										value={tabSelected}
										index={tab.id}
										className={
											tabSelected === 5 ? classes.tabSummaryContainer : classes.tabsContainer
										}>
										{tab.id === 0 && (
											<General
												{...commonProps}
												{...{ updateIRSTax }}
												{...{ isContractor }}
												{...{ setIsContractor }}
												{...{ currentDefaultValues }}
												{...{ isContractor }}
												{...{ setTabs }}
												{...{ formFields }}
												{...{ companyDomain }}
											/>
										)}
										{tab.id === 1 && (
											<CareerFinancial
												{...commonProps}
												{...{ updateIRSTax }}
												{...{ newProposal }}
												{...{ isContractor }}
												{...{ formFields }}
											/>
										)}
										{tab.id === 2 && isContractor !== 'Contractor' && (
											<OtherBenefits {...commonProps} {...{ updateIRSTax }} {...{ formFields }} />
										)}
										{tab.id === 3 && (
											<ContractCompany
												{...commonProps}
												{...{ formFields }}
												{...{ isContractor }}
											/>
										)}
										{tab.id === 4 && <Projects {...commonProps} {...{ formFields }} />}
										{tab.id === 5 && (
											<Summary
												additionalErrors={errors}
												handleWarning={(names: unknown, tabsWithWarn: number[]) =>
													setFormWarnings(tabsWithWarn)
												}
												{...commonProps}
												{...{ isContractor }}
												{...{ formFields }}
												isCollabType={false}
											/>
										)}
									</TabPanel>
								) : (
									<TabPanel
										key={`proposal_tab_container_${tab.id}`}
										value={tabSelected}
										index={tab.id}
										className={
											tabSelected === 4 ? classes.tabSummaryContainer : classes.tabsContainer
										}>
										{tab.id === 0 && (
											<General
												{...commonProps}
												{...{ updateIRSTax }}
												{...{ isContractor }}
												{...{ setIsContractor }}
												{...{ currentDefaultValues }}
												{...{ isContractor }}
												{...{ setTabs }}
												{...{ formFields }}
												{...{ companyDomain }}
											/>
										)}
										{tab.id === 1 && (
											<CareerFinancial
												{...commonProps}
												{...{ updateIRSTax }}
												{...{ newProposal }}
												{...{ isContractor }}
												{...{ formFields }}
											/>
										)}
										{tab.id === 2 && (
											<ContractCompany
												{...commonProps}
												{...{ isContractor }}
												{...{ formFields }}
											/>
										)}
										{tab.id === 3 && <Projects {...commonProps} />}
										{tab.id === 4 && (
											<Summary
												additionalErrors={errors}
												handleWarning={(names: unknown, tabsWithWarn: number[]) =>
													setFormWarnings(tabsWithWarn)
												}
												{...commonProps}
												{...{ isContractor }}
												{...{ formFields }}
												isCollabType={false}
											/>
										)}
									</TabPanel>
								)}
							</>
						);
					})}
				</Scrollbars>
			</form>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={isTouchedFieldsUsed(touchedFields, getValues) || hasBaseValueChanges}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default withLayout(NewProposal);
