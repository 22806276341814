import { getLSField } from 'lib/utils/cookies';

export enum Roles {
	//* SYSTEM
	SYS = 'SYSTEM ADMINISTRATOR',
	ADM = 'ADMINISTRATOR',
	AC = 'ACCESS CONTROLLER',
	BUM = 'BUSINESS UNIT MANAGER',

	//* USERS
	UM = 'USERS MANAGER',

	//* PROPOSALS / ESC / ADMISSIONS
	CPU = 'COLLABORATION PROPOSALS USER',
	CPA = 'COLLABORATION PROPOSALS ADMINISTRATOR',
	ADMU = 'ADMISSIONS USER',
	CSCU = 'CSC USER',
	CSCAP = 'CSC APPROVERS',

	//* CONTROL PANEL MY PAGES CONTROL
	FU = 'FORMS USER',
	SU = 'SKILLS USER',
	RU = 'RESUMES USER',
	SA = 'SKILLS ADMINISTRATOR',

	//* BUDGET
	BA = 'BUDGET ADMINISTRATOR',
	BU = 'BUDGET USER',

	//* PLANNER
	PLNU = 'PLANNER USER',

	//* BOOKINGS ADMINISTRATOR
	BOA = 'BOOKINGS ADMINISTRATOR',

	//* PAYROLL USER
	PU = 'PAYROLL USER',

	//* XTRAKCER USER
	XEU = 'XTRACKER EXPENSES USER'
}

interface Permissions {
	name: string;
}

export const actionPermission = (action: string): boolean => {
	const userPermStorage = getLSField('impersonate_userActions')
		? getLSField('impersonate_userActions')
		: getLSField('userActions');

	const userPerms = (userPermStorage && JSON.parse(userPermStorage)) || [];

	if (userPerms.some((e: Permissions) => e.name.toUpperCase() === 'SYSTEM ADMINISTRATOR')) return true;
	if (userPerms.some((e: Permissions) => e.name.toUpperCase() === 'ADMINISTRATOR')) return true;

	return userPerms.some((e: Permissions) => e.name.toLowerCase() === action.toLowerCase()); // true = hasPermission
};
