/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo, FC } from 'react';

import { LoadingOverlay, Table, Dialog, PageTitle, ActionFooter } from 'xpand-ui/core';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { START_API_PATH } from 'lib/utils/constants';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import GrantAccess from '../GrantAccess';
import { useStyles } from './styles';
import { AccessControlAddUserProps } from '.';

interface IAccessControlAddUser extends AccessControlAddUserProps {
	goToPage: (path: string) => void;
}

const AccessControlAddUser: FC<IAccessControlAddUser> = ({
	goToPage,
	administration,
	filters,
	getAccessControlListAddUser,
	clearAdministrationError,
	setAccessControlAddPageFilter
}) => {
	const classes = useStyles();
	const { accessControlListAddUser, loading, error } = administration;
	const [confirmModal, setConfirmModal] = useState(null);
	const { accessControlAddFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(accessControlAddFilter);

	useEffect(() => {
		setAccessControlAddPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!error && (!accessControlListAddUser || accessControlListAddUser?.users?.length === 0)) {
			getAccessControlListAddUser();
		}
	}, [accessControlListAddUser]);

	const handleCloseModal = () => {
		setConfirmModal(null);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				format: row => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'User',
				id: 'username'
			},
			{
				label: 'Name',
				id: 'name'
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'accessControlMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'accessControlMenuAdd',
						label: 'Add Roles and Accesses',
						onClick: row => setConfirmModal(row.id)
					}
				]
			}
		],
		[accessControlListAddUser]
	);

	const footerActions = [
		{
			id: 'back',
			label: 'Back',
			variant: 'text',
			left: true,
			startIcon: 'back',
			to: '/cp/admin/administration/accessControl',
			onClick: () => goToPage('/admin/administration/accessControl')
		}
	];

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = accessControlListAddUser === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.root}>
				<PageTitle title="Grant Access to User" actions={[]} />
				<Table
					tableData={accessControlListAddUser.users}
					columns={tableColumns}
					actions={tableActions}
					defaultSortColumn="name"
					handleSearch={{ searchValue, setSearchValue }}
				/>

				<Dialog
					fullScreen
					// customClasses={{ fullScreenContent: classes.fullScreenContent }}
					modal={{
						open: Boolean(confirmModal),
						handleClose: handleCloseModal,
						content: <GrantAccess handleClose={handleCloseModal} userId={confirmModal} />
					}}
					actions={[]}
				/>
				<ActionFooter actions={footerActions} />
			</div>
		</>
	);
};

export default withLayout(AccessControlAddUser);
