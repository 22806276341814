import { createReducer } from 'typesafe-actions';
import { SystemActions as Actions, SystemState } from 'typings/store/systemTypes';
import CompanyLogo from '../../assets/images/logo_Xpand_IT_white.png';
import BigCompanyLogo from '../../assets/images/xpandit-logo-transparent-full.png';

const INITIAL_STATE: SystemState = {
	loading: false,
	error: null,
	userPermissions: null,
	userActions: null,
	userPic: undefined,
	isExternal: undefined
};

const SystemReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_USER_PERMISSIONS]: (state, { payload }) => ({
		...state,
		loading: false,
		userPermissions: payload.userPermissions || null,
		userActions: payload.userActions || null
	}),
	[Actions.GET_USER_IS_EXTERNAL]: (state, { payload }) => ({
		...state,
		loading: false,
		isExternal: payload
	}),
	[Actions.REFRESH_USER_PERMISSIONS]: () => ({ ...INITIAL_STATE })
});

export default SystemReducer;
