import { OriginalResponse } from 'lib/handlers/requestTypes';

export enum SystemActions {
	SET_LOADING = '@@personalInfo/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_USER_PERMISSIONS = '@@system/GET_USER_PERMISSIONS',
	REFRESH_USER_PERMISSIONS = '@@system/REFRESH_USER_PERMISSIONS',
	GET_USER_PIC = '@@system/GET_USER_PIC',
	GET_USER_IS_EXTERNAL = '@@system/GET_USER_IS_EXTERNAL'
}

export type SystemState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly userPermissions: IUserPermission[] | null;
	readonly userActions: IUserAction[] | null;
	readonly userPic?: unknown;
	readonly isExternal: boolean | undefined;
};

//* Thunks interfaces

export interface IUserAction {
	id: number;
	name: string;
}
export interface IUserPermission {
	id: number;
	name: string;
}

export interface FetchedUserPermissions {
	roles: IUserPermission[];
}

export type UserInformation = {
	userActions: IUserAction[];
	userPermissions: IUserPermission[];
};
