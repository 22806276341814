/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReset } from 'react-hook-form';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, TabPanel, FormSteps } from 'xpand-ui/core';

//* TYPINGS
import {
	ICollaborationProposal,
	ICompanyInfo,
	IGetNewProposal,
	IProposalFormCareerHelper
} from 'typings/store/admin/proposals';
import { FetchedData, Match } from 'typings/store/generalTypes';
import { IPageTitle, ITitle } from 'components/App/TitleProvider';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';
import { addNotification } from 'lib/utils/notifications';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';

//*
import General from 'pages/Admin/_ProposalsFormUtils/General';
import CareerFinancial from 'pages/Admin/_ProposalsFormUtils/CareerFinancial';
import OtherBenefits from 'pages/Admin/_ProposalsFormUtils/OtherBenefits';
import ContractCompany from 'pages/Admin/_ProposalsFormUtils/ContractCompany';
import Summary from 'pages/Admin/_ProposalsFormUtils/Summary';
import {
	proposalsGetSelectOptions,
	proposalsGetFixedSelectOptions,
	updateSelectsInCascade,
	setValuesFromProposal,
	getProposalApiMethods,
	getMissingFieldsNames,
	setValuesFromCurrentProposal,
	setValuesFromCurrentProposalContractor
} from 'pages/Admin/_ProposalsFormUtils/utils';
import { COLLABTYPE } from 'pages/Admin/_ProposalsFormUtils/constants';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { schema, createDefaultValues } from './yupSchema';
import { useStyles } from './styles';
import { EditCollaborationTypeProps } from '.';
import {
	BALWURK_CONTRACTOR_ID,
	BALWURK_EMPLOYEE_ID,
	XPAND_CONTRACTOR_ID,
	XPAND_CONTRACTOR_ID_DE,
	XPAND_CONTRACTOR_ID_HR,
	XPAND_CONTRACTOR_ID_SE,
	XPAND_CONTRACTOR_ID_UK,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_DE,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_UK
} from 'lib/utils/constants';
import { IPostPutCSC } from 'typings/store/admin/employeeSalaryCategories';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import { useDispatch, useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface IEditCollaborationType extends EditCollaborationTypeProps, IPageTitle {
	match: Match<{
		id: string;
		edit: string;
		hash: string;
		toke: string;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const EditCollaborationType: FC<IEditCollaborationType> = ({
	match,
	title,
	setTitle,
	goToPage,
	// ? redux fields
	proposals,
	collaborationTypesManagement,
	submitNewCollaborationType,
	submitEditCollaborationType,
	// ? redux thunks
	getEditEmployee,
	getEditCollaborationType,
	getProposalAnnualGrossValue,
	// calculate  irs
	getIrsValue,
	company,
	resetEmployee
}) => {
	const classes = useStyles();
	const { loading: loadingProposal, proposalAnnualGross, irsValue } = proposals;
	const { loading: employeeCategoriesLoading, employee, invalidDocs } = collaborationTypesManagement;
	const { defaultCompanyKey, companyDomain } = company;
	const { params } = match;
	const cscHash = params?.hash;
	const isEdit = params?.id;
	const pageToken = params?.token;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	const [firstLoading, setFirstLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [firstChanged, setIsFirstChanged] = useState(false);
	const dispatch = useDispatch();
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() => createDefaultValues('PT'));

	//Forces to update of the value "company" to the current company
	useEffect(() => {
		setValue('company', defaultCompanyKey);
	}, []);
	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	// UseEffect required to adjust the schemas and defaultValues whenever the company changes
	useEffect(() => {
		if (formFields) {
			setCurrentDefaultValues(
				createDefaultValues(formFields?.formFieldsConfig?.country, employee?.company?.referenceData)
			);
		}
	}, [formFields]);

	// UseEffect required to reset the form fields.
	useEffect(() => {
		reset(currentDefaultValues);
		setValue('otherbenefits', currentDefaultValues?.otherbenefits);
		setValue('otherBenefitsList', currentDefaultValues?.otherBenefitsList);
		setValue('payBonusSalary', currentDefaultValues?.payBonusSalary);
	}, [currentDefaultValues]);

	const [isCollabType, setEmpCat] = useState(true);
	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const [tabs, setTabs] = useState([
		{
			id: 0,
			label: 'General'
		},
		{
			id: 1,
			label: 'Career & Financial'
		},
		{
			id: 2,
			label: 'Other Benefits'
		},
		{
			id: 3,
			label: 'Contract & Company'
		},
		{
			id: 4,
			label: 'Summary'
		}
	]);

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [errorCheck, setErrorCheck] = useState(false);
	const [selectHelper, setSelectHelper] = useState<IProposalFormCareerHelper>({
		company: defaultCompanyKey,
		businessUnit: currentDefaultValues.businessUnit,
		division: currentDefaultValues.division,
		department: currentDefaultValues.department,
		startDate: currentDefaultValues.startDate,
		careerPosition: currentDefaultValues.careerPosition,
		careerJobRole: currentDefaultValues.careerJobRole,
		careerJobRoleLevel: currentDefaultValues.careerJobRoleLevel,
		holdersNumber: currentDefaultValues.holdersNumber
	});
	const {
		handleSubmit,
		control,
		formState: { errors, touchedFields },
		reset,
		resetField,
		watch,
		setValue,
		setError,
		getValues,
		clearErrors,
		trigger
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema(formFields?.formFieldsConfig)),
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});
	const [initialBaseValue, setInitialBaseValue] = useState(watch().baseValue);
	const [isContractor, setIsContractor] = useState(
		watch('employeeCategory') === (XPAND_CONTRACTOR_ID || BALWURK_CONTRACTOR_ID) ? 'Contractor' : 'Employee'
	);

	//* when the proposal is submitted and there is no error, redirect to the corresponding page
	useEffect(() => {
		if (employee?.employeeInfo) {
			let newTitle: ITitle;

			if (isEdit) {
				newTitle = {
					...title,
					title: !employee?.employeeInfo?.searchKey
						? 'Edit Collaboration Type'
						: `Edit Collaboration Type - ${`${employee?.employeeInfo?.searchKey}`.toUpperCase()} : ${
								employee?.employeeInfo?.name
						  }`
				};
			} else {
				newTitle = {
					...title,
					title: !employee?.employeeInfo?.searchKey
						? 'New Collaboration Type'
						: `New Collaboration Type - ${`${employee?.employeeInfo?.searchKey}`.toUpperCase()} : ${
								employee?.employeeInfo?.name
						  }`
				};
			}
			setTitle(newTitle);
		}
	}, [employee]);

	const selectedCompany = useMemo(() => {
		if (!employee) {
			return undefined;
		}
		return selectHelper.company ? employee.company : undefined;
	}, [employee, selectHelper.company]);

	const referenceData = useMemo(() => selectedCompany?.referenceData || null, [selectedCompany]);

	/** //* GET API CALCULATIONS HELPERS
	 * @function initFinancialValues CALCULATE TAXABLE / GROSS VALUES / TWELFTHS
	 * @function updateIRSTax CALCULATE IRS
	 * @function getAnnualGrossValue
	 * @function getCareerFields CAREER NAMES HELPERS
	 */
	const { initFinancialValues, updateIRSTax, getAnnualGrossValue, getCareerFields } = getProposalApiMethods(
		reset as unknown as UseFormReset<IPostPutCSC>,
		getIrsValue,
		getProposalAnnualGrossValue
	);

	useEffect(() => {
		if (Object.keys(errors).length > 0 && errorCheck) {
			const { names } = getMissingFieldsNames(errors, isContractor);
			addNotification('danger', `The following required fields are required: ${names.join(', ')}`, 5);

			setErrorCheck(false);
		}
	}, [errorCheck, errors]);

	/** Set food Allowance value when foodAllowance type changes
	 * @onchange of the following fields
	 * @field1 - foodAllowanceType
	 */
	const updateFoodAllowanceValue = useMemo(
		() => (data: IPostPutCSC) => {
			if (referenceData) {
				const { foodAllowanceType, foodAllowance } = data;

				const result =
					foodAllowanceType === 'Cash'
						? foodAllowance || referenceData.xphrFoodAllowValue
						: foodAllowance || referenceData.xphrCrFoodAllowValue;
				setValue('foodAllowance', Number(result));
			}
		},
		[referenceData]
	);

	const updateFormAfterLoad = (proposal: ICollaborationProposal | null = null) => {
		// assign helpers values
		proposal &&
			setSelectHelper({
				company: proposal.company,
				businessUnit: proposal.businessUnit,
				division: proposal.division,
				department: proposal.department,
				startDate: proposal.startDate,
				careerPosition: proposal.careerPosition,
				careerJobRole: proposal.careerJobRole,
				careerJobRoleLevel: proposal.careerJobRoleLevel,
				holdersNumber: proposal.holdersNumber as string
			});

		const payload = watch();
		if (payload.prizeBonus === '0.00') {
			payload.eligiblePrizeValue = 0;
			payload.prizeBonusPercentage = 0;
			payload.prizeBonusGoals = [];
		} else {
			payload.eligiblePrizeValue = (Number(payload.prizeBonus) * Number(payload.monthsApplicable)) / 12 || 0;
			payload.prizeBonusPercentage =
				(Number(payload.prizeBonus) / (Number(payload.prizeBonus) + Number(payload.annualGrossValue))) * 100 ||
				0;
		}

		// Set tax related fields if they were sent
		if (proposal?.dependentsNumber) {
			payload.dependentsNumber = Number(proposal.dependentsNumber);
		}
		if (proposal?.disabled) {
			payload.disabled = proposal.disabled;
		}
		if (proposal?.maritalStatus) {
			payload.maritalStatus = proposal.maritalStatus;
		}
		if (proposal?.tsuExemption) {
			payload.tsuExemption = proposal.tsuExemption;
		}
		if (proposal?.bonusSalary) {
			payload.bonusSalary = proposal.bonusValue;
		}

		// load api related fields after form is filled
		initFinancialValues(payload);
		updateIRSTax(watch());
		getAnnualGrossValue(watch());
		updateFoodAllowanceValue(watch());
		setInitialBaseValue(watch().baseValue);
	};

	// Method create to manually display the error in respective inputs
	const enableViewError = (doc: string) => {
		setError(
			'socialSecurityNumber',
			{
				type: 'manual',
				message: 'The Social Security Number is invalid'
			},
			{ shouldFocus: true }
		);
	};

	useEffect(() => {
		if (invalidDocs) {
			// invalidDocss represents an array of documents that were returned by the BE as invalid.
			// As a result, an error must be shown for each invalid document
			invalidDocs.map(doc => enableViewError(doc));
		}
	}, [invalidDocs]);

	//* USE EFFECT TO LOAD INITIAL DATA
	useEffect(() => {
		if (!firstLoading) {
			resetEmployee();
			setFirstLoading(true);
		}

		if (!employee && !firstLoading) {
			if (isEdit) {
				getEditCollaborationType(cscHash, pageToken, Number(isEdit));
			} else {
				getEditEmployee(cscHash);
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			// LOAD INITIAL DATA
			reset({
				...currentDefaultValues,
				name: employee?.proposal?.name || employee?.employeeInfo?.name || '',
				email: employee?.proposal?.email || employee?.userInfo?.email || '',
				username: employee?.proposal?.username || employee?.userInfo?.username || '',
				company: employee?.proposal?.company || employee?.company?.searchKey!,
				companyName: employee?.proposal?.summary?.companyName || employee?.company?.name || '',
				businessUnit: employee?.proposal?.businessUnit || employee?.employeeInfo?.xPHRAdOrgBunit,
				division: employee?.proposal?.name.division || employee?.employeeInfo?.xphrAdOrgDivision,
				department: employee?.proposal?.department || employee?.employeeInfo?.xphrAdOrgDepartment,
				holdersNumber: (employee?.proposal?.holderNumber ||
					employee?.employeeInfo?.xphrHoldersNumber ||
					'1') as string,
				dependentsNumber:
					Number(employee?.proposal?.dependentsNumber || employee?.employeeInfo?.xphrNumberOfDependents) || 0,
				disabled: employee?.proposal?.disabled || employee?.employeeInfo?.xphrDisable,
				maritalStatus: employee?.proposal?.maritalStatus || employee?.employeeInfo?.xPHRMaritalStatus || '',
				tsuExemption: employee?.proposal?.tsuExemption || employee?.currentCSC?.xphrSocSecurityExemption > 0,
				token: (employee && employee.token ? employee.token : null) as string,
				primaryOffice: employee?.proposal?.primaryOffice || employee?.employeeInfo?.xphrEmployeeOffice || '',
				workingOffice:
					employee?.proposal?.workingOffice || employee?.employeeInfo?.xphrEmployeeWorkOffice || '',
				contractType: employee?.proposal?.contractType || employee?.employeeCurrentContract?.contractType || '',
				customerInContract:
					employee?.proposal?.customerInContract ||
					employee?.employeeCurrentContract?.customerInContract ||
					'',
				contractPosition:
					employee?.proposal?.contractPosition || employee?.employeeCurrentContract?.salaryCategory || '',
				fixedTermContractDuration: employee?.proposal?.fixedTermContractDuration,
				endDate: employee?.proposal?.formattedEndDate || employee?.employeeCurrentContract?.validUntil || '',
				careerManager: employee?.company?.careerManagers
					?.filter(
						e =>
							e.xPHRAdOrgBunit === employee?.proposal?.businessUnit ||
							e.xPHRAdOrgBunit === employee?.employeeInfo?.xPHRAdOrgBunit
					)
					.find(
						e =>
							e.id === employee?.proposal?.careerManager ||
							e.id === employee?.employeeCurrentCareer?.reportsTo
					)
					? employee?.proposal?.careerManager || employee?.employeeCurrentCareer?.reportsTo
					: '',
				employeeManager:
					employee?.xtrackerEmployeeAllInfo
						?.find(e => e.Username === employee?.proposal?.employeeManager)
						?.EmployeeManagerId.toString() ||
					employee?.xtrackerEmployeeAllInfo
						?.find(e => e.Username === employee?.employeeInfo?.searchKey)
						?.EmployeeManagerId.toString() ||
					'',
				holidayType:
					employee?.proposal?.holidayType ||
					employee?.xtrackerEmployeeAllInfo?.find(e => e.Username === employee?.employeeInfo?.searchKey)
						?.AccountHolidayTypeId ||
					'',
				socialSecurityNumber: employee?.employeeInfo?.xPHRSOCIALSECURITYNUMBER || '',
				pensionFundNumber: employee?.employeeInfo?.xPHRSOCIALSECURITYNUMBER || '',
				startDate: employee?.proposal?.startDate || getValues('startDate') || '',
				budget: employee?.proposal?.budget || 0,
				hourlyRate: employee?.proposal?.budget / (8 * 18) || 0,
				careerAndFinancialNotes: employee?.proposal?.careerAndFinancialNotes,
				benefitsComments: employee?.proposal?.benefitsComments,
				familyMembersComments: employee?.proposal?.familyMembersComments,
				taxZone: employee?.proposal?.taxZone || undefined,
				payBonusSalary: employee?.proposal?.payBonusSalary || currentDefaultValues?.payBonusSalary,
				bonusSalary: employee?.proposal?.bonusSalary
			});
			// update individually each field with the new data

			if (isContractor === 'Employee') {
				setValuesFromCurrentProposal(
					employee,
					getValues,
					reset,
					selectedCompany as ICompanyInfo,
					currentDefaultValues,
					isCollabType
				);
			} else {
				setValuesFromCurrentProposalContractor(
					employee,
					getValues,
					reset,
					selectedCompany as ICompanyInfo,
					currentDefaultValues,
					isCollabType
				);
			}

			// update the form selects and remake the calculations API requests
			updateFormAfterLoad({
				company: employee?.company?.searchKey as string,
				businessUnit: employee?.proposal?.businessUnit || employee?.employeeInfo?.xPHRAdOrgBunit,
				division: employee?.proposal?.name.division || employee?.employeeInfo?.xphrAdOrgDivision,
				department: employee?.proposal?.department || employee?.employeeInfo?.xphrAdOrgDepartment,
				careerPosition: careerFields?.careerPosition?.filter(
					e =>
						e.id === employee?.proposal?.careerPosition || e.id === employee?.employeeCurrentCareer?.profile
				)
					? employee?.proposal?.careerPosition || employee?.employeeCurrentCareer?.profile
					: '',
				careerJobRole: careerFields?.careerJobRole?.filter(
					e =>
						e.id === employee?.proposal?.careerJobRole ||
						e.id === employee?.employeeCurrentCareer?.xphrProfileEligibleRole
				)
					? employee?.proposal?.careerJobRole || employee?.employeeCurrentCareer?.xphrProfileEligibleRole
					: '',
				careerJobRoleLevel: careerFields?.careerJobRoleLevel?.filter(
					e =>
						e.id === employee?.proposal?.careerJobRoleLevel ||
						e.id === employee?.employeeCurrentCareer?.xphrProfileRoleLevel
				)
					? employee?.proposal?.careerJobRoleLevel || employee?.employeeCurrentCareer?.xphrProfileRoleLevel
					: '',
				holdersNumber: employee?.employeeInfo?.xphrHoldersNumber || 1,
				dependentsNumber: employee?.employeeInfo?.xphrNumberOfDependents || 0,
				disabled: employee?.employeeInfo?.xphrDisable,
				maritalStatus: employee?.employeeInfo?.xPHRMaritalStatus,
				tsuExemption: employee?.currentCSC?.xphrSocSecurityExemption > 0,
				bonusSalary: employee?.proposal?.bonusSalary || watch('baseValue')
			} as ICollaborationProposal);
		}

		if (!isEdit) {
			switch (employee?.employeeInfo?.businessPartnerCategory) {
				case XPAND_EMPLOYEE_ID:
					setValue('employeeCategory', XPAND_CONTRACTOR_ID);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case XPAND_EMPLOYEE_ID_DE:
					setValue('employeeCategory', XPAND_CONTRACTOR_ID_DE);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case XPAND_EMPLOYEE_ID_HR:
					setValue('employeeCategory', XPAND_CONTRACTOR_ID_HR);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case XPAND_EMPLOYEE_ID_SE:
					setValue('employeeCategory', XPAND_CONTRACTOR_ID_SE);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case XPAND_EMPLOYEE_ID_UK:
					setValue('employeeCategory', XPAND_CONTRACTOR_ID_UK);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case BALWURK_EMPLOYEE_ID:
					setValue('employeeCategory', BALWURK_CONTRACTOR_ID);
					setValue('collaborationRole', 'CONTRACTOR');
					break;
				case XPAND_CONTRACTOR_ID_DE:
					setValue('employeeCategory', XPAND_EMPLOYEE_ID_DE);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				case XPAND_CONTRACTOR_ID_HR:
					setValue('employeeCategory', XPAND_EMPLOYEE_ID_HR);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				case XPAND_CONTRACTOR_ID_SE:
					setValue('employeeCategory', XPAND_EMPLOYEE_ID_SE);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				case XPAND_CONTRACTOR_ID_UK:
					setValue('employeeCategory', XPAND_EMPLOYEE_ID_UK);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				case XPAND_CONTRACTOR_ID:
					setValue('employeeCategory', XPAND_EMPLOYEE_ID);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				case BALWURK_CONTRACTOR_ID:
					setValue('employeeCategory', BALWURK_EMPLOYEE_ID);
					setValue('collaborationRole', 'EMPLOYEE');
					break;
				default:
					break;
			}
		} else {
			// Edit Load
			setValue('employeeCategory', employee?.proposal?.employeeCategory);

			if (
				employee?.proposal?.employeeCategory == XPAND_CONTRACTOR_ID ||
				employee?.proposal?.employeeCategory == BALWURK_CONTRACTOR_ID
			) {
				setValue('collaborationRole', 'CONTRACTOR');
			}
			if (employee?.proposal?.prizeBonusGoals.length > 0) {
				employee?.proposal?.prizeBonusGoals.forEach(element => {
					element.id = element.id.toString();
				});
			}
			setValue('includeFamilyMembers', employee?.proposal?.includeFamilyMembers);
			setValue('numberMembersDep', employee?.proposal?.numberMembersDep);
			setValue('nationalAllowance', employee?.proposal?.nationalAllowance);
			setValue('nationalAllowanceDays', employee?.proposal?.nationalAllowanceDays);
			setValue('internationalAllowance', employee?.proposal?.internationalAllowance);
			setValue('internationalAllowanceDays', employee?.proposal?.internationalAllowanceDays);
			setValue('travelPass', employee?.proposal?.travelPass);
			setValue('nurseryCheck', employee?.proposal?.nurseryCheck);
			setValue('prizeBonus', employee?.proposal?.prizeBonus);
			setValue('prizeBonusPercentage', employee?.proposal?.prizeBonusPercentage);
			setValue('prizeBonusGoals', employee?.proposal?.prizeBonusGoals);
			setValue('company', employee?.proposal?.company);
			setValue('businessUnit', employee?.proposal?.businessUnit);
			setValue('division', employee?.proposal?.division);
			handleSelectHelper('division', employee?.proposal?.division);
			setValue('department', employee?.proposal?.department);
			handleSelectHelper('department', employee?.proposal?.department);
			setValue('careerPosition', employee?.proposal?.careerPosition);
			handleSelectHelper('careerPosition', employee?.proposal?.careerPosition);
			setValue('careerJobRole', employee?.proposal?.careerJobRole);
			handleSelectHelper('careerJobRole', employee?.proposal?.careerJobRole);
			setValue('careerJobRoleLevel', employee?.proposal?.careerJobRoleLevel);
			setValue('contractType', employee?.proposal?.contractType);
			setValue('contractPosition', employee?.proposal?.contractPosition);
			setValue('careerManager', employee?.proposal?.careerManager);
			setValue('dependentsNumber', employee?.proposal?.dependentsNumber);
			setValue('annualPrizeIRS', employee?.proposal?.annualPrizeIRS);
			setValue('baseValue', employee?.proposal?.baseValue);
			setValue('ihtValue', employee?.proposal?.ihtValue);
			setValue('ihtPercentage', employee?.proposal?.ihtPercentage);
			setValue('lifeInsurance', employee?.proposal?.lifeInsurance);
			setValue('additionalExpenses', employee?.proposal?.additionalExpenses);
			setValue('irsToApply', employee?.proposal?.irsToApply);
			setValue('hasTwelfths', employee?.proposal?.hasTwelfths);
			setValue('vacationsTwelfth', employee?.proposal?.vacationsTwelfth);
			setValue('christmasTwelfth', employee?.proposal?.christmasTwelfth);
			setValue('totalTaxableValue', employee?.proposal?.totalTaxableValue);
			setValue('foodAllowanceType', employee?.proposal?.foodAllowanceType);
			setValue('foodAllowance', employee?.proposal?.foodAllowance);
			setValue('foodAllowanceDays', employee?.proposal?.foodAllowanceDays);
			setValue(
				'monthsApplicable',
				employee?.proposal?.monthsApplicable == 0 ? 12 : employee?.proposal?.monthsApplicable
			);
			setValue('totalComponentsPrizePercentage', employee?.proposal?.totalComponentsPrizePercentage);
			setValue('totalComponentsPrizeValue', employee?.proposal?.totalComponentsPrizeValue);
			setValue('socialSecurityNumber', employee?.proposal?.socialSecurityNumber?.toString());
			setValue('startDate', employee?.proposal?.startDate);
			setValue(
				'eligiblePrizeValue',
				(Number(employee?.proposal?.prizeBonus) * Number(employee?.proposal?.monthsApplicable)) / 12 || 0
			);
			setValue('holdersNumber', employee?.proposal?.holdersNumber);
			setValue('maritalStatus', employee?.proposal?.maritalStatus);
			setValue('disabled', employee?.proposal?.disabled);
			setValue('tsuExemption', employee?.proposal?.tsuExemption);
			setValue('irsTwelfth', employee?.proposal?.irsTwelfth);
			setValue('contractType', employee?.proposal?.contractType);
			setValue('customerInContract', employee?.proposal?.customerInContract);
			setValue('traineeType', employee?.proposal?.traineeType);
			setValue('fixedTermContractDuration', employee?.proposal?.fixedTermContractDuration);
			setValue('endDate', employee?.proposal?.endDate);
			setValue('contractPosition', employee?.proposal?.contractPosition);
			setValue('primaryOffice', employee?.proposal?.primaryOffice);
			setValue('employeeManager', employee?.proposal?.employeeManager);
			setValue('holidayType', employee?.proposal?.holidayType);
			setValue('contactListLevel', employee?.proposal?.contactListLevel);
			setValue('healthInsuranceValue', employee?.proposal?.healthInsuranceValue);
			setValue('referralType', employee?.proposal?.referralType);
			setValue('reference', employee?.proposal?.reference);
			setValue('admissionProcessSheetId', employee?.proposal?.admissionProcessSheetId);
			setValue('projects', employee?.proposal?.projects);
			setValue('usersList', employee?.proposal?.usersList);
			setValue('emailsList', employee?.proposal?.emailsList);
			setValue('proposalId', employee?.proposal?.proposalId);
			setValue('payBonusSalary', employee?.proposal?.payBonusSalary);
			setValue('bonusSalary', employee?.proposal?.bonusSalary);
			initFinancialValues(watch());
			updateIRSTax(watch());
			getAnnualGrossValue(watch());
			updateFoodAllowanceValue(watch());
			setInitialBaseValue(watch().baseValue);
		}

		setValue('prizeBonus', watch('prizeBonus'), { shouldValidate: true });
		if (watch('company') !== 'HR') {
			setValue(
				'otherbenefits',
				getValues('employeeCategory') === XPAND_EMPLOYEE_ID ||
					getValues('employeeCategory') === BALWURK_EMPLOYEE_ID
					? true
					: false
			);
			setValue(
				'otherBenefitsList',
				getValues('employeeCategory') === XPAND_EMPLOYEE_ID ||
					getValues('employeeCategory') === BALWURK_EMPLOYEE_ID
					? [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }]
					: []
			);
		}

		if (watch('company') == 'HR') {
			setValue('otherbenefits', employee?.proposal?.otherbenefits || currentDefaultValues?.otherbenefits);
			setValue(
				'otherBenefitsList',
				employee?.proposal?.otherBenefitsList || currentDefaultValues?.otherBenefitsList
			);
		}
	}, [employee, cscHash, currentDefaultValues]);

	//REMOVES ALL VALUES THAT ARE USELESS TO HR COMPANY IN ORDER TO DO THE CORRECT CALCULATIONS
	useEffect(() => {
		if (watch('company') == 'HR') {
			if (Number(watch('grossBaseValue')) > 0) setValue('grossBaseValue', 0);
			if (Number(watch('annualGrossValue')) > 0) setValue('annualGrossValue', 0);
			if (Number(watch('travelPass')) > 0) setValue('travelPass', 0);
			if (Number(watch('totalTaxableValue')) > 0) setValue('totalTaxableValue', 0);
			if (Number(watch('foodAllowance')) > 0) setValue('foodAllowance', 0);
			if (Number(watch('foodAllowanceDays')) > 0) setValue('foodAllowanceDays', 0);
		}
	}, [watch()]);

	//* USE EFFECT TO UPDATE FORM WHEN THE IRS IS CALCULATED
	useEffect(() => {
		if (irsValue) {
			setValue('irsToApply', irsValue.irs as string, { shouldValidate: true });
			setValue('annualPrizeIRS', irsValue.irsPrize as string, { shouldValidate: true });
			setValue('irsTwelfth', irsValue.irsTwelfth as string, { shouldValidate: true });
		}
	}, [irsValue]);

	//* USE EFFECT TO FORCE THE UPDATE OF IRS TO 0 WHEN baseValue is 0 | "+" is used to convert the value of data.baseValue into a number
	useEffect(() => {
		if (+watch('baseValue') === 0) {
			setValue('irsToApply', '0', { shouldValidate: true });
			setValue('annualPrizeIRS', '0', { shouldValidate: true });
			setValue('irsTwelfth', '0', { shouldValidate: true });
		}
	}, [watch('baseValue')]);

	//* USE EFFECT TO UPDATE ANNUAL GROSS VALUE
	useEffect(() => {
		if (proposalAnnualGross) {
			const currentForm = watch();
			const { annualGrossValue } = proposalAnnualGross;
			setValue('annualGrossValue', annualGrossValue as string, { shouldValidate: true });
			setValue(
				'prizeBonusPercentage',
				(Number(currentForm.prizeBonus) / (Number(currentForm.prizeBonus) + Number(annualGrossValue))) * 100 ||
					0,
				{ shouldValidate: true }
			);
		}
	}, [proposalAnnualGross]);

	useEffect(() => {
		if (isContractor !== 'Employee') {
			setValuesFromCurrentProposalContractor(
				employee,
				getValues,
				reset,
				selectedCompany as ICompanyInfo,
				currentDefaultValues,
				isCollabType
			);
		}
	}, [getValues('employeeCategory')]);

	// ? SUBMIT PROPOSAL
	const onSubmit = async (data: IPostPutCSC): Promise<any> => {
		setIsSubmitting(true);
		const submitPayload = { ...data };
		delete submitPayload.usersList;
		delete submitPayload.emailsList;
		submitPayload.formFields = formFields.formFieldsConfig;

		if (data.company == 'PT') {
			if (isContractor === 'Contractor') {
				submitPayload.commsPlafond = 0;
				submitPayload.commsPlafondFlag = false;
				submitPayload.foodAllowance = 0;
				submitPayload.foodAllowanceDays = 0;
				submitPayload.hasTwelfths = false;
				submitPayload.healthInsuranceCheck = false;
				submitPayload.healthInsuranceValue = 0;
				submitPayload.ihtPercentage = 0;
				submitPayload.monthsApplicable = 0;
				submitPayload.otherbenefits = false;
				submitPayload.otherBenefitsList = [];
				submitPayload.baseValue = 0;
				submitPayload.ihtValue = 0;
				submitPayload.additionalExpenses = 0;
			} else {
				submitPayload.otherbenefits = true;
				submitPayload.otherBenefitsList = [{ goalDetails: 'Remote Work Allowance', prizeValue: 15 }];
			}
		}
		if (data.company == 'HR') {
			submitPayload.careerJobRoleLevel = '';
			data.foodAllowance = 0;
			data.foodAllowanceDays = 0;
			data.hasTwelfths = false;
			data.christmasTwelfth = 0;
			data.vacationsTwelfth = 0;
			data.ihtPercentage = 0;
			data.ihtValue = 0;
			data.monthsApplicable = 0;
			data.additionalExpenses = '0';
		}
		const docs = [];
		if (data.company == 'PT' || data.company == 'BW') {
			if (submitPayload.socialSecurityNumber) {
				docs.push({ name: 'niss', value: submitPayload.socialSecurityNumber });
			}
		}
		let result;
		if (isEdit) {
			result = await submitEditCollaborationType(submitPayload, data.token as string, Number(isEdit), docs);
		} else {
			result = await submitNewCollaborationType(submitPayload, data.token as string, docs);
		}
		if (result) {
			setIsSubmitted(result as unknown as boolean);
		}
	};

	useEffect(() => {
		if (isSubmitted) {
			reset(currentDefaultValues);
			goToPage(`/admin/salariesAndContracts/collaborationTypes/details/${cscHash}`);
		}
	}, [isSubmitted]);

	// SELECT FIELDS DEPENDENT OF COMPANY - CAREER NAMES
	const careerFields = useMemo(() => {
		const payload = getCareerFields(selectedCompany, selectHelper);

		let levelsListPreFilter = payload.careerJobRoleLevel;
		if (employee?.hasOwnProperty('currentCSC')) {
			levelsListPreFilter = payload.careerJobRoleLevel.filter(
				e => e.id == watch('careerJobRoleLevel') || e.isEmpty === true
			);
		}

		//Filter level based on Monthly Net Value
		if (employee?.hasOwnProperty('currentCSC')) {
			payload.careerJobRoleLevel = payload.careerJobRoleLevel.filter(
				e =>
					e.id == watch('careerJobRoleLevel') ||
					e.monthlyNet >= employee?.currentCSC?.xphrMonthlyNetAmount ||
					e.isEmpty === true
			);
		}

		const levelsListPostFilter = payload.careerJobRoleLevel;
		payload.showWarningJobLevels = levelsListPreFilter != levelsListPostFilter;

		if (firstLoading && employee) {
			if (selectHelper.department && payload.careerPosition?.length === 0) {
				addNotification('warning', 'There are no Careers for this department', 5);
			}
			return payload;
		}
	}, [employee, selectedCompany, selectHelper, firstLoading]);

	//Check if the current career of the user is inside of the option available to select in Career info section
	useEffect(() => {
		if (
			(careerFields?.careerPosition?.length > 0 ||
				careerFields?.careerJobRole?.length > 0 ||
				careerFields?.careerJobRoleLevel.length > 0) &&
			!firstChanged
		) {
			if (
				careerFields?.careerPosition?.find(
					e =>
						e.id === employee?.proposal?.careerPosition || e.id === employee?.employeeCurrentCareer?.profile
				) === undefined
			) {
				setValue('careerPosition', '');
			} else {
				setValue(
					'careerPosition',
					careerFields?.careerPosition?.find(
						e =>
							e.id === employee?.proposal?.careerPosition ||
							e.id === employee?.employeeCurrentCareer?.profile
					).id
				);
			}

			if (
				careerFields?.careerJobRole?.find(
					e =>
						e.id === employee?.proposal?.careerJobRole ||
						e.id === employee?.employeeCurrentCareer?.xphrProfileEligibleRole
				) === undefined
			) {
				setValue('careerJobRole', '');
			} else {
				setValue(
					'careerJobRole',
					careerFields?.careerJobRole?.find(
						e =>
							e.id === employee?.proposal?.careerJobRole ||
							e.id === employee?.employeeCurrentCareer?.xphrProfileEligibleRole
					).id
				);
			}

			if (
				careerFields?.careerJobRoleLevel?.find(
					e =>
						e.id === employee?.proposal?.careerJobRoleLevel ||
						e.id === employee?.employeeCurrentCareer?.xphrProfileRoleLevel
				) === undefined
			) {
				setValue('careerJobRoleLevel', '');
			} else {
				setValue(
					'careerJobRoleLevel',
					careerFields?.careerJobRoleLevel?.find(
						e =>
							e.id === employee?.proposal?.careerJobRoleLevel ||
							e.id === employee?.employeeCurrentCareer?.xphrProfileRoleLevel
					).id
				);
			}
			setIsFirstChanged(true);
		}
	}, [careerFields, firstChanged]);

	//Loads the form format fields
	useEffect(() => {
		dispatch(
			getFormFieldsConfig(
				isContractor,
				employee?.proposal?.company || employee?.company?.searchKey!,
				watch('collaborationType')
			)
		);
	}, [isContractor, watch('company'), watch('collaborationType')]);

	// SELECT FIELDS DEPENDENT OF COMPANY
	const selectOptions = useMemo(
		() =>
			proposalsGetSelectOptions(
				classes,
				employee as FetchedData,
				selectHelper,
				selectedCompany as ICompanyInfo,
				isCollabType
			),
		[employee, selectHelper]
	);
	// FIXED SELECT FIELDS
	const selectFixedOptions = useMemo(
		() => proposalsGetFixedSelectOptions(employee as unknown as IGetNewProposal),
		[employee]
	);

	// UPDATE SELECT CHILD'S IN CASCADE WHEN NEEDED
	const handleSelectHelper = (field: string, value: string | null) => {
		updateSelectsInCascade(setSelectHelper, setValue, field, value); // FIXME:
		if (field === 'careerJobRoleLevel') {
			setValue('careerJobRoleLevel', value!, { shouldValidate: true });
		}
	};

	const populateFormWithProposal = useMemo(
		() => (value: number) => {
			if (selectOptions?.collaborationProposals) {
				// find selected proposal in list
				const propSelected = selectOptions.collaborationProposals.find(e => e.id === value);

				if (!propSelected) return;

				const { proposal } = propSelected;

				if (!proposal) return;

				const proposalToCopy = {
					baseValue: proposal.baseValue,
					irsToApply: proposal.irsToApply,
					ihtValue: proposal.ihtValue,
					ihtPercentage: proposal.ihtPercentage,
					hasTwelfths: proposal.hasTwelfths,
					vacationsTwelfth: proposal.vacationsTwelfth,
					christmasTwelfth: proposal.christhmasTwelfth,
					totalTaxableValue: proposal.totalTaxableValue,
					foodAllowanceType: proposal.foodAllowanceType,
					foodAllowance: proposal.foodAllowance,
					foodAllowanceDays: proposal.foodAllowanceDays,
					nationalAllowance: proposal.nationalAllowance,
					nationalAllowanceDays: proposal.nationalAllowanceDays,
					internationalAllowance: proposal.internationalAllowance,
					internationalAllowanceDays: proposal.internationalAllowanceDays,
					travelPass: proposal.travelPass,
					additionalExpenses: proposal.additionalExpenses,
					nurseryCheck: proposal.nurseryCheck,
					lifeInsurance: proposal.lifeInsurance,
					prizeBonus: proposal.prizeBonus,
					prizeBonusGoals: proposal.prizeBonusGoals,
					projects: proposal.projects?.map(({ id, ...e }) => e) || [],
					prizeBonusPercentage: proposal.prizeBonusPercentage,
					monthsApplicable: proposal.monthsApplicable,
					annualPrizeIRS: proposal.annualPrizeIRS,
					totalComponentsPrizePercentage: proposal.totalComponentsPrizePercentage,
					totalComponentsPrizeValue: proposal.totalComponentsPrizeValue
				};

				// update individually each field with the new data
				setValuesFromProposal(proposalToCopy as unknown as ICollaborationProposal, reset, watch, false);

				// update the form selects and remake the calculations API requests
				updateFormAfterLoad();
			}
		},
		[selectOptions.collaborationProposals]
	);

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'cancel',
				label: 'Cancel',
				left: true,
				onClick: () => {
					resetEmployee();
					goToPage(`/admin/salariesAndContracts/collaborationTypes/details/${cscHash}`);
				},
				variant: 'outlined',
				disabled: false
			},
			...(tabSelected !== 0
				? [
						{
							id: 'previous',
							label: 'Previous',
							// left: true,
							onClick: () => {
								setTabSelected(tabSelected - 1);
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
							},
							variant: 'outlined',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected !== tabs.length - 1
				? [
						{
							id: 'next',
							label: 'Next',
							onClick: async () => {
								setTabSelected(tabSelected + 1);
								/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
								the array. */
								if (formWarnings.includes(tabSelected)) {
									setFormWarnings(formWarnings.filter(e => e !== tabSelected));
								}
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
								await trigger('prizeBonusGoals');
							},
							variant: 'contained',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected === tabs.length - 1
				? [
						{
							id: 'submit',
							label: 'Submit',
							onClick: () => {},
							type: 'submit',
							form: 'form-new-employee-salary',
							variant: 'contained'
						}
				  ]
				: [])
		],
		[tabSelected, cscHash]
	);

	let hasBaseValueChanges = false;
	if (watch().baseValue !== initialBaseValue) {
		hasBaseValueChanges = true;
	}

	// TODO Review this solution - cannot find where traineeType receives value of false while rendering ContractCompany
	if (watch('traineeType') === false) {
		setValue('traineeType', '');
	}

	//if (error) return handleErrorPage(error, clearCscError);
	const isLoading = employee === null || selectedCompany === null;
	if (isLoading) return <LoadingOverlay />;
	return (
		<>
			{(employeeCategoriesLoading || loadingProposal) && <LoadingOverlay />}
			<div className={classes.root}>
				<form
					ref={scrollToTopRef}
					id="form-new-employee-salary"
					className={classes.pageForm}
					onSubmit={e => {
						e.preventDefault();
						setErrorCheck(true);
						return handleSubmit(() => onSubmit(watch()))(e);
					}}>
					<FormSteps
						steps={tabs}
						selected={tabSelected}
						errors={formWarnings}
						style={isTablet ? { paddingTop: '30px' } : { paddingTop: '10px' }}
					/>

					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(90vh - 240px)', overflowX: 'hidden' }}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{tabs.map(tab => {
							const commonProps = {
								type: COLLABTYPE,
								selectOptions: {
									...selectOptions,
									...careerFields,
									...selectFixedOptions
								},
								currentDefaultValues,
								initialFormData: employee,
								referenceData,
								getAnnualGrossValue,
								initFinancialValues,
								updateFoodAllowanceValue,
								populateFormWithProposal,
								handleSelectHelper,
								// react-hook-form helpers
								control,
								errors,
								reset,
								resetField,
								getValues,
								setValue,
								watch,
								clearErrors,
								isEdit
							};
							return (
								<TabPanel key={`proposal_tab_container_${tab.id}`} value={tabSelected} index={tab.id}>
									{tab.id === 0 && (
										<General
											{...commonProps}
											{...{ updateIRSTax }}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ setTabs }}
											{...{ isCollabType }}
											{...{ formFields }}
											{...{ companyDomain }}
										/>
									)}
									{tab.id === 1 && (
										<CareerFinancial
											{...commonProps}
											{...{ updateIRSTax }}
											{...{ employee }}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ isCollabType }}
											{...{ formFields }}
										/>
									)}
									{isContractor !== 'Contractor' && (
										<>
											{tab.id === 2 && (
												<OtherBenefits
													{...commonProps}
													{...{ updateIRSTax }}
													{...{ formFields }}
												/>
											)}
										</>
									)}
									{isContractor !== 'Contractor' ? (
										<>
											{tab.id === 3 && (
												<ContractCompany
													{...commonProps}
													{...{ isContractor }}
													{...{ setIsContractor }}
													{...{ isCollabType }}
													{...{ formFields }}
												/>
											)}
										</>
									) : (
										<>
											{tab.id === 2 && (
												<ContractCompany
													{...commonProps}
													{...{ isContractor }}
													{...{ setIsContractor }}
													{...{ isCollabType }}
													{...{ formFields }}
												/>
											)}
										</>
									)}
									{tabSelected === tabs.length - 1 && (
										<Summary
											additionalErrors={errors}
											handleWarning={(names: unknown, tabsWithWarn: number[]) =>
												setFormWarnings(tabsWithWarn)
											}
											{...commonProps}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ isCollabType }}
											{...{ formFields }}
										/>
									)}
								</TabPanel>
							);
						})}
					</Scrollbars>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={
					(isTouchedFieldsUsed(touchedFields, getValues) && !isSubmitting) ||
					(hasBaseValueChanges && !isSubmitting)
				}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default withLayout(EditCollaborationType);
