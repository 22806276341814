import { OriginalResponse } from 'lib/handlers/requestTypes';
import { action } from 'typesafe-actions';
import {
	PersonalInfoActions as Actions,
	IUserSkills,
	ISystemSkills,
	IUserProfile,
	IUserResume,
	ITrainings,
	IBenefits,
	ICareer,
	IFinancialDocuments
} from 'typings/store/personalInfoTypes';
import { IDispatchType } from 'typings/store/storeTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

//* BOOKINGS */
export const setBooking = (payload: unknown): IDispatchType => action(Actions.GET_DESK_BOOKING, payload);

export const setBookingsList = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_DESK_BOOKINGS_LIST, payload);

export const setUpdateBookings = (payload: unknown | null): IDispatchType =>
	action(Actions.SET_UPDATE_BOOKINGS, payload);

export const setEmployeeBookings = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_EMPLOYEE_BOOKINGS, payload);

export const setConfirmBooking = (payload: unknown | null): IDispatchType =>
	action(Actions.SEND_CONFIRM_BOOKING, payload);

export const setDeleteBooking = (payload: unknown | null): IDispatchType =>
	action(Actions.SEND_DELETE_BOOKING, payload);

export const setEmployeeBookingsCounter = (payload: number | null): IDispatchType =>
	action(Actions.GET_EMPLOYEE_PENDING_COUNT, payload);

export const setExportBookingsList = (payload: unknown): IDispatchType => action(Actions.GET_EXPORT_BOOKINGS, payload);
export const clearBooking = (): IDispatchType => action(Actions.CLEAR_BOOKING);

//* Profile */
export const setUserProfile = (payload: IUserProfile | null): IDispatchType =>
	action(Actions.GET_USER_PROFILE, payload);

export const setSendUserUpdate = (newData: IUserProfile | null): IDispatchType =>
	action(Actions.SET_UPDATE_USER_PROFILE, {
		newData
	});

export const setNewProfilePic = (newData: unknown): IDispatchType =>
	action(Actions.SET_NEW_USER_PROFILE_PIC, {
		newData
	});

export const setClearUserInfo = (): IDispatchType => action(Actions.CLEAR_USER_PROFILE, {});

//* COMPANY */
export const setAllEmployees = (payload: unknown): IDispatchType => action(Actions.GET_ALL_EMPLOYEES, payload);

export const setUserTeam = (payload: unknown): IDispatchType => action(Actions.GET_USER_TEAM, payload);

//* Skills */
export const setUserSkills = (payload: IUserSkills | null | []): IDispatchType =>
	action(Actions.GET_USER_SKILLS, payload);

export const setSystemSkills = (payload: ISystemSkills | null | []): IDispatchType =>
	action(Actions.GET_SYSTEM_SKILLS, payload);

//* Resume */
export const setUserResume = (payload: IUserResume | null): IDispatchType => action(Actions.GET_USER_RESUME, payload);

export const setPostUserResume = (newData: IUserResume | null, payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SEND_USER_RESUME, {
		payload,
		newData
	});

//* Trainings */
export const setUserTrainings = (payload: ITrainings | null | []): IDispatchType =>
	action(Actions.GET_USER_TRAININGS, payload);

//* Benefits */
export const setUserBenefits = (payload: IBenefits | null | []): IDispatchType =>
	action(Actions.GET_USER_BENEFITS, payload);

//* Career */
export const setUserCareer = (payload: ICareer | null): IDispatchType => action(Actions.GET_USER_CAREER, payload);

//* Financial Documents */
export const setUserFinancialDocuments = (payload: IFinancialDocuments | null): IDispatchType =>
	action(Actions.GET_USER_FINANCIAL_DOCUMENTS, payload);

export const setUserDocumentDownloadFinished = (): IDispatchType => action(Actions.DOWNLOAD_USER_DOCUMENT);

export const setClearSystemSkills = (): IDispatchType => action(Actions.CLEAR_SYSTEM_SKILLS);

export const getUsersPendingConvocationMessage = (payload: string | null | undefined): IDispatchType =>
	action(Actions.GET_PENDING_CONVOCATIONS_MESSAGE, payload);

export const clearConvocationMessageConfig = (): IDispatchType => action(Actions.CLEAR_CONVOCATION_MESSAGE_CONFIG);

//* Xtracker - MyTimesheets */
export const setWeekViewData = (payload: unknown | null): IDispatchType => action(Actions.GET_WEEK_VIEW_DATA, payload);
export const setWeekViewDataForCopy = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_WEEK_VIEW_DATA_FOR_COPY, payload);
export const setMoveTimeEntryMessage = (payload: unknown | null): IDispatchType =>
	action(Actions.MOVE_TIMEENTRY, payload);

export const setDeleteMoveTimeEntryMessage = (): IDispatchType => action(Actions.DELETE_MOVE_TIMEENTRY);
export const setDayViewData = (payload: unknown | null): IDispatchType => action(Actions.GET_DAY_VIEW_DATA, payload);
export const setExportTimesheetList = (): IDispatchType => action(Actions.GET_EXPORT_TIMESHEET);
export const setImportTimesheet = (payload: unknown | null): IDispatchType => action(Actions.GET_IMPORT_TIMESHEET);

export const setTimesheetsPeriodListFiltersData = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_PERIODLIST_FILTERS_DATA, payload);

export const setCopySave = (payload: unknown | null): IDispatchType => action(Actions.SAVE_TIMEENTRIES_COPY, payload);
export const setClearCopyMessage = (payload: unknown | null): IDispatchType =>
	action(Actions.CLEAR_COPY_MESSAGE, payload);

export const setExportTimesheet = (): IDispatchType => action(Actions.GET_EXPORT_TIMESHEET_CSV);

export const setSaveTimesheet = (payload: unknown | null): IDispatchType => action(Actions.SAVE_TIMESHEET, payload);

export const setAttachmentList = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_ATTACHMENT_LIST, payload);

export const setLoadingToFalse = (): IDispatchType => action(Actions.SET_LOADING_FALSE);

//* Xtracker - MyExpenses */
export const setExpensesData = (payload: unknown | null): IDispatchType => action(Actions.GET_EXPENSES_DATA, payload);

export const setExpenseSheetData = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_EXPENSE_SHEET_DATA, payload);

export const setNewExpenseLineData = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_NEW_EXPENSE_LINE_DATA, payload);

export const setAddNewExpenseSheet = (payload: unknown | null): IDispatchType =>
	action(Actions.SEND_NEW_EXPENSE_SHEET, payload);

export const setAddNewExpenseLine = (payload: unknown | null): IDispatchType =>
	action(Actions.SEND_NEW_EXPENSE_LINE, payload);

export const setExpensesAuditData = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_EXPENSES_AUDIT_DATA, payload);

export const setExpensesAttachmentList = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_EXPENSE_ATTACHMENT_LIST, payload);

export const setUpdateExpenseSheet = (payload: unknown | null): IDispatchType =>
	action(Actions.SEND_UPDATE_EXPENSE_SHEET, payload);

export const setSubmitExpenseSheetMessage = (payload: unknown | null): IDispatchType =>
	action(Actions.GET_SUBMIT_EXPENSE_SHEET, payload);

export const setClearExpenseSheetData = (): IDispatchType => action(Actions.CLEAR_EXPENSE_SHEET_DATA);

export const setCopiedExpenseSheetId = (payload: string | null): IDispatchType =>
	action(Actions.COPIED_EXPENSE_SHEET, payload);
