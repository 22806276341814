/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, FormHelperText } from '@mui/material';
import { Add } from '@mui/icons-material';
import Adicionar from '../../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { BudgetLine, BudgetTitle } from '../../utils';

import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IProjectTeam extends IProjectTabs {
	budget: { profile: string; role: string; level: string; days: string; dailyRate: string }[];
	addBudget: () => void;
	deleteBudget: (field: string, index: number) => void;
	selectedCompany: any;
	newProjectHelper: HelperProps;
}

//* COMPONENT
const ProjectTeam: FC<IProjectTeam> = ({
	trigger,
	control,
	errors,
	setValue,
	getValues,
	budget,
	addBudget,
	deleteBudget,
	selectedCompany,
	newProjectHelper
}) => {
	const classes = useStyles();
	useEffect(() => {
		if (budget.length === 0) {
			setValue('hasBudget', false);
		}
		const validateBudget = async () => {
			await trigger('budget');
		};
		validateBudget();
	}, [budget]);

	const { width } = useWindowDimensions();
	const isMobile = useMemo(() => width <= 600, [width]); // default is 600 mobile

	return (
		<Grid container spacing={4} className={classes.root}>
			{/* **************** Budgets ****************** */}
			<Grid item {...sizes[12]}>
				<Typography variant="h2">Budgets</Typography>
			</Grid>
			<Grid item {...sizes[9]}>
				<Typography component="span" className={classes.infoLabel}>
					Budget for this project, the number of days per profile and the profile daily rate
				</Typography>
				{errors.budget && <FormHelperText error>Empty fields are not allowed</FormHelperText>}
			</Grid>
			<Grid item {...sizes[3]}>
				<Button
					color="primary"
					variant="text"
					endIcon={<Adicionar />}
					onClick={() => {
						addBudget();
						setValue('hasBudget', true);
					}}>
					Add Budget
				</Button>
			</Grid>
			{isMobile ? (
				<Scrollbars
					style={{ marginTop: '10px', height: 'calc(100vh - 320px)', overflowX: 'hidden', width: '100%' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					{budget.length > 0 && (
						<Grid
							container
							className={classes.responsiveGrid}
							direction="row"
							justifyContent="space-evenly"
							alignItems="baseline"
							spacing={3}>
							<BudgetTitle classes={classes} />
							{budget.map(
								(e, index) =>
									e !== null && (
										<BudgetLine
											// eslint-disable-next-line react/no-array-index-key
											key={index}
											index={index}
											handleDelete={deleteBudget}
											control={control}
											errors={errors}
											classes={classes}
											selectedCompany={selectedCompany}
											helper={newProjectHelper}
											setValue={setValue}
											getValues={getValues}
										/>
									)
							)}
						</Grid>
					)}
				</Scrollbars>
			) : (
				<Grid item {...sizes[12]}>
					{budget.length > 0 && (
						<Grid
							container
							className={classes.responsiveGrid}
							direction="row"
							justifyContent="space-evenly"
							alignItems="center"
							spacing={3}>
							<BudgetTitle classes={classes} />
							{budget.map(
								(e, index) =>
									e !== null && (
										<BudgetLine
											// eslint-disable-next-line react/no-array-index-key
											key={index}
											index={index}
											handleDelete={deleteBudget}
											control={control}
											errors={errors}
											classes={classes}
											selectedCompany={selectedCompany}
											helper={newProjectHelper}
											setValue={setValue}
											getValues={getValues}
										/>
									)
							)}
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default ProjectTeam;
