/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ChangeEventHandler } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Input as MUIInput, InputLabel } from '@mui/material';
import { Add, Sync, List, Publish } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { Input, DatePicker, Select, InfoField } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { ErrorPage } from 'xpand-ui/lab';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IBudgetInfo, IBudgetPostPayload } from 'typings/store/admin/budget';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { Roles, actionPermission } from 'lib/roles';
import BudgetAudit from './BudgetAudit';
import { useStyles } from './styles';
import { schema, defaultValues } from './yupSchema';
import { BudgetProps } from '.';
import { useSelector } from 'react-redux';
import Adicionar from '../../../../assets/icons/Adicionar.svg';

//* COMPONENT INTERFACES
interface IBudget extends BudgetProps {
	goToPage: (path: string) => void;
}

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Budget - XPECP.png';
import { getLSField } from 'lib/utils/cookies';

let formData: FormData = new FormData();

const STATUS_DRAFT = 1;
const STATUS_APPROVED = 3;
const STATUS_VOIDED = 4;
const STATUS_EDITED = 5;

/**
 *  * BUDGET ADMINISTRATOR:
 * -	Access Budget page
 * -	Option to create New Budget
 * -	Option to import a Budget
 * -	Option to Sync BI
 * -	Option to edit Budget
 * -	Option to mark as approved (in Edit page)
 * -	Option to delete Budget
 * -	Option to export Budget
 *
 *  * BUDGET USER:
 * -	Access Budget page
 * -	Option to edit Budget
 * -	Option to export Budget
 */
//* COMPONENT
const Budget: FC<IBudget> = ({
	budget,
	filters,
	goToPage,
	getBudgetTable,
	submitBudgetPost,
	getNewBudget,
	submitBudgetBiRefresh,
	submitDeleteBudget,
	submitExportBudget,
	sendPostImportBudget,
	clearBudgetError,
	setBudgetPageFilter
}) => {
	const company = useSelector(state => state.company);
	const classes = useStyles();
	const { loading, error, budgetList, newBudget, budgetAction, budgetCompanies } = budget;
	const { budgetFilter, budgetCompanyFilter } = filters;
	const [newBudgetModal, setNewBudgetModal] = useState<boolean>(false);
	const [auditModal, showAuditModal] = useState<unknown>(null);
	const [confirmModal, setConfirmModal] = useState<unknown>(null);
	const [importBudgetModal, setImportBudgetModal] = useState<boolean>(false);
	const [selectedBudget, setSelectedBudget] = useState<Document | FormData | null>(null);
	const [selectedCompany, setSelectedCompany] = useState<string>(budgetCompanyFilter);
	const [isImportedBudgetFile, setIsImportedBudgetFile] = useState<boolean | null>(null);
	const [filteredTableData, setFilteredTableData] = useState();

	//const [selectedCompany, setSelectedCompany] = useState<string>(budgetCompanyFilter);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(budgetFilter);

	// Update filters whenever necessary
	useEffect(() => {
		setBudgetPageFilter({ searchValue, selectedCompany });
	}, [searchValue, selectedCompany]);

	useEffect(() => {
		if (!budgetList && !error) getBudgetTable();
	}, [budgetList]);

	useEffect(() => {
		if (budgetAction && (budgetAction as any).id) {
			goToPage(`/admin/budget/${(budgetAction as any).id}`);
		}
	}, [budgetAction]);

	useEffect(() => {
		if (newBudgetModal && !newBudget) {
			getNewBudget();
		}
	}, [newBudgetModal]);

	const changeHandler = (event: { target: { files: (string | Blob)[] } }) => {
		let ext = event?.target.files[0]?.name.match(/\.([^.]+)$/)[1];
		if (ext === 'xlsx' && 'xls') {
			setIsImportedBudgetFile(true);
		} else {
			setIsImportedBudgetFile(false);
		}
		formData.append('newDoc', event.target.files[0]);
		setSelectedBudget(formData);
	};

	const onSubmitImport = () => {
		setImportBudgetModal(false);
		setSelectedBudget(new FormData());
		formData = new FormData();
		sendPostImportBudget(selectedBudget as FormData);
	};

	const isBudgetAdmin = useMemo(() => budgetList && actionPermission(Roles.BA), [budgetList]);
	const isBudgetUser = useMemo(() => budgetList && actionPermission(Roles.BU), [budgetList]);

	const confirmDeleteBudget = (payload: number) => {
		submitDeleteBudget(payload);
		setConfirmModal(false);
	};

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IBudgetInfo>[] = useMemo(
		() => [
			{ label: 'Name', id: 'tableName', accentColumn: true },
			{ label: 'Year', id: 'year', accentColumn: true },
			{ label: 'Budget Type', id: 'type', format: (row, str) => str.charAt(0).toUpperCase() + str.slice(1) },
			{
				label: 'Created Date',
				id: 'creationDate',
				type: 'date',
				format: ({ creationDate }) => parseDateToShow(creationDate)
			},
			{
				label: 'Updated  Date',
				id: 'updatedDate',
				type: 'date',
				format: ({ updatedDate }) => parseDateToShow(updatedDate)
			},
			{
				label: 'Status',
				id: 'statusName'
			}
		],
		[]
	);

	const tableActions: ITableAction<IBudgetInfo>[] = useMemo(
		() => [
			{
				id: 'viewDetails',
				type: 'button',
				icon: <List />,
				render: () => true,
				disabled: () => false,
				onClick: row => showAuditModal(row)
			},
			{
				id: 'someMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'edit',
						label: 'Edit Budget',
						to: ({ id }) => `/cp/admin/budget/${id}`,
						onClick: ({ id }) => goToPage(`/admin/budget/${id}`),
						disabled: () => getLSField('impersonate_userInfo')
					},
					{
						id: 'delete',
						label: 'Delete Budget',
						disabled: () => !isBudgetAdmin,
						onClick: ({ id }) => {
							isBudgetAdmin && setConfirmModal(id);
						}
					},
					{
						id: 'export',
						label: 'Export Budget',
						disabled: () => (!isBudgetAdmin && !isBudgetUser) || getLSField('impersonate_userInfo'),
						onClick: ({ id }) => {
							(isBudgetAdmin || isBudgetUser) && submitExportBudget(id);
						}
					}
				]
			}
		],
		[isBudgetAdmin, isBudgetUser]
	);

	const tableData = useMemo(
		() =>
			budgetList &&
			budgetList?.map(({ statusId, ...e }) => ({
				...e,
				tableName: e.customName || e.name,
				statusName:
					(statusId === STATUS_DRAFT && 'DRAFT') ||
					(statusId === STATUS_APPROVED && 'APPROVED') ||
					(statusId === STATUS_VOIDED && 'VOIDED') ||
					(statusId === STATUS_EDITED && 'EDITED') ||
					'',
				creationDateFormated: parseDateToShow(e.creationDate),
				updatedDateFormated: parseDateToShow(e.updatedDate),
				budgetCompany: !e.budgetCompany ? company.defaultCompanyKey : e.budgetCompany
			})),
		[budgetList]
	);

	//Filters the budget list based on the company selected
	useEffect(() => {
		const filteredData = tableData?.filter(e => e.budgetCompany === selectedCompany);
		setFilteredTableData(filteredData);
	}, [selectedCompany, tableData]);

	const companiesBudget = useMemo(
		() =>
			budgetCompanies &&
			budgetCompanies?.map(e => ({
				searchKey: e.searchKey,
				label: e.name
			})),
		[budgetCompanies]
	);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'newBudget',
				onClick: () => isBudgetAdmin && setNewBudgetModal(true),
				disabled: !isBudgetAdmin,
				icon: <Adicionar />,
				label: 'New Budget'
			},
			{
				id: 'importBudget',
				onClick: () => isBudgetAdmin && setImportBudgetModal(true),
				disabled: !isBudgetAdmin,
				icon: <Publish />,
				label: 'Import Budget'
			},
			{
				id: 'syncBIData',
				color: 'secondary',
				onClick: () => isBudgetAdmin && submitBudgetBiRefresh(),
				disabled: !isBudgetAdmin || getLSField('impersonate_userInfo'),
				icon: <Sync />,
				label: 'Sync BI Data'
			}
		],
		[isBudgetAdmin, isBudgetUser]
	);

	const onSubmit = (data: unknown) => {
		submitBudgetPost(data as IBudgetPostPayload);
		setNewBudgetModal(false);
	};

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setNewBudgetModal(false);
				reset(defaultValues);
			}
		},
		{
			id: 'confirm',
			label: 'Create',
			color: 'primary',
			variant: 'contained',
			onClick: () => {},
			type: 'submit',
			form: 'new-budget-modal',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const importBudgetActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setSelectedBudget(null);
				setIsImportedBudgetFile(null);
				setImportBudgetModal(false);
			}
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			form: 'form-import-budget',
			variant: 'contained',
			disabled:
				selectedBudget === null || !isImportedBudgetFile || getLSField('impersonate_userInfo')
					? true
					: ((selectedBudget as FormData)?.getAll('newDoc').length as number) === 0,
			onClick: onSubmitImport
		}
	];

	if (error) return handleErrorPage(error, clearBudgetError);

	const isLoading = budgetList === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Dialog
				title="New budget"
				scroll="body"
				actions={confirmActions}
				modal={{
					open: newBudgetModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setNewBudgetModal(false);
					},
					content: (
						<form id="new-budget-modal" onSubmit={handleSubmit(onSubmit)}>
							{newBudget ? (
								<Grid container style={{ padding: '10px 5px 40px 5px' }} spacing={4}>
									<Grid item {...sizes[12]}>
										<Input
											name="customName"
											label="Budget name (optional)"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<DatePicker
											views={['year']}
											inputFormat="yyyy"
											placeholder="Year"
											name="year"
											label="Budget Year"
											errors={errors}
											control={control}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<Select
											required
											name="type"
											label="Budget Type"
											options={
												newBudget.budgetTypes.map((e: any) => ({ ...e, label: e.name })) || []
											}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<Select
											name="cloneBudgetType"
											label="DO YOU WISH TO MIGRATE ALL QUARTER VALUES FROM A SPECIFIC BUDGET?"
											options={
												(tableData && tableData.map(e => ({ id: e.id, label: e.tableName }))) ||
												[]
											}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<Select
											name="budgetCompany"
											label="Budget Company"
											options={
												(companiesBudget &&
													companiesBudget.map(e => ({ id: e.searchKey, label: e.label }))) ||
												[]
											}
											control={control}
											errors={errors}
										/>
									</Grid>
								</Grid>
							) : (
								<LoadingOverlay />
							)}
						</form>
					)
				}}
			/>
			<Dialog
				fullScreen
				customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(auditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') showAuditModal(null);
					},
					content:
						(Boolean(auditModal) && (
							<BudgetAudit
								budgetId={(auditModal as any).id}
								name={`${(auditModal as any).tableName} - Audit Log`}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: <Typography gutterBottom>Are you sure you want to delete this budget?</Typography>
				}}
				title="Delete Budget"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'confirmDeleteBudget',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => confirmDeleteBudget(confirmModal as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				modal={{
					open: Boolean(importBudgetModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setImportBudgetModal(false);
					},
					content: (
						<form encType="multipart/form-data" id="form-import-budget">
							<Grid container style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<InfoField label="" value="Select a budget to upload" />
									<MUIInput
										onChange={changeHandler as unknown as ChangeEventHandler}
										name="file"
										type="file"
										inputProps={{ accept: '.xls,.xlsx' }}
									/>
								</Grid>
								{isImportedBudgetFile === false && (
									<Typography component="span" className={classes.importBudgetHelper}>
										Please select an excel type file.
									</Typography>
								)}
							</Grid>
						</form>
					)
				}}
				title="Uploading Budget"
				actions={importBudgetActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>

			{tableData && (
				<>
					<Grid container direction="row" justifyContent="flex-start" alignItems="center">
						<Grid item xs={4}>
							<div>
								<InputLabel className={classes.inputLabel}>Company</InputLabel>
								<Select
									name="company"
									label=""
									defaultValue={selectedCompany}
									onChange={setSelectedCompany}
									options={
										(companiesBudget &&
											companiesBudget.map(e => ({ id: e.searchKey, label: e.label }))) ||
										[]
									}
								/>
							</div>
						</Grid>
					</Grid>

					<Table
						tableData={filteredTableData}
						headerActions={pageTitleActions}
						columns={tableColumns}
						actions={tableActions}
						defaultSortColumn="updatedDate"
						defaultOrder="desc"
						handleSearch={{ searchValue, setSearchValue }}
						handleDiagramModal={{ diagramModal, setDiagramModal }}
					/>
				</>
			)}
		</>
	);
};

export default withLayout(Budget);
