/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IDeskResourcesSeat } from 'pages/PersonalInfo/Bookings/@types';

import { ISkillsFeedback } from './admin/skillsAndResumes';
import { IChoosableBaseInfo } from './generalTypes';
import { ICompanyInfo } from './admin/proposals';
import { ICurrentCSC } from './admin/employeeSalaryCategories';

export enum PersonalInfoActions {
	SET_LOADING = '@@personalInfo/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',

	// Bookings
	GET_DESK_BOOKING = '@@deskBooking/GET_DESK_BOOKING_INFO',
	GET_DESK_BOOKINGS_LIST = '@@deskBooking/GET_DESK_BOOKINGS_LIST',
	GET_EMPLOYEE_BOOKINGS = '@@deskBooking/GET_EMPLOYEE_BOOKINGS',
	SEND_CONFIRM_BOOKING = '@@deskBooking/SEND_CONFIRM_BOOKING',
	GET_EMPLOYEE_PENDING_COUNT = '@@deskBooking/GET_EMPLOYEE_PENDING_COUNT',
	SEND_DELETE_BOOKING = '@@deskBooking/SEND_DELETE_BOOKING',
	SET_UPDATE_BOOKINGS = '@@deskBooking/SET_UPDATE_BOOKINGS',
	GET_EXPORT_BOOKINGS = '@@deskBooking/GET_EXPORT_BOOKINGS',
	CLEAR_BOOKING = '@@deskBooking/CLEAR_BOOKING',

	// Profile
	GET_USER_PROFILE = '@@profile/GET_USER_PROFILE',
	SET_UPDATE_USER_PROFILE = '@@profile/SET_UPDATE_USER_PROFILE',
	SET_NEW_USER_PROFILE_PIC = '@@profile/SET_NEW_USER_PROFILE_PIC',
	CLEAR_USER_PROFILE = '@@profile/CLEAR_USER_PROFILE',
	CLEAR_CONVOCATION_MESSAGE_CONFIG = '@@profile/CLEAR_CONVOCATION_MESSAGE_CONFIG',

	// Company
	GET_ALL_EMPLOYEES = '@@company/GET_ALL_EMPLOYEES',
	GET_USER_TEAM = '@@skills/GET_USER_TEAM',

	// Skills
	GET_SYSTEM_SKILLS = '@@skills/GET_SYSTEM_SKILLS',
	GET_USER_SKILLS = '@@skills/GET_USER_SKILLS',
	UPDATE_USER_SKILLS = '@@skills/UPDATE_USER_SKILLS',
	UPDATE_SYSTEM_SKILLS = '@@skills/UPDATE_SYSTEM_SKILLS',
	CLEAR_SYSTEM_SKILLS = '@@skills/CLEAR_SYSTEM_SKILLS',
	// Resume
	GET_USER_RESUME = '@@resume/GET_USER_RESUME',
	SEND_USER_RESUME = '@@resume/SEND_USER_RESUME',
	// Trainings
	GET_USER_TRAININGS = '@@trainings/GET_USER_TRAININGS',
	// Benefits
	GET_USER_BENEFITS = '@@benefits/GET_USER_BENEFITS',
	// Career
	GET_USER_CAREER = '@@career/GET_USER_CAREER',
	// Financial Documents
	GET_USER_FINANCIAL_DOCUMENTS = '@@finDocuments/GET_USER_FINANCIAL_DOCUMENTS',
	DOWNLOAD_USER_DOCUMENT = '@@finDocuments/DOWNLOAD_USER_DOCUMENT',
	GET_PENDING_CONVOCATIONS_MESSAGE = '@@profile/GET_PENDING_CONVOCATIONS_MESSAGE',
	// Xtracker - MyTimesheets
	GET_WEEK_VIEW_DATA = 'GET_WEEK_VIEW_DATA',
	GET_WEEK_VIEW_DATA_FOR_COPY = 'GET_WEEK_VIEW_DATA_FOR_COPY',
	GET_PERIODLIST_FILTERS_DATA = 'GET_PERIODLIST_FILTERS_DATA',
	GET_DAY_VIEW_DATA = 'GET_DAY_VIEW_DATA',
	GET_EXPORT_TIMESHEET = 'GET_EXPORT_TIMESHEET',
	GET_IMPORT_TIMESHEET = 'GET_IMPORT_TIMESHEET',
	MOVE_TIMEENTRY = 'MOVE_TIMEENTRY',
	DELETE_MOVE_TIMEENTRY = 'DELETE_MOVE_TIMEENTRY',
	SAVE_TIMEENTRIES_COPY = 'SAVE_TIMEENTRIES_COPY',
	CLEAR_COPY_MESSAGE = 'CLEAR_COPY_MESSAGE',
	GET_EXPORT_TIMESHEET_CSV = 'GET_EXPORT_TIMESHEET_CSV',
	SAVE_TIMESHEET = 'SAVE_TIMESHEET',
	GET_ATTACHMENT_LIST = 'GET_ATTACHMENT_LIST',
	SET_LOADING_FALSE = 'SET_LOADING_FALSE',
	// Xtracker - MyExpenses
	GET_EXPENSES_DATA = 'GET_EXPENSES_DATA',
	GET_EXPENSE_SHEET_DATA = 'GET_EXPENSE_SHEET_DATA',
	GET_NEW_EXPENSE_LINE_DATA = 'GET_NEW_EXPENSE_LINE_DATA',
	GET_EXPENSES_AUDIT_DATA = 'GET_EXPENSES_AUDIT_DATA',
	SEND_NEW_EXPENSE_SHEET = 'SEND_NEW_EXPENSE_SHEET',
	SEND_UPDATE_EXPENSE_SHEET = 'SEND_UPDATE_EXPENSE_SHEET',
	SEND_NEW_EXPENSE_LINE = 'SEND_NEW_EXPENSE_LINE',
	GET_EXPENSE_ATTACHMENT_LIST = 'GET_EXPENSE_ATTACHMENT_LIST',
	GET_SUBMIT_EXPENSE_SHEET = 'GET_SUBMIT_EXPENSE_SHEET',
	CLEAR_EXPENSE_SHEET_DATA = 'CLEAR_EXPENSE_SHEET_DATA',
	COPIED_EXPENSE_SHEET = 'COPIED_EXPENSE_SHEET'
}

export type PersonalInfoSate = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	// Booking
	readonly deskBooking: IBooking | null;
	readonly deskBookingsList: IBookings | null;
	readonly countPending: number | null;
	readonly employeeBookings: IEmployeeBookings | null;
	readonly deskBookingActions: INewBooking | null;
	readonly exportBookings: unknown | null;
	// Profile
	readonly userProfile: IUserProfile | null;
	readonly userProfileAction: null;
	// Company
	readonly allEmployees: any | null;
	readonly userTeam: any | null;
	// Skills
	readonly userSkills: IUserSkills | null;
	readonly systemSkills: ISystemSkills[] | null;
	readonly skillsAction: null;
	// Resume
	readonly userResume: IUserResume | null;
	readonly resumeAction: null;
	// Trainings
	readonly userTrainings: ITrainings | null;
	// Benefits
	readonly userBenefits: IBenefits | null;
	// Career
	readonly userCareer: ICareer | null;
	// Financial Documents
	readonly userFinancialDocuments: IFinancialDocuments | null;
	// Xtracker - MyTimesheets
	readonly weekViewData: unknown | null;
	readonly weekViewDataForCopy: unknown | null;
	readonly attachList: unknown | null;
	readonly getPeriodListFiltersData: unknown | null;
	readonly dayViewData: unknown | null;

	readonly userPendingConvocationMessage: string | null | undefined;
	readonly acknowledgedNewRelease: boolean;

	readonly convocationMessageHasChanged: boolean;
	readonly exportTimesheetList: unknown | null;
	readonly isMoved: string | null;
	readonly copySaveMessage: unknown | null;
	readonly saveTimesheetMessage: unknown | null;

	// Xtracker - MyExpenses
	readonly expensesData: unknown | null;
	readonly newExpenseSheet: unknown | null;
	readonly newExpenseLineData: unknown | null;
	readonly updateExpenseSheet: unknown | null;
	readonly expenseSheetData: unknown | null;
	readonly expensesAuditData: unknown | null;
	readonly newExpenseLine: unknown | null;
	readonly expenseAttachList: unknown | null;
	readonly expenseSheetSubmitMessage: unknown | null;
	readonly copiedExpenseSheetId: string | null;
};

/* Bookings */
export interface IBooking {
	companies: IDeskCompany[];
	users: ILDAPData[];
}

export interface IDeskCompany {
	id: number;
	searchKey: string;
	name: string;
	erpClientId: string;
	offices: IDeskOffice[];
}

export interface INewBooking {
	type: 'CREATED' | 'CONFIRMED' | 'DELETED';
	response: string;
	payload?: IBookingItem[];
	data: { created: Array<{ customId: string | number; id: number }> };
}

export interface IDeskOffice {
	id: number;
	company: {
		id: number;
	};
	searchKey: string;
	name: string;
	active: boolean;
	zones: IDeskZone[];
}
export interface IDeskZone {
	id: number;
	name: string;
	active: boolean;
	seats: IDeskSeat[];
}
export interface IDeskSeat {
	id: number;
	type: string;
	name: string;
	active: boolean;
}

export interface IBookings {
	bookings: IBookingItem[];
	isAdmin: boolean;
	pendingBookingsCounter: number;
	parkingBookingsLimit: number;
	userParkingBookingsCount: number;
	seatRequiredToBookParking: boolean;
}
export interface IBookingItem {
	[x: string]: any;
	id: number;
	customId?: number;
	resourceId: number;
	creationDate: string;
	createdBy: string;
	lastUpdateDate: string;
	lastUpdatedBy: string;
	startDate: string;
	employee: string;
	username?: string;
	validated: boolean;
	endDate: string;
	duration: number;
	durationUnit: string;
	eventColor: string;
	dragabble: boolean;
	resizable: boolean;
	seat?: IDeskResourcesSeat;
}
export interface IEmployeeBookings {
	approved: IBookingItem[];
	pending: IBookingItem[];
}

/* Personal Info */
export interface ICountryInfo {
	id: string;
	name: string;
	description: string;
	hasRegions: boolean;
	iSOCountryCode: string;
	language$_identifier: string;
}

export interface ISkillsTree {
	tree: ISystemSkills[];
}

export interface IBanksInfo {
	id: string;
	name: string;
	swiftCode: string;
}

export interface IBankAccount {
	accountNo: string;
	bankFormat: string;
	bankName: string;
	businessPartner: string;
	country: string;
	displayedAccount: string;
	iBAN: string;
	id: string;
	showGeneric: false;
	showIBAN: false;
	swiftCode: string;
	xphrXphrBank: string;
	xphrXphrBank$_identifier: string;
}
export interface IBenefit {
	active: boolean;
	amount: number;
	amountPercentage: number;
	benefitsCategory: string;
	benefitsCategory$_identifier: string;
	businessPartner: string;
	client: string;
	description: string;
	endDate: string;
	extraInformation: string;
	extraInformation$_identifier: string;
	id: string;
	number: string;
	paidByCompany: boolean;
	payslipItem: boolean;
	receivable: boolean;
	startDate: string;
	supportedAmount: number;
	xphrDefBenCatName: string;
	xphrDefBenCatName$_identifier: string;
	xphrDefBenCatSupplier: string;
	xphrDefBenCatSupplier$_identifier: string;
}

export interface IJobroleData {
	id: string;
	name: string;
	overview: string;
	responsabilities: string;
}

export interface IProfileEligibleRoleData {
	active: boolean;
	id: string;
	levels: unknown; // ???
	name: string;
	xphrJobrole: string;
	xphrJobroleData: IJobroleData;
	xphrProfile: string;
}

export interface IProfileRoleLevelData {
	id: string;
	active: boolean;
	xphrProfileEligibleRole: string;
	name: string;
	careerLevel: string;
	careerSubLevel: string;
	place: string;
	units: number;
	displayOrder: number;
	xphrSalaryBand: string;
	availableOnProjRequest: boolean;
	roleAutonomy: string;
	roleMaturity: string;
	roleResponsability: string;
}

export interface ICareerLevel {
	id: string;
	profile: string;
	profile$_identifier: string;
	startDate: string;
	xphrProfileEligibleRole: string;
	xphrProfileEligibleRoleData: IProfileEligibleRoleData;
	xphrProfileRoleLevel: string;
	xphrProfileRoleLevelData: IProfileRoleLevelData;
}

export interface IEmail {
	contacttype: string;
	id: string;
	value: string;
}

export interface IEmergency {
	comments: string;
	contacttype: string;
	id: string;
	value: string;
}
export interface ILaptopInformation {
	assetCategory: string;
	description: string;
	id: string;
	name: string;
	searchKey: string;
	serialNo: string;
}

export interface ILocation {
	addressLine1: string;
	addressLine2: string;
	cityName: string;
	country: string;
	id: string;
	postalAdd: string;
	postalCode: string;
}
export interface IMobilePhone {
	contacttype: string;
	id: string;
	value: string;
}

export interface IPrivateData {
	active: boolean;
	biometricSysConsent: boolean;
	bpartner: string;
	client: string;
	clotheSizeConsent: boolean;
	clothingSize: string;
	contactInfoConsent: boolean;
	demogInfoEngConsent: boolean;
	demogInfoTraConsent: boolean;
	disabilities: string;
	familyInfoConsent: boolean;
	foodAllergiesIntolerances: string;
	healthConditions: string;
	id: string;
	intranetSysConsent: boolean;
	lifeHistoryConsent: boolean;
	promoMediaConsent: boolean;
	sensitiveInfoConsent: boolean;
	startDate: string;
}

export interface ITraining {
	certificationDate: string;
	certificationOrder: number;
	certificationType: string;
	cost: number;
	course: string;
	description: string;
	endingDate: string;
	certificationExpiryDate?: string;
	formationField: string;
	formationModality: string;
	formationPlace: string;
	hoursnumber: number;
	id: string;
	initiative: string;
	name: string;
	period: string;
	place: string;
	preBolognaCourse: false;
	schedule: string;
	startingDate: string;
	status: string;
}

export interface IERPData {
	admissionDate: string;
	bankAccount: IBankAccount;
	benefits: IBenefit;
	businessPartnerCategory: string;
	careerLevel: null;
	client: string;
	currentCsc: ICurrentCSC;
	email: IEmail;
	emergency: IEmergency;
	id: string;
	laptopsInformation: ILaptopInformation[];
	lastCscInProgressStatus: number;
	location: ILocation;
	mobile: IMobilePhone;
	name: string;
	privateData: IPrivateData;
	referenceNumber: number;
	searchKey: string;
	taxID: string;
	trainings: ITraining[];
	xPHRAdOrgBunit: string;
	xPHRAdOrgBunit$_identifier: string;
	xPHRChildrenNUMBER: number;
	xPHRCitizenCardExpirationDate: string;
	xPHRDateOfBirth: string;
	xPHRIDNUMBER: string;
	xPHRMaritalStatus: string;
	xPHRSOCIALSECURITYNUMBER: string;
	xphrAdOrgDepartment: string;
	xphrAdOrgDepartment$_identifier: string;
	xphrAdOrgDivision: string;
	xphrAdOrgDivision$_identifier: string;
	xphrDisable: boolean;
	xphrEmployeeOffice: string;
	xphrEmployeeWorkOffice: string;
	xphrEmployeeWorkOfficeDescription: string;
	xphrGender: string;
	xphrHoldersNumber: number;
	xphrIdControlDigits: string;
	xphrNationality: string;
	xphrNumberOfDependents: number;
}

export interface ILDAPData {
	companyCode: string;
	department: string;
	displayName: string;
	email: string;
	firstName: string;
	lastName: string;
	officeName: string;
	phone: string[];
	username: string;
	companyAzureDescription: string;
}

export interface IUserInfo {
	company: ICompanyInfo;
	erpData: IERPData;
	ldapData: ILDAPData;
}

export interface IUserProfile {
	[x: string]: any;
	acknowlegedPrivacyStatement: boolean;
	banks: IBanksInfo[];
	countries: ICountryInfo[];
	hasUpdatedConsents: boolean;
	maritalStatus: IChoosableBaseInfo[];
	user: IUserInfo;
	pendingConvocation: IConvocation;
	external: boolean;
	roles: IRolesInfo[];
}

export interface IRolesInfo {
	id: number;
	name: string;
}
export interface IConvocation {
	appUserid: number;
	id: number;
	locations: IConvocationLocations[];
	status: string;
	type: string;
}

export interface IConvocationLocations {
	address: string;
	convocationId: number;
	endDate: string;
	id: number;
	link: string;
	linkEmbed: string;
	location: string;
	startDate: string;
}

export interface IUpdateType {
	type: string;
}

export interface IUpdateContacts {
	phoneNumbers: string[];
}

export interface IUpdateCompanyAllocation {
	businessUnit: string;
	division: string;
	department: string;
}

export interface IUpdatePassword {
	password: string;
	oldPassword: string;
	confirmPassword: string;
}

export interface IUpdatePersonalInfo {
	citizenCardExpirationDateString: string;
	emergencyContact: string;
	emergencyContactName: string;
	addressLine1: string;
	addressLine2: string;
	postalCode: string;
	city: string;
	countryId: string;
	personalEmail: string;
}

export interface IUpdateFiscalInfo {
	maritalStatus: string;
	holdersNumber: number;
	numberOfDependents: number;
}

export interface IUpdateBankInfo {
	bankId: string;
	iban: string;
}

export interface IUpdatePrivateData {
	biometricSysConsent: boolean;
	intranetSysConsent: boolean;
	promoMediaConsent: boolean;
	contactInfoConsent: boolean;
	demogInfoEngConsent: boolean;
	demogInfoTraConsent: boolean;
	familyInfoConsent: boolean;
	lifeHistoryConsent: boolean;
	clotheSizeConsent: boolean;
	sensitiveInfoConsent: boolean;
	clothingSize: string;
	disabilities: string;
	foodAllergiesIntolerances: string;
	healthConditions: string;
}

export interface IUpdatePasswordAdmin {
	isAdmin?: string;
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}
export interface IUpdatePhoto {
	file: boolean;
}

export interface IUpdateReadConsents {
	status: boolean;
}

/* Emloyee Skills */

export interface ISkillEvaluation {
	id: number;
	experienceLevel: number;
	knowledgeLevel: number;
	skillId: number;
}

export interface IUserSkills {
	careerStartDate: string;
	employee: string;
	evaluations: ISkillEvaluation[];
	feedbacks: ISkillsFeedback[];
	firstSubmittedDate: string;
	id: number;
	lastSubmittedDate: string;
	lastUpdateDate: string;
	name: string;
	nextEvaluationDueDate: string;
	nextReminderDate: string;
	position: string;
	status: number;
	subGroups: IEmployeeSubGroups[];
}

export interface IEmployeeSubGroups {
	id: number;
	skillSubGroupId: number;
}

export interface ISkillAction {
	areaId: number | null;
	groupId: number | null;
	subGroupId: number | null;
	skillsId?: number | null;
	contextName?: string;
}

export interface ISkill {
	id: number;
	name: string;
	skillSubGroupId?: number;
	customId?: number;
	editable?: boolean;
	actions?: any[];
	data?: ISkill[];
}

export interface ISkillSubGroup {
	id?: number;
	name?: string;
	section?: boolean;
	skillGroupId?: number;
	skills?: ISkill[];
	customId?: number;
	data?: ISkill[];
	actions?: any[];
	editable?: boolean;
}

export interface ISkillGroup {
	id?: number;
	name?: string;
	skillAreaId?: number;
	skillSubGroups?: ISkillSubGroup[];
	weight?: number;
	customId?: number;
	data?: ISkillSubGroup[];
	editable?: boolean;
	actions?: any[];
}

export interface ISystemSkills {
	id?: number;
	name?: string;
	skillGroups?: ISkillGroup[];
	weight?: number;
	data?: ISkillGroup[];
	toDelete?: boolean;
	customId?: number;
	actions?: any[];
}

export interface ISkillLevel {
	knowledgeLevel: number;
	experienceLevel: number;
}

export interface ISkillsSubGroupUpdate {
	[index: number]: ISkillLevel;
}
export interface ISkillsGroupUpdate {
	[index: number]: ISkillsSubGroupUpdate;
}

export interface ISkillsUpdate {
	type: string;
	careerStartDate: string | null;
	skills: ISkillsGroupUpdate;
}

/* RESUME */

export interface ICourseQualifications {
	id: string;
	name: string;
	searchKey: string;
}

export interface IPersonalInfo {
	id?: number;
	location?: string;
	nationality?: string;
	order?: number;
	updateDate?: string;
	username?: string;
	address?: string;
	birthday?: string;
	gender?: string;
	phone?: string;

	nativeLanguage: string;
	countryOrigin: string;
	countryCode: string;
	birthplace: string;
	description: string;
}

export interface IResumeEducation {
	id?: number | string;
	order?: number;
	classification: string;
	startDate: string;
	endDate: string;
	mainSubjects: string;
	minor: string;
	major: string;
	organizationName: string;
	certificationAwarded: string;
}

export interface IResumeWorkExperience {
	id?: number | string;
	order?: number;
	startDate: string;
	endDate: string;
	businessSector: string;
	occupation: string;
	mainActivities: string;
}

export interface IResumeWorkshop {
	id?: number | string;
	order?: number;
	date: string;
	location: string;
	subject: string;
}

export interface IResumeOtherProjects {
	id?: number | string;
	order?: number;
	name: string;
	mainActivities: string;
}

export interface IResumeTraining {
	id?: number | string;
	order?: number;
	startDate: string;
	endDate: string;
	organizationName: string;
	mainSubjects: string;
	certificationAwarded: string;
	level: string;
}

export interface IResume extends IPersonalInfo {
	education: IResumeEducation[];
	otherProjects: IResumeOtherProjects[];
	training: IResumeTraining[];
	workExperience: IResumeWorkExperience[];
	workshops: IResumeWorkshop[];
}
export interface IUserResume {
	initialData: IPersonalInfo;
	countries: ICountryInfo[];
	courseQualifications: ICourseQualifications[];
	resume: IResume;
	systemSkills: ISystemSkills[];
	userSkills: IUserSkills;
}

export interface IPostResumePayload {
	personalInfo: IPersonalInfo[];
	education: IResumeEducation[];
	workExperience: IResumeWorkExperience[];
	workshops: IResumeWorkshop[];
	otherProjects: IResumeOtherProjects[];
	training: IResumeTraining[];
}

/* Tranining */

export interface ITrainings {
	trainings?: ITraining[];
}

/* Benefits */
export interface IBenefits {
	benefits: IBenefit[];
}

/* career */
export interface IProfileElegibleRoleData {
	active: boolean;
	id: string;
	levels: unknown[];
	name: string;
	xphrJobrole: string;
	xphrJobroleData: IJobroleData;
	xphrProfile: string;
}

export interface ICareer {
	id: string;
	profile: string;
	profile$_identifier: string;
	startDate: string;
	xphrProfileEligibleRole: string;
	xphrProfileEligibleRoleData: IProfileElegibleRoleData;
	xphrProfileRoleLevel: string;
	xphrProfileRoleLevelData: IProfileRoleLevelData;
}

export interface IDocument {
	uploadDate: string;
	documentType: string;
	documentName: string;
}
export interface IFinancialDocuments {
	documents: IDocument[];
}

export interface IAccountEmployeeExpenseSheetAudit {
	id: number;
	type: string;
	tableName: string;
	pk: string;
	fieldName: string;
	oldValue: string;
	newValue: string;
	updateDate: Date;
	userName: string;
	updateBy: string;
	accountId: number;
}

export interface IAccountExpenseEntryAudit {
	id: number;
	type: string;
	tableName: string;
	pk: string;
	fieldName: string;
	oldValue: string;
	newValue: string;
	updateDate: Date;
	userName: string;
	updatedBy: string;
	accountProjectId: number;
	accountExpenseId: number;
	amount: number;
	projectName: string;
	accountExpenseName: string;
	accountId: number;
}
