/* eslint-disable indent */

import { ISidebarGroupItem } from 'xpand-ui/core';
import { IUserPermission } from 'typings/store/systemTypes';
import { setLSField, removeLSField, getLSField } from 'lib/utils/cookies';
import { pages } from 'lib/routes/pages';

import { IAzureAccount } from 'lib/hocs/ProjectProviders/@types';
import { ITitle } from './TitleProvider';

const validDynamicPaths = (original: string, current: string): boolean => {
	// eslint-disable-next-line no-useless-escape
	const regex = /\:(.*?)\//g;
	const payload1 = original
		.replaceAll(regex, '/')
		.split('/')
		.map(e => (e.includes(':') ? '' : e));
	const payload2 = current.replaceAll(regex, '/').split('/');

	let valid = true;
	payload1.forEach((e, i) => {
		if (e !== '' && !!valid) {
			valid = payload2[i] === e;
		}
	});

	return valid;
};

export const getTitle = (payload: string): ITitle => {
	interface NewSidebarItem extends ISidebarGroupItem {
		title?: string;
	}

	let pageFound!: NewSidebarItem | null;

	Object.keys(pages).map(page => {
		if (pages[page].path === payload) pageFound = pages[page];
		return page;
	});

	if (!pageFound) {
		Object.keys(pages).map(page => {
			if (pages[page].path.includes(':') && validDynamicPaths(pages[page].path, payload)) {
				pageFound = pages[page];
			}

			return page;
		});
	}
	if (pageFound) {
		return { title: pageFound.title || pageFound.label || '', icon: pageFound.titleIcon };
	}

	return { title: ' ', icon: null };
};

// Manually create the accountInfo object with data retrieved from azureAD
export const loadAccountInfo = () => {
	const impersonateUserInfoStorage = getLSField('impersonate_userInfo');
	let impersonateUserInfo = (impersonateUserInfoStorage && JSON.parse(impersonateUserInfoStorage)) || null;

	const storageObject = sessionStorage.getItem('auxStorage');
	const jsonStorageObject = JSON.parse(storageObject!);

	if (impersonateUserInfo) {
		const accountInfo: IAzureAccount = { name: '', email: '', company: '', username: '' };
		accountInfo.name = impersonateUserInfo?.name;
		accountInfo.email = impersonateUserInfo?.email;
		accountInfo.company = impersonateUserInfo?.company;
		accountInfo.username = impersonateUserInfo?.username;
		return accountInfo;
	} else if (storageObject) {
		const accountInfo: IAzureAccount = { name: '', email: '', company: '', username: '' };
		accountInfo.name = jsonStorageObject?.idTokenClaims?.name;
		accountInfo.email = jsonStorageObject?.idTokenClaims?.preferred_username;
		accountInfo.company = jsonStorageObject?.idTokenClaims?.companyName;
		accountInfo.username = jsonStorageObject?.idTokenClaims?.mailNickname;
		return accountInfo;
	}
	return undefined;
};

// UpdateUserInfo
export const updateUserInfo = (isAuthed: string | undefined, accountInfo: IAzureAccount): void => {
	if (!isAuthed) {
		removeLSField('userInfo');
	}

	if (accountInfo && accountInfo) {
		setLSField(
			'userInfo',
			JSON.stringify({
				name: accountInfo.name,
				email: accountInfo.email,
				username: accountInfo.username,
				company: accountInfo.company
			})
		);
	}
};

const checkRolesNeeded = (roles: string[], perms: IUserPermission[]) =>
	roles.filter(roleNeeded => perms.find(({ name }) => name.toLowerCase() === roleNeeded.toLowerCase())).length > 0;

const checkSubMenu = (payload: ISidebarGroupItem, perms: IUserPermission[], isExternal: boolean | undefined) => {
	const newMenu = payload.subMenu
		? payload.subMenu
				.map(subMenuItem => {
					const newSubItem = { ...subMenuItem, render: true };
					// Check External user access
					if (isExternal && !subMenuItem.allowExternalAccess) {
						return null;
					}
					// SUBITEM WITH ROLES
					if (subMenuItem.roles) {
						return checkRolesNeeded(subMenuItem.roles, perms) ? newSubItem : null;
					}
					return newSubItem;
				})
				.filter(k => k !== null)
		: [];

	return { ...payload, subMenu: newMenu };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkAvailableMenu = (
	menu: ISidebarGroupItem[],
	perms: IUserPermission[],
	isExternal: boolean | undefined
): ISidebarGroupItem[] => {
	try {
		const filteredPages = menu.map(e => {
			// Check External user access
			if (isExternal && !e.allowExternalAccess) {
				return null;
			}

			// ITEM W/ROLES Wout/SUBMENU
			if (e.roles && !e.subMenu) {
				return checkRolesNeeded(e.roles, perms) ? e : null;
			}

			// ITEM Wout/ROLES W/SUBMENU
			if (!e.roles && e.subMenu) {
				return checkSubMenu(e, perms, isExternal);
			}

			// ITEM W/ROLES W/SUBMENU
			if (e.roles && e.subMenu) {
				if (checkRolesNeeded(e.roles, perms)) {
					return checkSubMenu(e, perms, isExternal);
				}
				return null;
			}

			// ITEM Wout/ROLES Wout/SUBMENU
			return e;
		});

		return filteredPages
			.filter(k => k !== null)
			.filter(k => (k && k.subMenu ? k.subMenu.length > 0 : true)) as ISidebarGroupItem[];
	} catch (e) {
		return [];
	}
};
