/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ChangeEvent } from 'react';

//* EXTERNAL LIBS --> MUI
import { FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle, Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';

//* TYPINGS
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { availableRoles, AVAILABLE_ROLES } from './utils';
import GrantAccess from './GrantAccess';
import { useStyles } from './styles';
import { AccessControlProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IAccessControl extends AccessControlProps {
	goToPage: (path: string) => void;
}

//* COMPONENT
const AccessControl: FC<IAccessControl> = ({
	goToPage,
	administration,
	filters,
	getAccessControlList,
	sendRemoveAllRolesAndAccesses,
	clearAdministrationError,
	setAccessControlPageFilter
}) => {
	const classes = useStyles();
	const { accessControlList, loading, error } = administration;
	const { accessControlFilter, accessControlRolesFilter } = filters;

	// TABLE COMPONENT - custom filter state field List
	const [rolesFilter, setRolesFilter] = useState(accessControlRolesFilter);

	// 1: false, // SYSTEM_ADMINISTRATOR
	// 2: false, // ADMINISTRATOR
	// 3: false, // BUSINESS_UNIT_MANAGER
	// 4: false, // USERS_MANAGER
	// 5: false, // CSC_APPROVERS
	// 6: false, // CSC_USER
	// 7: false, // COLLABORATION_PROPOSAL_USER
	// 8: false, // FORMS_USER
	// 9: false, // SKILLS_USER
	// 10: false, // BUDGET_ADMINISTRATOR
	// 11: false, // BUDGET_USER
	// 12: false, // ADMISSIONS_USER
	// 13: false, // ACCESS_CONTROLLER
	// 14: false, // SKILLS_ADMINISTRATOR
	// 15: false, // RESUMES_USER
	// 16: false, // PLANNER_USER
	// 17: false // BOOKINGS_ADMINISTRATOR

	// ADMISSIONS STATUS HELPER
	const rolesHelper = useMemo(() => availableRoles(), []);

	const [confirmModal, setConfirmModal] = useState<number | boolean>();
	const [editModal, setEditModal] = useState(null);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(accessControlFilter);

	useEffect(() => {
		setAccessControlPageFilter({ searchValue, rolesFilter });
	}, [searchValue, rolesFilter]);

	useEffect(() => {
		if (!error) {
			getAccessControlList();
		}
	}, []);

	const confirmRemoveAll = (payload: number) => {
		sendRemoveAllRolesAndAccesses(payload);
		setConfirmModal(false);
	};

	const handleCloseModal = () => {
		setEditModal(null);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				width: '5%',
				format: row => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'User',
				id: 'username',
				width: '15%'
			},
			{
				label: 'Name',
				id: 'name',
				width: '20%'
			},
			{
				label: 'Roles List',
				id: 'rolesID',
				width: '20%',
				format: row => {
					if (row.rolesList) {
						return <div>{row.rolesList}</div>;
					}

					return <div>N/A</div>;
				}
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'editAction',
				type: 'button',
				icon: <EditIcon />,
				render: () => true,
				disabled: () => false,
				onClick: row => setEditModal(row.id)
			},
			{
				id: 'removeAction',
				type: 'button',
				icon: <DeleteIcon />,
				render: () => true,
				disabled: () => false,
				onClick: row => setConfirmModal(row.id)
			}
		],
		[accessControlList]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'grantAccess',
				to: '/cp/admin/administration/accessControl/addUser',
				onClick: () => goToPage('/admin/administration/accessControl/addUser'),
				icon: <Adicionar />,
				label: 'Grant Access to User'
			}
		],
		[]
	);

	// TABLE DATA
	const tableData = useMemo(
		() =>
			accessControlList?.users?.map(e => ({
				...e,
				rolesList: e.roles?.map(k => k.name).join(', '),
				rolesID: e.roles[0].id
			})),
		[accessControlList]
	);

	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleRolesFilterPropsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setRolesFilter({ ...rolesFilter, [event.target.name]: event.target.checked });
	};

	const rolesFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{rolesHelper
					.sort((a: any, b: any) => b.listPosition + a.listPosition)
					.map(e => (
						<FormControlLabel
							key={e.name}
							control={
								<Checkbox
									color="primary"
									icon={<RadioButtonUnchecked />}
									checkedIcon={<CheckCircle />}
									checked={rolesFilter[e.id]}
									onChange={handleRolesFilterPropsChange}
									name={`${e.id}`}
								/>
							}
							label={e.label}
						/>
					))}
			</FormGroup>
		</FormControl>
	);

	const getRoleIdByListPosition = (position: string) => {
		// With the list position, we're able to get the status id that will be used by the filter
		const result = rolesHelper.find(role => role.id === parseInt(position));
		return result.label;
	};

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			rolesID: {
				anyToFilter: Object.values(rolesFilter).includes(true),
				filterComponent: rolesFilterComponent(),
				handleFilter: (info: any[]) => {
					const payloadToSearch = Object.keys(rolesFilter)
						.filter(field => rolesFilter[field])
						.map(e => getRoleIdByListPosition(e));

					return info.filter(item => {
						let separatedRoles = item.rolesList.split(', ');
						let rolesPresentInSearch = separatedRoles.map(role => payloadToSearch.includes(role));
						for (let i = 0; i < rolesPresentInSearch.length; i++) {
							if (rolesPresentInSearch[i] === true) {
								return true;
							}
						}
					});
				}
			}
		}),
		[rolesFilter]
	);

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = accessControlList === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				tableColumnsFilter={tableColumnsFilter}
				defaultSortColumn="name"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<Typography gutterBottom>Are you sure you want to remove all roles and accesses?</Typography>
					)
				}}
				title="Remove All Roles and Accesses"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'confirmRemoveAllRolesAndAccesses',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => confirmRemoveAll(confirmModal as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				modal={{
					open: Boolean(editModal),
					handleClose: handleCloseModal,
					content: <GrantAccess handleClose={handleCloseModal} userId={editModal} />
				}}
				actions={[]}
			/>
		</>
	);
};

export default withLayout(AccessControl);
