/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { FormHelperText } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, DualTransferList, PageTitle, ActionFooter } from 'xpand-ui/core';

//* TYPINGS
import { IChoosableBaseInfo } from 'typings/store/generalTypes';
import { IGrantAccessControl, IGrantAccessControlInfo } from 'typings/store/admin/administration';

//* PROJECT IMPORTS [LIB / PAGES ]
import { Roles } from 'lib/roles';
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import OrgAccesses from './OrgAccesses';
import { GrantAccessProps } from '.';
import { useStyles } from './styles';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IGrantAccess extends GrantAccessProps {
	userId: number;
	handleClose: () => void;
}

//* COMPONENT
const GrantAccess: FC<IGrantAccess> = ({
	administration,
	userId,
	handleClose,
	getAccessControlEditUser,
	sendPutAccessControl,
	clearEditUser
}) => {
	const classes = useStyles();
	const { accessControlEditUser } = administration;

	const [allRoles, setAllRoles] = useState<IChoosableBaseInfo[] | IGrantAccessControlInfo | null>([]);
	const [selectedRoles, setSelectedRoles] = useState<IChoosableBaseInfo[] | [] | undefined>([]);
	const [disabledRoles, setDisabledRoles] = useState<IChoosableBaseInfo[] | [] | undefined>([]);

	const {
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		reset,
		formState: { errors }
		// TODO Remove any typing
	} = useForm<any>({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(
		() => () => {
			clearEditUser();
		},
		[]
	);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (!accessControlEditUser) {
			getAccessControlEditUser(userId);
		} else if (accessControlEditUser && accessControlEditUser.roles && accessControlEditUser.user) {
			const left = accessControlEditUser.roles
				.filter(a => !accessControlEditUser?.user?.roles?.some(u => u.id === a.id))
				.sort((a, b) => Number(a.id) - Number(b.id));
			const right = accessControlEditUser?.user?.roles?.sort((a, b) => Number(a.id) - Number(b.id));

			if (right?.some(e => e.name === Roles.SYS)) {
				if (accessControlEditUser) {
					setAllRoles(accessControlEditUser?.roles?.filter(e => e.name !== Roles.SYS));
				}

				const roles = right?.find(e => e.name === Roles.SYS);

				setDisabledRoles(roles ? left : []);

				return setSelectedRoles(roles ? [roles] : []);
			}

			if (right?.some(e => e.name === Roles.ADM)) {
				if (accessControlEditUser) {
					setAllRoles(accessControlEditUser?.roles?.filter(e => e.name !== Roles.ADM));
				}

				const roles = right?.find(e => e.name === Roles.ADM);

				setDisabledRoles(roles ? left : []);

				setValue('roles', roles ? [roles] : []);

				return setSelectedRoles(roles ? [roles] : []);
			}

			setDisabledRoles(
				right && right.length && right.length >= 1
					? [
							{ id: 1, name: Roles.SYS },
							{ id: 2, name: Roles.ADM }
					  ]
					: []
			);
			setAllRoles(left);
			if (right) setSelectedRoles(right);
			setValue('roles', right);
		}
	}, [accessControlEditUser]);

	/** handle dual transfer list component changes */
	const handleListChanges = (l: IChoosableBaseInfo[], r: IChoosableBaseInfo[]) => {
		// Specific cases where the role is either SYSTEM ADMINISTRATOR or ADMINISTRATOR to prevent roles from being added
		// Note SYSTEM ADMINISTRATOR has priority over ADMINSTRATOR
		let leftList: IChoosableBaseInfo[] = [];
		if (r.some(e => e.name === Roles.SYS)) {
			// set left list
			if (accessControlEditUser) {
				leftList = accessControlEditUser?.roles?.filter(e => e.name !== Roles.SYS);
				setAllRoles(leftList);
			}

			const roles = r.find(e => e.name === Roles.SYS);

			setValue('roles', roles ? [roles] : []);

			setDisabledRoles(roles ? leftList : []);

			// set right list
			return setSelectedRoles(roles ? [roles] : []);
		}

		if (r.some(e => e.name === Roles.ADM)) {
			// set left list
			if (accessControlEditUser) {
				leftList = accessControlEditUser?.roles?.filter(e => e.name !== Roles.ADM);
				setAllRoles(leftList);
			}

			const roles = r.find(e => e.name === Roles.ADM);

			setDisabledRoles(roles ? leftList : []);

			setValue('roles', roles ? [roles] : []);

			// set right list
			return setSelectedRoles(roles ? [roles] : []);
		}

		setDisabledRoles(
			r.length >= 1
				? [
						{ id: 1, name: Roles.SYS },
						{ id: 2, name: Roles.ADM }
				  ]
				: []
		);
		setSelectedRoles(r);
		setValue('roles', r);
		return setAllRoles(l);
	};

	const formData: IGrantAccessControl = getValues();

	const onSubmit = () => {
		formData.id = userId;
		sendPutAccessControl(formData, userId);
		handleClose();
	};

	const footerActions = [
		{
			id: 'submit',
			label: 'Submit',
			onClick: () => ({}),
			type: 'submit',
			form: 'form-accessControl',
			variant: 'contained',
			disabled:
				selectedRoles?.length === 0 ||
				(selectedRoles && Number(selectedRoles[0].id) > 2 && formData.orgAccesses?.length === 0) ||
				getLSField('impersonate_userInfo')
		}
	];

	// FIXME any typing
	const duplicatedError: any = errors?.orgAccesses && typeof errors?.orgAccesses === 'object' && errors?.orgAccesses;

	const isLoading = accessControlEditUser === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<PageTitle title="Roles" />
			<form id="form-accessControl" className={classes.root} onSubmit={handleSubmit(onSubmit)}>
				<div style={{ width: '100%', maxWidth: 'calc(60vw)' }}>
					{/* **************** Roles ****************** */}
					<DualTransferList
						title="Roles"
						leftList={allRoles} // FIXME
						rightList={selectedRoles}
						disabledList={disabledRoles?.map(e => e.id)} // FIXME
						payloadId="id"
						payloadLabel="name"
						internalUpdate
						handleChanges={handleListChanges}
					/>
				</div>
				{/* **************** Org Accesses ****************** */}
				<OrgAccesses
					control={control}
					errors={errors}
					watch={watch}
					reset={reset}
					setValue={setValue}
					selectedRoles={selectedRoles}
					payload={accessControlEditUser}
				/>
			</form>
			{/* **************** FIXME any typing ****************** */}
			{duplicatedError && <FormHelperText error>{(duplicatedError as any)?.message}</FormHelperText>}
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(GrantAccess);
