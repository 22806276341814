/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import moment, { Moment } from 'moment';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-INTERNAL-APPS-LIB
import { DatePicker, InfoField, Select, Input, Checkbox } from 'xpand-ui/forms';
// import { parseDateToManage } from 'xpand-ui/utils/dates';
import { removeTimezone, parseDateToManageSaf } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { Dialog } from 'xpand-ui/core';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import { Roles } from 'lib/roles';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schema } from './yupSchema';
import { getRandomInt, parseEventData, sortDates } from '..';
import { IDeskEvent } from '../../@types';
import { getLSField } from 'lib/utils/cookies';

const parseDateToManage = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

//* COMPONENT INTERFACES
interface IBlockSeatsModal {
	closePopup: any;
	schedulerRef: any;
	isAdmin: boolean;
	loggedUser: IUserToken;
	eventRecord: any;
	eventStore: any;
	eventAction: any;
	resources: any;
	users: any[];
	toolbarSelected: {
		currentUser: string | null;
		company: string | null;
		office: string | null;
		companyName: string | null;
		officeName: string | null;
	};
}

const DateAux = new Date();
DateAux.setHours(0, 0, 0, 0);

// Admin Start Date (View 1 year back)
const dateStartAdmin = new Date();
dateStartAdmin.setDate(dateStartAdmin.getDate() - 365);
dateStartAdmin.setHours(0, 0, 0, 0);

const max90DaysFromNow = parseDateToManage(new Date(DateAux.setMonth(DateAux.getMonth() + 3)));

// Admin End Date
const dateEndAdmin = new Date();
dateEndAdmin.setMonth(dateEndAdmin.getMonth() + 3);
dateEndAdmin.setHours(0, 0, 0, 0);

//* COMPONENT
const BlockSeatsModal: FC<IBlockSeatsModal> = ({
	schedulerRef,
	isAdmin,
	resources,
	toolbarSelected,
	loggedUser,
	closePopup,
	eventRecord,
	eventStore,
	users
}) => {
	const classes = useStyles();
	// form
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		clearErrors,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: {
			name: eventRecord?.username?.toUpperCase() || loggedUser.username.toUpperCase(),
			fullName: loggedUser.name,
			createdBy: eventRecord?.username || loggedUser.username,
			username: eventRecord?.username || loggedUser.username,
			duration: eventRecord?.duration || 1,
			zoneId: eventRecord?.zoneId || '',
			resourceId: eventRecord?.resourceId || '',
			startDate: parseDateToManage(eventRecord?.startDate || new Date(), true).replace('00:00:00', '00:00:00'),
			endDate: parseDateToManage(eventRecord?.endDate || new Date(), true).replace('00:00:00', '23:59:00'),
			isAdmin,
			validated: true,
			previousId: '',
			previousStartDate: '',
			previousEndDate: '',
			isBlockedSeat: true,
			blockedReason: ''
		}
	});
	const userPermissionsStorage = getLSField('userPermissions');
	const userPermissions = (userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;
	let isBookingsAdmin = false;

	userPermissions.forEach((userPermission: any) => {
		if (userPermission.name === Roles.BOA) {
			isBookingsAdmin = true;
		}
	});

	/* A callback function that is used to check if the new event is overlapping with any weekend days */
	const blockAddEvent = useCallback((start: Date) => {
		const isWeekend = start.getDay() === 0 || start.getDay() === 6;
		return isWeekend;
	}, []);

	/* Splitting a multi-day event into single day events. */
	const splitDays = useCallback((event: IDeskEvent, initialEvent: { startDate: string | number | Date }) => {
		const events: IDeskEvent[] = [];
		if (event.duration > 1) {
			// remove old event
			eventStore.remove(eventRecord);

			// for the duration of the original event, add new events individually through all the days
			Array.from({ length: event.duration }, (_, i) => i).forEach(plusDay => {
				// SET START DATE AT 00:00:00
				const newStart = new Date(
					new Date(parseDateToManageSaf(initialEvent.startDate)).getTime() + plusDay * (1000 * 3600 * 24)
				);

				// SET END DATE EQUAL TO THE START, BUT AT 23:59:00
				const newEnd = new Date(
					new Date(parseDateToManageSaf(initialEvent.startDate)).getTime() +
						(plusDay + 1) * (1000 * 3600 * 24) -
						60
				);

				// If new singleDayEvent is weekend, dont add it
				if (!blockAddEvent(newStart, newEnd, event)) {
					events.push({
						...event,
						customId: `${getRandomInt()}`,
						duration: 1,
						startDate: newStart,
						endDate: newEnd
					});
				}
			});
		} else {
			return [event];
		}

		return events;
	}, []);

	/** handler to save the selected event payload into the store and DB */
	const saveClickHandler = async (payload: any, isBookingsAdmin: boolean) => {
		/* Parsing the event data and returning a new event object. */
		const newEvent = parseEventData({ ...payload, isAdmin }, loggedUser.username);

		const currentMoment = new Date();

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const tomorrow = new Date();
		tomorrow.setHours(0, 0, 0, 0);
		tomorrow.setDate(tomorrow.getDate() + 1);

		const inOneWeek = new Date();
		inOneWeek.setDate(inOneWeek.getDate() + 7);

		let dbChanges = null;
		let hasBlockedDays = false;

		/* if the startDate is bigger than the startDate(possible through the drag creation on bryntum), sort 
		those dates by destructuring the array returned by the sorter */
		const [sd, ed] = sortDates(newEvent.startDate, newEvent.endDate);
		/* Calculating the number of days between two dates. */
		const days = Math.ceil((ed.getTime() - sd.getTime()) / (1000 * 3600 * 24));
		newEvent.duration = days;

		//* event is created from bryntum
		if (eventRecord) {
			// We need to reset this flag to tell scheduler that this is a real event
			eventRecord.isCreating = false;

			// Update the eventRecord using the default setters
			eventRecord.beginBatch();

			// ? MULTIPLE DAYS EVENT ---> SPLIT IT TO SINGLE DAYS
			// ? (already filters if new singleDayEvent is weekend OR is overlapped by some other event, dont add it)
			const events = splitDays(newEvent, payload);

			if (newEvent.duration !== events.length) {
				hasBlockedDays = true;
			}
			dbChanges = events;
			if (events.length > 1) {
				// remove old event from the store (with duration > 1 day)
				eventStore.remove(eventRecord);
			} else {
				// ? SINGLE DAY ADD
				// If new singleDayEvent is weekend OR is overlapped by some other event, REMOVE IT
				if (blockAddEvent(newEvent.startDate, newEvent.endDate, newEvent)) {
					eventRecord.isCreating = true;
					eventRecord.endBatch();
					closePopup(null);
					addNotification('info', 'Blocking this seat was not possible.', 15);
					return;
				}

				// set correct values to edited event
				eventRecord.set(newEvent);

				// Add the eventRecord to the eventStore if it is not already there
				if (!eventRecord.eventStore) {
					await eventStore.add(eventRecord);
				}
			}
			eventRecord.endBatch();
		} else {
			//* event is created from MANUALLY top right button
			// ? MULTIPLE DAYS EVENT ---> SPLIT IT TO SINGLE DAYS
			// ? (already filters if new singleDayEvent is weekend OR is overlapped by some other event, dont add it)
			const events = splitDays(newEvent, payload);

			if (newEvent.duration !== events.length) {
				hasBlockedDays = true;
			}
			dbChanges = events;
			if (events.length < 1) {
				if (blockAddEvent(newEvent.startDate, newEvent.endDate, newEvent)) {
					closePopup(null);
					addNotification('info', 'Blocking this seat was not possible.', 10);
					return;
				}
			}
		}

		/* Checking if the hasBlockedDays variable is true, if it is, it will add a notification to the
		screen. */
		if (hasBlockedDays) {
			addNotification('info', 'Blocking this seat was not possible.', 10);
		}
		closePopup(dbChanges);
	};

	const filtersModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => closePopup(null)
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-desk-block-seats-modal',
			variant: 'contained',
			disabled:
				Object.keys(errors).length !== 0 ||
				getValues('resourceId').length === 0 ||
				getLSField('impersonate_userInfo'),
			onClick: () => saveClickHandler(getValues(), isBookingsAdmin)
		}
	];

	const currentZone = resources.find((e: any) => e.id === getValues('zoneId'));
	let seatOptions = [];
	if (currentZone) {
		seatOptions = currentZone.children.map((e: any) => ({
			id: e.id,
			label: e.name,
			type: e.type,
			restricted: e.restricted
		}));
	}

	/* Using the useWatch hook to watch the control object for changes to the resourceId property. */
	const resource = useWatch({
		control,
		name: 'resourceId',
		defaultValue: eventRecord?.resourceId
	});

	/* Filters users options according to selected seat */
	const usersOptions = useMemo(() => {
		if (!resource)
			return users.map((e: any) => ({
				id: e.username,
				label: `${e.displayName} (${e.username})`
			}));

		const currentSeat = seatOptions.find((e: any) => e.id === resource);

		if (!isAdmin && (currentSeat.type === 'PARKING' || currentSeat.type === 'BICYCLE')) {
			const userData = users.find((u: any) => u.username === loggedUser.username);
			if (userData) {
				setValue('username', loggedUser.username);
				return [{ id: userData.username, label: `${userData.displayName} (${userData.username})` }];
			}

			return [];
		} else {
			return users.map((e: any) => ({
				id: e.username,
				label: `${e.displayName} (${e.username})`
			}));
		}
	}, [resource, seatOptions]);

	return (
		<Dialog
			title={'Block Seats'}
			actions={filtersModalActions}
			scroll="body"
			modal={{
				// Sending Edit or Add mode
				open: true,
				handleClose: () => closePopup(null),
				content: (
					<form
						id="form-desk-block-seats-modal"
						onSubmit={e => handleSubmit(() => saveClickHandler(watch(), isBookingsAdmin))(e)}>
						<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
							<Grid item {...sizes[12]} className={classes.centerFieldInfo}>
								<InfoField label="Company" value={toolbarSelected.companyName} />
							</Grid>
							<Grid item {...sizes[12]} className={classes.centerFieldInfo}>
								<InfoField label="Office" value={toolbarSelected.officeName} />
							</Grid>
							<Grid item {...sizes[6]}>
								<DatePicker
									required
									desktopModeMediaQuery={undefined}
									maxDate={moment(max90DaysFromNow)}
									minDate={isAdmin ? moment(dateStartAdmin) : moment(DateAux.getDate(), 'DD/MM/YYYY')}
									name="startDate"
									label="Start Date"
									additionalOnChange={date => {
										const endDateHelper = watch('endDate');
										if (date && endDateHelper && endDateHelper !== '') {
											const start = new Date(parseDateToManage(date, true));
											const end = new Date(parseDateToManage(endDateHelper, true));

											if (start.getTime() > end.getTime()) {
												setValue('endDate', '');
											}
										}
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<DatePicker
									required
									desktopModeMediaQuery={undefined}
									maxDate={moment(max90DaysFromNow)}
									minDate={isAdmin ? moment(dateStartAdmin) : moment(DateAux.getDate(), 'DD/MM/YYYY')}
									label="End Date"
									name="endDate"
									additionalOnChange={date => {
										const startDateHelper = watch('startDate');
										if (date && startDateHelper && startDateHelper !== '') {
											const start = new Date(parseDateToManage(startDateHelper, true));
											const end = new Date(parseDateToManage(date, true));

											if (start.getTime() > end.getTime()) {
												setValue('startDate', '');
											}
										}
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									required
									name="zoneId"
									label="Zone"
									options={resources.map((e: any) => ({ id: e.id, label: e.name }))}
									additionalOnChange={e => {
										setValue('resourceId', []);
									}}
									control={control}
									errors={errors}
								/>
								<Checkbox
									name="allZoneSeats"
									label="All Zone Seats"
									helperText="Block all the seats for this zone."
									additionalOnChange={(val: boolean) => {
										clearErrors('resourceId');
										if (val) {
											const allIds = seatOptions?.map(seatOption => seatOption.id);
											setValue('resourceId', allIds);
										} else {
											setValue('resourceId', []);
										}
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									multiple
									name="resourceId"
									label="Location"
									disabled={watch('allZoneSeats')}
									options={seatOptions}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<Input
									required
									name="blockedReason"
									label="Reason"
									multiline
									minRows={4}
									maxRows={8}
									control={control}
									errors={errors}
								/>
							</Grid>
						</Grid>
					</form>
				)
			}}
		/>
	);
};

export default BlockSeatsModal;
