/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, {
	useState,
	FC,
	useEffect,
	useRef,
	useCallback,
	ChangeEventHandler,
	SetStateAction,
	Dispatch,
	useMemo
} from 'react';
import { ErrorPage } from 'xpand-ui/lab';
import { START_API_PATH } from 'lib/utils/constants';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { format, startOfDay, differenceInDays, isSameWeek } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import withLayout from 'lib/hocs/withLayout';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useForm } from 'react-hook-form';
import { Toggler, GoToCard, RightSidebar, Dialog, LoadingOverlay, Subtitles, Table, BackPage } from 'xpand-ui/core';
import { XpandedDatePicker, Select as LibSelect, InfoField, Input as LibInput } from 'xpand-ui/forms';
import { MyTimesheetsProps } from '.';
import { addNotification } from 'lib/utils/notifications';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { sizes } from 'xpand-ui/utils/handlers';
import { Eventcalendar, Popup, formatDate, momentTimezone } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './MyTimesheets.css';
import {
	Card,
	Button,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Typography,
	Badge,
	Input
} from '@mui/material';
import {
	ArrowDropDown,
	MoreVert,
	AttachFile,
	Download,
	Delete,
	Edit,
	PublishedWithChangesOutlined
} from '@mui/icons-material';
import { Pie, ChartCard } from 'xpand-ui/graphs';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { setSaveTimesheet } from 'store/personalInfo/actions';
import TextField from '@mui/material/TextField';
import { IUserToken } from 'typings/store/generalTypes';
import CalendarWeek from '../../../../assets/icons/calendar-week.svg';
import CalendarWorkWeek from '../../../../assets/icons/calendar-work.svg';
import CalendarDay from '../../../../assets/icons/calendar-day.svg';
import ArrowLeft from '../../../../assets/icons/arrow-left-1.svg';
import ArrowRight from '../../../../assets/icons/arrow-right-1.svg';
import Save from '../../../../assets/icons/floppyDisk.svg';
import Palm from '../../../../assets/icons/Palm.svg';
import Import from '../../../../assets/icons/import.svg';
import Export from '../../../../assets/icons/export.svg';
import Print from '../../../../assets/icons/print.svg';
import { useStyles } from './styles';
import FiltersModal from './FiltersModal';
import NewEntryModal from './NewEntryModal';
import TimeEntryMoverModal from './TimeEntryMoverModal/TimeEntryMoverModal';
import TimeEntryCopyModal from './TimeEntryCopyModal/TimeEntryCopyModal';
import {
	addDay,
	removeDay,
	addWeeks,
	removeWeeks,
	addDaysToDate,
	decimalToHours,
	dateDifferenceHelper,
	dateDifferenceHelperInDays,
	getPossibleMinutes,
	calculateEndTime,
	calculateDiferenceHoursMinutes,
	areDatesInSameWeek,
	formatStringPTToDate,
	checkForOverlaps,
	convertTimeToDecimal,
	refreshPage
} from './utils';
import { setMoveTimeEntryMessage } from 'store/personalInfo/actions';
import { startOfWeek, endOfWeek } from 'date-fns';
import { getRandomInt } from 'pages/PersonalInfo/Bookings/utils';
import ViewDetailsModal from './ViewDetailsModal';
import { ITitle } from 'components/App/TitleProvider';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { Input as CoreInput } from '@mui/material';
import { Prompt } from 'react-router';
import Joyride, { STATUS } from 'react-joyride';
import { stringLong } from 'lib/utils/formUtils';
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { XTRACKER_ABSENCE_PROJECTS, XTRACKER_EXTERNAL_TIMEOFF_TASKS } from './utils';
import { setDefaultOptions } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { getLSField } from 'lib/utils/cookies';

setDefaultOptions({ locale: enGB });

//* COMPONENT INTERFACES
interface ITimeEntry {
	start: string;
	end: string;
	state: number;
	title: string;
	projectTaskName: string;
	projectTaskCode: string;
	projectName: string;
	projectCode: string;
	id: number;
	costCenterName: string;
	workTypeName: string;
	totalTime: string;
	description: string;
	accountEmployeeTimeEntryId: number;
	submitted: boolean;
	approved: boolean;
	rejected: boolean;
	accountProjectTaskId: number;
	accountProjectId: number;
	accountCostCenterId: number;
	accountWorkTypeId: number;
	accountEmployeeId: number;
	timeEntryDate: string;
	billingRate: number;
	teamLeadApproved: boolean;
	projectManagerApproved: boolean;
	administratorApproved: boolean;
	createdOn: string;
	modifiedOn: string;
	employeeRate: number;
	accountBaseCurrencyId: number;
	accountEmployeeTimeEntryPeriodId: string;
	billed: boolean;
	createdByEmployeeId: number;
	modifiedByEmployeeId: number;
	isTimeOff: boolean;
	hours: any;
	minutes: any;
	isTimeOffConsumed: boolean;
	percentage: number;
	isFixedBid: boolean;
	timeOffTypeId: string;
	timeOffRequestId: number;
	accountParty: number;
	accountEmployeeTimeEntryApprovalProjectId: string;
	projectApproved: boolean;
	isCopiedTimeEntry: boolean;
}
interface IMyTimesheets extends MyTimesheetsProps {
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	title: ITitle;
	guides: any;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}
//* COMPONENT INTERFACES
interface IEmployeeSelect {
	id: string;
	label: string;
}
interface ITimeEntrySave {
	id: number;
	date: string;
	description: string;
	time: string;
}
interface ITimeSheetSave {
	percentage: number;
	projTask: number;
	projectTaskName: string;
	costCenter: number;
	workType: number;
	accountProject: number;
	isTimeOff: boolean;
	isExternalTimeOff: boolean;
	timeOffTypeId: string;
	timeOffRequestId: number;
	accountParty: number;
	approved: boolean;
	rejected: boolean;
	accountEmployeeTimeEntryApprovalProjectId: string;
	projectApproved: boolean;
	timeEntries: ITimeEntrySave[];
}
let formData: FormData = new FormData();
let formDataAttachment: FormData = new FormData();

const MyTimesheets: FC<IMyTimesheets> = ({
	loggedUser,
	personalInfo,
	filters,
	guides,
	title,
	setTitle,
	// Functions
	getPeriodListFiltersData,
	getWeekViewData,
	getWeekViewDataForCopy,
	getDayViewData,
	printTimesheet,
	moveTimeEntry,
	copySave,
	exportTimesheet,
	saveTimesheet,
	attachmentList,
	deleteAttachment,
	downloadAttachment,
	addAttach,
	getGuideData,
	getUserGuideData,
	setUserGuideAsRead,
	setDeleteMoveTimeEntryMessage,
	downloadImportTemplate,
	setClearCopyMessage,
	setNotificationAsRead,
	setMyTimesheetsFilter
}) => {
	const classes = useStyles();
	const { myTimesheetsTypeViewFilter } = filters;
	const dispatch = useDispatch();
	// Main scheduler ref
	const schedulerRef = useRef();
	// DatePicker ref
	const datePickerRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);

	// Chart info
	const [chartCardInfo, setChartCardInfo] = useState();
	// Current calendar view and toggle
	const [currentToggle, setCurrentToggle] = useState('weekly');
	const [currentView, setCurrentView] = useState('workWeek');
	const [openCalendarHeader, setOpenCalendarHeader] = useState(false);
	// Current selected date
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [previousSelectedDate, setPreviousSelectedDate] = useState(selectedDate);
	const [changeWeekInDayView, setChangeWeekInDayView] = useState(false);
	// Current selected date for Copy
	const [selectedDateForCopy, setSelectedDateForCopy] = useState(new Date());
	// Events Data
	const {
		dayViewData,
		weekViewData,
		weekViewDataForCopy,
		copySaveMessage,
		loading,
		isMoved,
		saveTimesheetMessage,
		attachList,
		error
	} = personalInfo;

	const { guide, userGuide } = guides;

	// Events List
	const [mergedArray, setMergedArray] = useState<ITimeEntry[]>([]);
	// Sums for daily columns and total week hours/minutes
	const [dailySums, setDailySums] = useState({
		Sun: 0,
		Mon: 0,
		Tue: 0,
		Wed: 0,
		Thu: 0,
		Fri: 0,
		Sat: 0
	});

	// Auxiliary Sums for daily columns that helps to position the events in case of having time entries with less than 30 minutws
	const [dailySumsWithRounded30mins, setDailySumsWithRounded30mins] = useState({
		Sun: 0,
		Mon: 0,
		Tue: 0,
		Wed: 0,
		Thu: 0,
		Fri: 0,
		Sat: 0
	});

	const [typeView, setTypeView] = useState(myTimesheetsTypeViewFilter);

	useEffect(() => {
		if (myTimesheetsTypeViewFilter) {
			setCurrentView(myTimesheetsTypeViewFilter);
		}
	}, []);

	const [view, setView] = useState<any>({
		schedule: {
			type: typeView == 'workWeek' ? 'week' : typeView,
			allDay: false,
			startDay: 1,
			endDay: typeView == 'workWeek' ? 5 : 0,
			startTime: '00:00',
			endTime: '24:00',
			timeCellStep: 30,
			timeLabelStep: 30,
			currentTimeIndicator: false
		}
	});
	const [weekSum, setWeekSum] = useState(0);
	momentTimezone.moment = moment;
	const [hasChanged, setHasChanged] = useState(true);
	const [tempEvent, setTempEvent] = React.useState(null);
	// Related to add/edit events.
	const [isOpen, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
	const [anchor, setAnchor] = React.useState(null);

	// Filter Related
	const [openModalFilters, setOpenModalFilters] = useState(false);
	const [periodStartDate, setPeriodStartDate] = useState(''); // if periodStartDate is empty, will load current week
	const [periodEndDate, setPeriodEndDate] = useState(''); // if periodEndDate is empty, will load current week
	const [employeesList, setEmployeesList] = useState<IEmployeeSelect[]>([]);
	const [filterUser, setFilterUser] = useState<string>(loggedUser.username);
	const [filterUserFullName, setFilterUserFullName] = useState<string>();
	const [filterStartDate, setFilterStartDate] = useState('');
	const [filterEndDate, setFilterEndDate] = useState('');
	const [isDifferentUserOpened, setDifferentUserOpened] = useState(false);

	// Copy Timesheet Related
	const [openCopyTimesheetModal, setOpenCopyTimesheetModal] = useState(false);

	// new event popup data

	const [popupEventID, setEventID] = React.useState();
	const [popupEventTitle, setEventTitle] = React.useState('');
	const [popupEventDescription, setEventDescription] = React.useState('');
	const [popupEventDate, setEventDate] = React.useState([]);
	const [mySelectedDateTODO, setSelectedDateTODO] = React.useState(new Date());

	//Right Side Bar
	const [isRightBarOpen, setIsRightBarOpen] = useState(true);

	//ToolTip
	const [isOpenToolTip, setIsOpenToolTip] = useState(false);
	const [statusToolTip, setStatusToolTip] = useState('');
	const [projectToolTip, setProjectToolTip] = useState('');
	const [projectTaskToolTip, setProjectTaskToolTip] = useState('');
	const [costCenterName, setCostCenterName] = useState('');
	const [hoursToolTip, setHoursToolTip] = useState('');
	// Define a state variable to track whether the mouse is over the icon button
	const [isIconButtonHovered, setIsIconButtonHovered] = useState(false);

	// Tooltip for Description period
	// Related to Period Description
	const [anchorDescriptionPeriod, setAnchorDescriptionPeriod] = React.useState(null);
	const [isOpenToolTipDescriptionPeriod, setIsOpenToolTipDescriptionPeriod] = useState(false);
	const anchorDescriptionPeriodRef = useRef(null);

	// Copy / Move
	const [anchorElEventAction, setAnchorElEventAction] = useState<null | HTMLElement>(null);
	const [anchorElHeaderAction, setAnchorElHeaderAction] = useState<null | HTMLElement>(null);
	const [openModalTimeEntryMover, setOpenModalTimeEntryMover] = useState(false);
	const [openModalTimeEntryCopy, setOpenModalTimeEntryCopy] = useState(false);
	const [eventInformation, setEventInformation] = useState(null);
	const [dateToMove, setDateToMove] = useState();
	const [selectedDatesOnCopy, setSelectedDatesOnCopy] = useState([]);
	const [dateToMoveAfterCopy, setDateToMoveAfterCopy] = useState<Date | number>(new Date());

	const [originalStartWeekDay, setOriginalStartWeekDay] = useState<Date | number>(new Date());
	const [originalEndWeekDay, setOriginalEndWeekDay] = useState<Date | number>(new Date());

	const [loadAfterMove, setLoadAfterMove] = useState(false);
	const [isToMove, setIsToMove] = useState(false);

	//Attachment modal
	// const attachList = useSelector(state => state.personalInfo.attachmentList);
	const [openAttachModal, setOpenAttachModal] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [inputValue, setInputValue] = useState('');

	// Import
	const [isLoadForImport, setIsLoadForImport] = useState(false);
	const [isImport, setIsImport] = useState(false);

	// Approval Details
	const [openApprovalDetailsModal, setOpenApprovalDetailsModal] = useState(false);

	//View Details Events
	const [isViewDetails, setIsViewDetails] = useState(false);
	const [subtitles, setSubtitles] = useState([
		{
			color: '#FAC15F',
			text: 'Not Submitted'
		},
		{
			color: '#2BAAFF',
			text: 'Submitted'
		},
		{
			color: '#21BA45',
			text: 'Approved'
		},
		{
			color: '#D1345E',
			text: 'Rejected'
		}
	]);
	//Delete Entry Related
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [needsCalendarToUpdateHours, setNeedsCalendarToUpdateHours] = useState(false);

	//Import Timesheet Related
	const [modalImportOpen, setModalImportOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState<any>(null);

	// Edit Period Description
	const [modalPeriodDescription, setModalPeriodDescription] = useState(false);

	// Show Period Description
	const [showPeriodDescription, setShowPeriodDescription] = useState(false);

	// Period Description Value
	const [periodDescriptionValue, setPeriodDescriptionValue] = useState('');

	//Submit Timesheet Related
	const [submitTimesheetModal, setSubmitTimesheetModal] = useState(false);
	//Submit Unsubmit Timesheet Related
	const [saveSubmitUnSubmitTimesheetModal, setSaveSubmitUnSubmitTimesheetModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(true);
	const [isSave, setIsSave] = useState(true);

	const [xtrackerRole, setXtrackerRole] = useState();

	// Drag Event Related
	const [updateWithDrag, setHasUpdateWithDrag] = useState(false);
	const [eventUpdatedWithDrag, setEventUpdatedWithDrag] = useState({});

	// Save Timesheet Warning Related
	const [areChangesMade, setChangesMade] = useState(false);
	const [changePageAction, setChangePageAction] = useState(' ');
	const [showSaveWarningModal, setShowSaveWarningModal] = useState(false);
	const [cardInfoForSaveWarning, setCardInfoForSaveWarning] = useState({});
	const [selectedDateForSaveWarning, setSelectedDateForSaveWarning] = useState(new Date());
	const [updateTimesheetTodayDatePickerForSaveWarning, setUpdateTimesheetTodayDatePickerForSaveWarning] =
		useState<any>(null);
	const [loadCurrentPeriodDateForSaveWarning, setLoadCurrentPeriodDateForSaveWarning] = useState<any>(null);
	const [weekSumLessThan40, setWeekSumLessThan40] = useState(false);
	const userInfo = (getLSField('userInfo') && JSON.parse(getLSField('userInfo'))) || null;
	//Used to see if the right side bar is closed and there is missing timesheets
	const [rightBarClosedWithMissingTimesheets, setRightBarClosedWithMissingTimesheets] = useState(false); // default is 1300 tablet
	const [approvalDetailsData, setApprovalDetailsData] = useState();
	const [saveWarningView, setSaveWarningView] = useState();

	const { width, height } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 1500, [width]); // default is 1300 tablet

	const notificationPayload = {
		area: 'My Time Tracking',
		section: 'Timesheets'
	};

	useEffect(() => {
		if (
			weekViewData?.results?.missingTimesheetsDays?.NotSaved === 0 &&
			weekViewData?.results?.missingTimesheetsDays?.Saved === 0 &&
			weekViewData?.results?.missingTimesheetsDays?.Rejected === 0
		) {
			setNotificationAsRead(loggedUser.username, notificationPayload);
			setIsRightBarOpen(false);
		}

		const approvalDetails = weekViewData?.results?.vueAccountEmployeeTimeEntryPeriodApprovalDetailWithProject;
		if (approvalDetails) {
			const uniqueApprovalDetails = approvalDetails.filter(
				(detail, index, self) =>
					index ===
					self.findIndex(
						t =>
							t.approvedOn === detail.approvedOn &&
							t.comments === detail.comments &&
							t.projects === detail.projects &&
							t.approverName === detail.approverName &&
							t.status === detail.status
					)
			);

			setApprovalDetailsData(uniqueApprovalDetails);
		}
	}, [weekViewData]);

	//Show notification on right side bar when its closed if there is missing timesheets
	useEffect(() => {
		if (weekViewData) {
			if (
				weekViewData?.results?.missingTimesheetsDays?.NotSaved === 0 &&
				weekViewData?.results?.missingTimesheetsDays?.Saved === 0 &&
				weekViewData?.results?.missingTimesheetsDays?.Rejected === 0
			) {
				setRightBarClosedWithMissingTimesheets(false);
			} else {
				setRightBarClosedWithMissingTimesheets(true);
			}
		}
	}, [isRightBarOpen, weekViewData]);

	useEffect(() => {
		if (!guide) {
			getGuideData('MyTimesheets');
		}
	}, []);

	useEffect(() => {
		getUserGuideData(loggedUser.username, 'MyTimesheets');
	}, []);

	const [{ run, steps }, setState] = useState({
		run: true,
		steps: []
	});

	const handleJoyrideCallback = data => {
		const { action, index, type } = data;

		if (action === 'reset') {
			// Joyride finished
			setUserGuideAsRead(loggedUser.username, 'MyTimesheets');
		}
	};

	useEffect(() => {
		if (guide && userGuide?.data?.userGuide?.active) {
			setState(prevState => ({
				...prevState,
				steps:
					guide?.data?.guide?.guideSteps?.map((step, index) => ({
						content: <div dangerouslySetInnerHTML={{ __html: step.content }} />,
						placement: step.placement,
						target: step.target,
						title: step.title,
						disableScrolling: step.disableScrolling,
						spotlightClicks: step.spotlightClicks,
						key: index
					})) || []
			}));
		}
	}, [guide, userGuide]);

	//Gets the necessary data from the file and loads the data from that week
	const onSubmitImportTimesheet = event => {
		event.preventDefault();
		const file = selectedFile;
		const reader = new FileReader();
		reader.onload = async event => {
			const fileContent = event!.target!.result;
			const lines = fileContent!.split('\n');
			const parsedData = lines.map(line => line.split(',')); // Gets the data from the row by ,
			//Filters the necessary info
			parsedData.map(e => {
				e[0] = e[0].replace(/"/g, '');
			});

			const eventsInsideDocument = parsedData
				.sort((a, b) => (formatStringPTToDate(a[0]) > formatStringPTToDate(b[0]) ? 1 : -1))
				.filter(importedEvent => importedEvent[0].includes('/'))
				.filter(importedEvent => !isNaN(Date.parse(formatStringPTToDate(importedEvent[0]).toISOString())));

			//Gets the first day of the list inside of the document
			const [date, time] = eventsInsideDocument[0][0].split(' ');
			const [day, month, year] = date.split('/');
			const firstDayOfWeek = new Date(year, month - 1, day);

			//Goes to the week of the import, sets the view to week (easier to load all data), loads the data from that week
			setSelectedDate(firstDayOfWeek);
			setCurrentView('workWeek');
			loadCurrentPeriodDate(firstDayOfWeek.toISOString());
			setIsImport(true);
		};

		reader.readAsText(file);
		setModalImportOpen(false);
		//setSelectedFile(null);
	};

	//Put the default hour as 00:00
	useEffect(() => {
		selectedDate.setHours(0, 0, 0, 0);
	}, []);

	// Period Description Hover Logic
	const handleMouseEnter = event => {
		setIsOpenToolTipDescriptionPeriod(true);
		setAnchorDescriptionPeriod(event.currentTarget);
	};

	const handleMouseLeave = () => {
		setIsOpenToolTipDescriptionPeriod(false);
		setAnchorDescriptionPeriod(null);
	};

	// Adjust entries after drag
	useEffect(() => {
		if (updateWithDrag) {
			// Helps to update dailySumsWithRounded30mins value
			const updatedDailySumsAux = {
				Sun: 0,
				Mon: 0,
				Tue: 0,
				Wed: 0,
				Thu: 0,
				Fri: 0,
				Sat: 0
			};
			schedulerRef?.current?._events.map(event => {
				if (eventUpdatedWithDrag?.id == event.id) {
					const diffHours = calculateDiferenceHoursMinutes(
						new Date(eventUpdatedWithDrag.start),
						new Date(eventUpdatedWithDrag.end)
					);

					const dateString = new Date(eventUpdatedWithDrag.start).toISOString().split('T')[0];
					event.totalTime =
						dateString +
						' ' +
						diffHours.hours.toString().padStart(2, '0') +
						':' +
						diffHours.minutes.toString().padStart(2, '0') +
						':' +
						'00';
					event.timeEntryDate = dateString;
					event.hours = diffHours.hours + diffHours.minutes / 60;
					const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
						updatedDailySumsAux,
						new Date(eventUpdatedWithDrag.start).toISOString(),
						new Date(eventUpdatedWithDrag.end).toISOString(),
						event.totalTime
					);
					event.start = adjustedDate.start;
					event.end = adjustedDate.end;
				} else {
					const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
						updatedDailySumsAux,
						event.start,
						event.end,
						format(new Date(event.totalTime), 'yyyy-MM-dd HH:mm:ss')
					);
					event.start = adjustedDate.start;
					event.end = adjustedDate.end;
				}
			});
			setDailySumsWithRounded30mins(updatedDailySumsAux);
			setMergedArray(schedulerRef?.current?._events);
			setHasUpdateWithDrag(false);
			setEventUpdatedWithDrag({});
		}
	}, [updateWithDrag]);

	/**
	 * Set the title page based on if the opened timesheet belongs to another employee.
	 */
	useEffect(() => {
		const newTitleString = isDifferentUserOpened
			? `${weekViewData?.results?.viewAccountEmployees?.employeeName}'s timesheet`
			: 'Timesheets';

		// Update the title only if there's a change
		newTitleString !== title.title &&
			weekViewData?.results?.viewAccountEmployees?.employeeName &&
			setTitle({
				...title,
				title: newTitleString,
				optionalAvatar: isDifferentUserOpened ? getPhoto() : '',
				onClose: () => {
					isDifferentUserOpened && window.location.reload();
				}
			});
	}, [weekViewData, title, isDifferentUserOpened]);

	useEffect(() => {
		if (isLoadForImport && isImport) {
			const file = selectedFile;
			const reader = new FileReader();
			reader.onload = async event => {
				let fileContent = event!.target!.result;
				let lines = fileContent!.split('\n');
				//CSV files comes with extra "", in order fix unecessary errors its necessary to remove extra ""
				lines = lines.map(e => {
					e = e.replace(/""/g, '"');
					if (e[0] === '"') {
						e = e!.substring(1, e.length - 2);
						return e;
					}
					return e;
				});
				//Divides lines by comma unlease the comma is inside ""
				let parsedData = lines.map(line => line.match(/(?:[^,\s]+(?:\s+[^,\s]+)*(?:"[^"]*")?)+/g));

				parsedData = parsedData.filter(item => item !== null);
				// Trim surrounding double quotes
				parsedData.map(e => {
					e.map(item => {
						item = item.replace(/"/g, '');
						return item;
					});
					return e;
				});

				//Create event with the information obtained in the file
				let previousEndHour = '';

				//------------------------Check if it has errors inside the document------------------------//
				parsedData
					.sort((a, b) => (formatStringPTToDate(a[0]) > formatStringPTToDate(b[0]) ? 1 : -1))
					.map(e => {
						if (e[0] != 'TimeEntryDate') {
							if (e[0] == '' || !e[0].includes('/')) {
								addNotification('danger', 'Date format is incorrect! The correct format is dd/mm/yyyy');
								throw new Error('Date format is incorrect! The correct format is dd/mm/yyyy');
							}
							if (e[0].includes('/')) {
								const [day, month, year] = e[0].split('/');
								if (day < 1 || day > 31 || month < 1 || month > 12) {
									addNotification(
										'danger',
										'Date format is incorrect! The correct format is dd/mm/yyyy'
									);
									throw new Error('Date format is incorrect! The correct format is dd/mm/yyyy');
								}
							}
						}
						if (e[6] != 'TotalTime') {
							if (e[6] == '' || !e[6].includes(':')) {
								addNotification(
									'danger',
									'Total Time format is incorrect! The correct format is HH:mm:ss'
								);
								throw new Error('Total Time format is incorrect! The correct format is HH:mm:ss');
							}
						}
					});
				const eventsInsideDocument = parsedData
					.sort((a, b) => (formatStringPTToDate(a[0]) > formatStringPTToDate(b[0]) ? 1 : -1))
					.filter(importedEvent => importedEvent[0].includes('/'))
					.filter(importedEvent => !isNaN(Date.parse(formatStringPTToDate(importedEvent[0]).toISOString())));
				//Gets the first day of the list inside of the document
				const [date, time] = eventsInsideDocument[0][0].split(' ');
				const [day, month, year] = date.split('/');
				const firstDayOfWeek = new Date(year, month - 1, day);

				//Gets the last day of the list inside of the document
				const [dateFinalDate, timeFinalDate] =
					eventsInsideDocument[eventsInsideDocument.length - 1][0].split(' ');
				const [dayFinalDate, monthFinalDate, yearFinalDate] = dateFinalDate.split('/');
				const finalDate = new Date(yearFinalDate, monthFinalDate - 1, dayFinalDate);

				//Since the array is organized by date (older to newer) we can check if all events are from the same week, if not shows error
				if (!areDatesInSameWeek(firstDayOfWeek, finalDate)) {
					addNotification('danger', 'All entries must be from the same week!');
					return;
				}

				//Maps the events of the document and confirms if all fields are correct, if not shows error
				eventsInsideDocument.map(event => {
					//If first fields (Date of the event) has not the correct form, shows error
					if (!event[0].includes('/')) {
						addNotification('danger', 'Date format is incorrect! The correct format is dd/mm/yyyy');
					}
					const costCenter = weekViewData?.results?.costCenters?.find(
						e => e.accountCostCenterName == event[9]
					);
					const workType = weekViewData?.results?.workTypes?.find(e => e.accountWorkTypeName == event[5]);
					const assignedProjects = weekViewData?.results?.assignedAccountProjects;
					const projectName = assignedProjects?.find(e => e.projectName == event[3]);
					const timeOffTypes = weekViewData?.results?.accountTimeOffType;
					const timeOffTypeName = timeOffTypes?.find(e => e.accountTimeOffType == event[3]);

					//If the cost center is wrong it will not find it in all costCenters available, so, it will show error
					if (costCenter == null) {
						addNotification('danger', 'AccountCostCenter is incorrect, please insert a valid Cost Center!');
					}
					//If the work type is wrong it will not find it in all work types available, so, it will show error
					if (workType == null) {
						addNotification('danger', 'AccountWorkType is incorrect, please insert a valid Work Type!');
					}
					if (workType == null) {
						addNotification('danger', 'AccountWorkType is incorrect, please insert a valid Work Type!');
					}

					//If the project name is wrong it will not find it in all projects available, so, it will show error
					if (projectName == null && timeOffTypeName == null) {
						addNotification('danger', 'ProjectName is incorrect, please insert a valid Project Name!');
					}
					const assignedProjectsTask = weekViewData?.results?.assignedProjectTasks;
					let projectTaskExists = false;
					for (let projectTask of assignedProjectsTask) {
						for (let task of projectTask) {
							if (task.taskName == event[4] && task.accountProjectId == projectName.accountProjectId) {
								projectTaskExists = true;
							}
						}
					}
					if (!projectTaskExists) {
						addNotification('danger', 'Project Task is incorrect, please insert a valid Project Task!');
						throw new Error('Project Task is incorrect, please insert a valid Project Task!');
					}
				});
				//Shows no error proceeds to create events and populate the main array
				//-------------------Updates events with the data of the document-------------------//
				if (
					(weekViewData?.results?.accountEmployeeTimeEntryPeriod &&
						(weekViewData?.results?.accountEmployeeTimeEntryPeriod.submitted ||
							weekViewData?.results?.accountEmployeeTimeEntryPeriod.approved)) ||
					weekViewData?.results?.listTimeEntriesByPeriodId?.some(entry => entry.approved === true) ||
					weekViewData?.results?.listTimeEntriesByPeriodId?.some(entry => entry.submitted === true)
				) {
					addNotification('danger', 'This timesheet is already submitted/approved!');
				} else {
					setMergedArray(
						eventsInsideDocument.map(importedEvent => {
							if (!isNaN(Date.parse(formatStringPTToDate(importedEvent[0]).toISOString()))) {
								const [date, time] = importedEvent[0].split(' ');
								const [day, month, year] = date.split('/');
								const [hours, minutes] = importedEvent[6].split(':').map(Number);

								const startDate = new Date(year, month - 1, day);
								const endDate = calculateEndTime(startDate, hours, minutes);
								const totalTime = new Date(year, month - 1, day);
								totalTime.setHours(hours, minutes);
								let state = 1;

								importedEvent[8] = importedEvent[8].replace(/"/g, '');
								const costCenter = weekViewData?.results?.costCenters?.find(
									e => e.accountCostCenterName == importedEvent[9]
								);
								const workType = weekViewData?.results?.workTypes?.find(
									e => e.accountWorkTypeName == importedEvent[5]
								);
								const assignedProjects = weekViewData?.results?.assignedAccountProjects;
								const favoriteAssignedProjects = weekViewData?.results?.favoriteAssignedAccountProjects;
								const timeOffTypes = weekViewData?.results?.accountTimeOffType;
								const projectName = assignedProjects?.find(e => e.projectName == importedEvent[3]);
								const timeOffTypeName = timeOffTypes?.find(
									e => e.accountTimeOffType == importedEvent[3]
								);
								const assignedProjectsTask = weekViewData?.results?.assignedProjectTasks;

								const eventProjectTaskName = importedEvent[4];

								let projectTaskName: string = '';
								let projectTaskId;
								for (let projectTask of assignedProjectsTask) {
									for (let task of projectTask) {
										if (
											task.taskName == eventProjectTaskName &&
											task.accountProjectId == projectName.accountProjectId
										) {
											projectTaskName = task.taskName;
											projectTaskId = task.id;
											break;
										}
									}
								}
								if (projectTaskName == '') {
									projectTaskName = timeOffTypeName?.accountTimeOffType;
								}

								//Get the previous event hours
								const previousEndHourToInsert = previousEndHour;
								const endDateOfPreviousEvent = new Date(previousEndHourToInsert);
								previousEndHour = endDate.toISOString();

								//If the previous event date is the same as the current one it will check if has the same total hours and return the event
								if (
									endDate.getDate() == endDateOfPreviousEvent.getDate() &&
									endDate.getMonth() == endDateOfPreviousEvent.getMonth() &&
									endDate.getFullYear() == endDateOfPreviousEvent.getFullYear()
								) {
									//If the current event end the preivous one has the same hours, adds the previous date hours to the current one
									endDate.setHours(
										hours + endDateOfPreviousEvent.getHours(),
										minutes + endDateOfPreviousEvent.getMinutes(),
										0
									);
									previousEndHour = format(endDate, 'yyyy-MM-dd HH:mm:ss');
									const id = `${getRandomInt()}`;
									return {
										start:
											previousEndHourToInsert == ''
												? format(startDate, 'yyyy-MM-dd HH:mm:ss')
												: previousEndHourToInsert,
										end: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
										state,
										title: projectName?.projectName,
										projectTaskName,
										projectName: projectName?.projectName,
										id,
										costCenterName: costCenter?.accountCostCenterName,
										workTypeName: workType?.accountWorkTypeName,
										totalTime: totalTime,
										description: importedEvent[8],
										submitted: false,
										approved: false,
										rejected: false,
										accountProjectTaskId: projectTaskId,
										accountProjectId: projectName.accountProjectId,
										accountCostCenterId: costCenter.accountCostCenterId,
										accountWorkTypeId: workType.accountWorkTypeId
									};
								} else {
									const id = `${getRandomInt()}`;
									return {
										start: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
										end: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
										state,
										title: projectName?.projectName,
										projectTaskName,
										projectName: projectName?.projectName,
										id,
										costCenterName: costCenter?.accountCostCenterName,
										workTypeName: workType?.accountWorkTypeName,
										totalTime: totalTime,
										description: importedEvent[8],
										submitted: false,
										approved: false,
										rejected: false,
										accountProjectTaskId: projectTaskId,
										accountProjectId: projectName.accountProjectId,
										accountCostCenterId: costCenter.accountCostCenterId,
										accountWorkTypeId: workType.accountWorkTypeId
									};
								}
							}
						})
					);
				}

				setSelectedFile(new FormData());
				formData = new FormData();
				setHasUpdateWithDrag(true);
			};
			reader.readAsText(file);
			setIsImport(false);
			setIsLoadForImport(false);
		}
	}, [isImport, isLoadForImport]);

	//Necessary for import
	useEffect(() => {
		if (weekViewData && isImport) setIsLoadForImport(true);
	}, [weekViewData, isImport]);

	const changeHandlerImportTimesheet = (event: { target: { files: (string | Blob)[] } }) => {
		if (event.target.files.length > 0) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const newTemplateActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setModalImportOpen(false)
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-new-file-template',
			variant: 'contained',
			// disabled:
			// 	selectedFile === null
			// 		? true
			// 		: ((selectedFile as FormData)?.getAll('newDoc').length as number) === 0 ||
			// 		  getLSField('impersonate_userInfo'),
			onClick: onSubmitImportTimesheet
			// ,disabled: isDifferentUserOpened
		}
	];

	const editPeriodDescriptionActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setPeriodDescriptionValue(
					weekViewData?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || ''
				);
				setModalPeriodDescription(false);
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'confirm',
			label: 'Save',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setPeriodDescriptionValue(watch('periodDescription') || '');
				setShowPeriodDescription(true);
				setChangesMade(true);
				setModalPeriodDescription(false);
			}
			// ,disabled: isDifferentUserOpened
		}
	];

	// useEffect to transform the events from database into mobiscroll format(added startdate/endate/status)
	useEffect(() => {
		let previousEndHour = '';

		// Helps to update dailySumsWithRounded30mins value
		const updatedDailySumsAux = {
			Sun: 0,
			Mon: 0,
			Tue: 0,
			Wed: 0,
			Thu: 0,
			Fri: 0,
			Sat: 0
		};

		setXtrackerRole(weekViewData?.results?.viewAccountEmployees?.accountRoleId);

		setMergedArray(
			weekViewData?.results?.listTimeEntriesByPeriodId
				?.sort((a, b) => (a.totalTime > b.totalTime ? 1 : -1))
				?.map(duration => {
					const {
						totalTime,
						description,
						accountEmployeeTimeEntryId,
						submitted,
						approved,
						rejected,
						accountProjectTaskId,
						accountProjectId,
						accountCostCenterId,
						accountWorkTypeId,
						accountTimeOffTypeId,
						...otherProperties
					} = duration;
					const [date, time] = totalTime.split(' ');
					const [hours, minutes] = time.split(':').map(Number);

					const startDate = new Date(date);
					const endDate = calculateEndTime(startDate, hours, minutes);
					let state = 1;
					if (submitted) {
						if (approved) {
							state = 3;
						} else {
							state = 2;
						}
					} else {
						if (rejected) {
							state = 4;
						}
					}
					const costCenter = weekViewData?.results?.costCenters?.find(
						e => e.accountCostCenterId == accountCostCenterId
					);
					const workType = weekViewData?.results?.workTypes?.find(
						e => e.accountWorkTypeId == accountWorkTypeId
					);

					const assignedProjects = weekViewData?.results?.assignedAccountProjects;
					const favoriteAssignedProjects = weekViewData?.results?.favoriteAssignedAccountProjects;
					const timeOffTypes = weekViewData?.results?.accountTimeOffType;
					const eventAccountProjectId = accountProjectId;
					const projectName = assignedProjects?.find(e => e.accountProjectId == eventAccountProjectId);
					const timeOffTypeName = timeOffTypes?.find(e => e.accountTimeOffTypeId == accountTimeOffTypeId);
					const assignedProjectsTask = weekViewData?.results?.assignedProjectTasks;
					const eventProjectTaskId = accountProjectTaskId;
					let projectTaskName: string = '';
					let projectTaskCode: string = '';
					for (let projectTask of assignedProjectsTask) {
						for (let task of projectTask) {
							if (task.id == eventProjectTaskId) {
								projectTaskName = task.taskName;
								projectTaskCode = task.taskCode;
								break;
							}
						}
					}
					if (projectTaskName == '') {
						projectTaskName = timeOffTypeName?.accountTimeOffType;
					}

					//Get the previous event hours
					const previousEndHourToInsert = previousEndHour;
					const endDateOfPreviousEvent = new Date(previousEndHourToInsert);
					previousEndHour = format(endDate, 'yyyy-MM-dd HH:mm:ss');

					//If the previous event date is the same as the current one it will check if has the same total hours and return the event

					if (
						endDate.getDate() == endDateOfPreviousEvent.getDate() &&
						endDate.getMonth() == endDateOfPreviousEvent.getMonth() &&
						endDate.getFullYear() == endDateOfPreviousEvent.getFullYear()
					) {
						//If the current event end the preivous one has the same hours, adds the previous date hours to the current one
						endDate.setHours(
							hours + endDateOfPreviousEvent.getHours(),
							minutes + endDateOfPreviousEvent.getMinutes(),
							0
						);
						previousEndHour = format(endDate, 'yyyy-MM-dd HH:mm:ss');

						const startDateAux =
							previousEndHourToInsert == ''
								? format(startDate, 'yyyy-MM-dd HH:mm:ss')
								: previousEndHourToInsert;

						//	Adjusts position of event in case of having time entries with less than 30 minutws in the same day
						const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
							updatedDailySumsAux,
							startDateAux,
							format(endDate, 'yyyy-MM-dd HH:mm:ss'),
							totalTime
						);

						return {
							start: adjustedDate.start,
							end: adjustedDate.end,
							state,
							title: projectName?.projectName,
							projectTaskName,
							projectTaskCode,
							projectName: projectName?.projectName,
							projectCode: projectName?.projectCode,
							id: accountEmployeeTimeEntryId,
							costCenterName: costCenter?.accountCostCenterName,
							workTypeName: workType?.accountWorkTypeName,
							totalTime,
							description,
							accountEmployeeTimeEntryId,
							submitted,
							approved,
							rejected,
							accountProjectTaskId,
							accountProjectId,
							accountCostCenterId,
							accountWorkTypeId,
							...otherProperties
						};
					} else {
						//	Adjusts position of event in case of having time entries with less than 30 minutws in the same day
						const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
							updatedDailySumsAux,
							format(startDate, 'yyyy-MM-dd HH:mm:ss'),
							format(endDate, 'yyyy-MM-dd HH:mm:ss'),
							totalTime
						);

						return {
							start: adjustedDate.start,
							end: adjustedDate.end,
							state,
							title: projectName?.projectName,
							projectTaskName,
							projectTaskCode,
							projectName: projectName?.projectName,
							projectCode: projectName?.projectCode,
							id: accountEmployeeTimeEntryId,
							costCenterName: costCenter?.accountCostCenterName,
							workTypeName: workType?.accountWorkTypeName,
							totalTime,
							description,
							accountEmployeeTimeEntryId,
							submitted,
							approved,
							rejected,
							accountProjectTaskId,
							accountProjectId,
							accountCostCenterId,
							accountWorkTypeId,
							...otherProperties
						};
					}
				}) || [] // provide a default empty array if listTimeEntriesByPeriodId is undefined
		);
		if (
			weekViewData?.results?.accountEmployeeTimeEntryPeriod !== '' &&
			weekViewData?.results?.accountEmployeeTimeEntryPeriod !== undefined
		) {
			setPeriodDescriptionValue(weekViewData?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || '');
			setValue(
				'periodDescription',
				weekViewData?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || ''
			);
			setShowPeriodDescription(true);
		}
		setLoadAfterMove(false);
	}, [weekViewData, view, dayViewData]);

	// useEffect to transform the events copy timesheets button from database into mobiscroll format(added startdate/endate/status)
	useEffect(() => {
		let previousEndHour = '';

		// Helps to update dailySumsWithRounded30mins value
		const updatedDailySumsAux = {
			Sun: 0,
			Mon: 0,
			Tue: 0,
			Wed: 0,
			Thu: 0,
			Fri: 0,
			Sat: 0
		};

		setXtrackerRole(weekViewDataForCopy?.results?.viewAccountEmployees?.accountRoleId);

		const startWeekDay = startOfWeek(selectedDate, {
			weekStartsOn: 1
		}); // 1 represents monday

		const daysDiff = differenceInDays(startWeekDay, new Date(weekViewDataForCopy?.results?.periodStartDate));
		setMergedArray(
			weekViewDataForCopy?.results?.listTimeEntriesByPeriodId
				?.sort((a, b) => (a.totalTime > b.totalTime ? 1 : -1))
				?.map((duration, index) => {
					const {
						totalTime,
						description,
						accountProjectTaskId,
						accountProjectId,
						accountCostCenterId,
						accountWorkTypeId,
						accountTimeOffTypeId,
						accountEmployeeTimeEntryId,
						approved,
						submitted,
						rejected,
						...otherProperties
					} = duration;

					if (XTRACKER_ABSENCE_PROJECTS.includes(accountProjectId)) {
						return null; // Skip this entry by returning null
					}

					const [date, time] = totalTime.split(' ');
					const [hours, minutes] = time.split(':').map(Number);
					const startDate = new Date(date);
					const endDate = calculateEndTime(startDate, hours, minutes);

					let state = 1;
					const costCenter = weekViewDataForCopy?.results?.costCenters?.find(
						e => e.accountCostCenterId == accountCostCenterId
					);
					const workType = weekViewDataForCopy?.results?.workTypes?.find(
						e => e.accountWorkTypeId == accountWorkTypeId
					);

					const assignedProjects = weekViewDataForCopy?.results?.assignedAccountProjects;
					const favoriteAssignedProjects = weekViewDataForCopy?.results?.favoriteAssignedAccountProjects;
					const timeOffTypes = weekViewDataForCopy?.results?.accountTimeOffType;
					const eventAccountProjectId = accountProjectId;
					const projectName = assignedProjects?.find(e => e.accountProjectId == eventAccountProjectId);
					const timeOffTypeName = timeOffTypes?.find(e => e.accountTimeOffTypeId == accountTimeOffTypeId);
					const assignedProjectsTask = weekViewDataForCopy?.results?.assignedProjectTasks;
					const eventProjectTaskId = accountProjectTaskId;
					let projectTaskName: string = '';
					let projectTaskCode: string = '';
					for (let projectTask of assignedProjectsTask) {
						for (let task of projectTask) {
							if (task.id == eventProjectTaskId) {
								projectTaskName = task.taskName;
								projectTaskCode = task.taskCode;
								break;
							}
						}
					}
					if (projectTaskName == '') {
						projectTaskName = timeOffTypeName?.accountTimeOffType;
					}

					//Get the previous event hours
					const previousEndHourToInsert = previousEndHour;
					const endDateOfPreviousEvent = new Date(previousEndHourToInsert);
					previousEndHour = format(endDate, 'yyyy-MM-dd HH:mm:ss');

					//If the previous event date is the same as the current one it will check if has the same total hours and return the event

					if (
						endDate.getDate() == endDateOfPreviousEvent.getDate() &&
						endDate.getMonth() == endDateOfPreviousEvent.getMonth() &&
						endDate.getFullYear() == endDateOfPreviousEvent.getFullYear()
					) {
						//If the current event end the preivous one has the same hours, adds the previous date hours to the current one
						endDate.setHours(
							hours + endDateOfPreviousEvent.getHours(),
							minutes + endDateOfPreviousEvent.getMinutes(),
							0
						);
						previousEndHour = format(endDate, 'yyyy-MM-dd HH:mm:ss');

						const startDateAux =
							previousEndHourToInsert == ''
								? format(startDate, 'yyyy-MM-dd HH:mm:ss')
								: previousEndHourToInsert;

						//	Adjusts position of event in case of having time entries with less than 30 minutws in the same day
						const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
							updatedDailySumsAux,
							startDateAux,
							format(endDate, 'yyyy-MM-dd HH:mm:ss'),
							totalTime
						);

						const copyAdjustedStartDate = addDaysToDate(adjustedDate.start, daysDiff);
						const copyAdjustedEndDate = addDaysToDate(adjustedDate.end, daysDiff);

						return {
							start: copyAdjustedStartDate,
							end: copyAdjustedEndDate,
							state,
							title: projectName?.projectName,
							projectTaskName,
							projectTaskCode,
							projectName: projectName?.projectName,
							projectCode: projectName?.projectCode,
							costCenterName: costCenter?.accountCostCenterName,
							workTypeName: workType?.accountWorkTypeName,
							totalTime,
							description,
							submitted: false,
							accountEmployeeTimeEntryId: 0,
							approved: false,
							rejected: false,
							accountProjectTaskId,
							accountProjectId,
							accountCostCenterId,
							accountWorkTypeId,
							...otherProperties
						};
					} else {
						//	Adjusts position of event in case of having time entries with less than 30 minutws in the same day
						const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
							updatedDailySumsAux,
							format(startDate, 'yyyy-MM-dd HH:mm:ss'),
							format(endDate, 'yyyy-MM-dd HH:mm:ss'),
							totalTime
						);
						const copyAdjustedStartDate = addDaysToDate(adjustedDate.start, daysDiff);
						const copyAdjustedEndDate = addDaysToDate(adjustedDate.end, daysDiff);

						return {
							start: copyAdjustedStartDate,
							end: copyAdjustedEndDate,
							state,
							title: projectName?.projectName,
							projectTaskName,
							projectTaskCode,
							projectName: projectName?.projectName,
							projectCode: projectName?.projectCode,
							costCenterName: costCenter?.accountCostCenterName,
							workTypeName: workType?.accountWorkTypeName,
							totalTime,
							description,
							accountEmployeeTimeEntryId: 0,
							submitted: false,
							approved: false,
							rejected: false,
							accountProjectTaskId,
							accountProjectId,
							accountCostCenterId,
							accountWorkTypeId,
							...otherProperties
						};
					}
				})
				.filter(entry => entry !== null) || [] // provide a default empty array if listTimeEntriesByPeriodId is undefined
		);

		if (
			weekViewDataForCopy?.results?.accountEmployeeTimeEntryPeriod !== '' &&
			weekViewDataForCopy?.results?.accountEmployeeTimeEntryPeriod !== undefined
		) {
			setPeriodDescriptionValue(
				weekViewDataForCopy?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || ''
			);
			setValue(
				'periodDescription',
				weekViewDataForCopy?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || ''
			);
			setShowPeriodDescription(true);
		}
	}, [weekViewDataForCopy]);

	// Load Page
	useEffect(() => {
		loadPeriodData();
	}, []);

	// Set Employees List in Filters
	useEffect(() => {
		let unrepeatedEmployees: any = [];
		weekViewData?.results?.employees?.forEach(e => {
			if (!unrepeatedEmployees.some(obj => obj.employeeId === e.employeeId)) {
				unrepeatedEmployees = [...unrepeatedEmployees, e];
			}
		});
		if (!isDifferentUserOpened) {
			let employeesOptions: IEmployeeSelect[] = [];
			unrepeatedEmployees.forEach((e: { username: string; firstName: string; lastName: string }) => {
				employeesOptions.push({
					id: e.username,
					label: e.username + ' - ' + e.firstName + ' ' + e.lastName
				});
				if (
					e.username == loggedUser.username.toUpperCase() &&
					loggedUser.username.toUpperCase() == filterUser.toUpperCase()
				) {
					setFilterUser(e.username);
					setFilterUserFullName(e.firstName + ' ' + e.lastName);
				}
			});

			// Sort employeesOptions alphabetically by the label property
			employeesOptions.sort((a, b) => a.label.localeCompare(b.label));

			setEmployeesList(employeesOptions);
		}
	}, [weekViewData]);

	//Updates the total hours when mergedArray and hasChanged variables changes
	useEffect(() => {
		if (mergedArray) {
			//To update the total hours of the week it needs to load the event calendar first, in order to do that, we force the change of the view to the calendar
			if (schedulerRef && schedulerRef.current && schedulerRef.current._events) {
				// Calculate sums based on the existing events
				const updatedDailySums = {
					Sun: 0,
					Mon: 0,
					Tue: 0,
					Wed: 0,
					Thu: 0,
					Fri: 0,
					Sat: 0
				};
				// Helps to update dailySumsWithRounded30mins value
				const updatedDailySumsAux = {
					Sun: 0,
					Mon: 0,
					Tue: 0,
					Wed: 0,
					Thu: 0,
					Fri: 0,
					Sat: 0
				};

				schedulerRef.current._events.forEach(event => {
					const helperHours = dateDifferenceHelper(event.start, event.end);
					const dayOfWeek = new Date(event.start).toLocaleDateString('en-US', { weekday: 'short' });
					const timeDailySum = decimalToHours(updatedDailySumsAux[dayOfWeek]);
					// Get logged hours and minutes from event
					const diffHours = calculateDiferenceHoursMinutes(new Date(event.start), new Date(event.end));

					//	Adjusts event start to the last place of the day (considering entries rounded to 30 min)
					event.start = calculateEndTime(
						new Date(event.start).setHours(0, 0, 0, 0),
						timeDailySum.hours,
						timeDailySum.minutes
					).toISOString();

					// End date = Adds old logged hours and minutes to the new start date
					event.end = calculateEndTime(
						new Date(event.start),
						diffHours.hours,
						diffHours.minutes
					).toISOString();

					//	If event with less than 30 minutes
					if (helperHours < 0.5) {
						updatedDailySums[dayOfWeek] += helperHours;
						updatedDailySumsAux[dayOfWeek] += 0.5;
					} else {
						updatedDailySums[dayOfWeek] += helperHours;
						updatedDailySumsAux[dayOfWeek] += helperHours;
					}
				});

				const updatedWeekSum = Object.values(updatedDailySums).reduce((sum, dailySum) => sum + dailySum, 0);
				setDailySums(updatedDailySums);
				setDailySumsWithRounded30mins(updatedDailySumsAux);
				setWeekSum(updatedWeekSum);
				setHasChanged(false);
			}
			if (currentToggle == 'chart') setWeekSum(chartCardInfo[1]);
		}
	}, [mergedArray, hasChanged, selectedDate, chartCardInfo, currentToggle]);

	//Remove event from the main array of events (used in the options of the event)
	const deleteEvent = React.useCallback(
		(id: any) => {
			setMergedArray(mergedArray.filter(item => item.id.toString() !== id.toString()));
		},
		[mergedArray, popupEventID]
	);

	//Loads week data
	const loadPeriodData = () => {
		const startWeekDay = startOfWeek(new Date(), {
			weekStartsOn: 1
		}); // 1 represents monday
		startWeekDay.setHours(startWeekDay.getHours() + 1);

		// Gets the final day of the week
		const endWeekDay = endOfWeek(new Date(), {
			weekStartsOn: 1
		}); // 1

		getWeekViewData({
			username: filterUser,
			periodStartDate: startWeekDay,
			periodEndDate: endWeekDay
		});

		// Reset changesMade state
		setChangesMade(false);
	};

	const loadCurrentPeriodDate = (selectedDateAux = '') => {
		//  Gets the initial date of the week
		const startWeekDay = startOfWeek(selectedDateAux == '' ? selectedDate : new Date(selectedDateAux), {
			weekStartsOn: 1
		}); // 1 represents monday
		startWeekDay.setHours(startWeekDay.getHours() + 1);

		// Gets the final day of the week
		const endWeekDay = endOfWeek(selectedDateAux == '' ? selectedDate : new Date(selectedDateAux), {
			weekStartsOn: 1
		}); // 1
		getWeekViewData({
			username: filterUser,
			periodStartDate: startWeekDay ?? '',
			periodEndDate: endWeekDay ?? ''
		});

		// Reset changesMade state
		setChangesMade(false);
	};

	const loadTimesheetForCopy = (selectedDateAux = '') => {
		//  Gets the initial date of the week
		const startWeekDay = startOfWeek(selectedDateAux == '' ? selectedDate : new Date(selectedDateAux), {
			weekStartsOn: 1
		}); // 1 represents monday
		startWeekDay.setHours(startWeekDay.getHours() + 1);

		// Gets the final day of the week
		const endWeekDay = endOfWeek(selectedDateAux == '' ? selectedDate : new Date(selectedDateAux), {
			weekStartsOn: 1
		}); // 1

		getWeekViewDataForCopy({
			username: filterUser,
			periodStartDate: startWeekDay ?? '',
			periodEndDate: endWeekDay ?? ''
		});

		// Reset changesMade state
		setChangesMade(false);
	};

	const defaultValues = {};

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const handleClickEventAction = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElEventAction(event.currentTarget);
	};

	const handleClickHeaderAction = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElHeaderAction(event.currentTarget);
	};

	// Get the necessary info to populate chardCards and pie chart (Gets numbers of projects, total hours and all projects used in timesheets)
	const getInfoToChart = () => {
		//Gets all the time entries of the current week
		const employeeTimeEntries = weekViewData?.results?.listTimeEntriesByPeriodId;
		//Gets all the project that the user has
		const assignedProjects = weekViewData?.results?.assignedAccountProjects;
		const favoriteAssignedProjects = weekViewData?.results?.favoriteAssignedProjects;
		const timeOffTypes = weekViewData?.results?.accountTimeOffType;
		let totalHours = 0;
		let totalProjects: any[] = [];
		let cardsInfo: any[] = [];
		//For every time entry adds up the total hours of the week, adds up total projects and get the array to show in chart
		employeeTimeEntries?.forEach(
			(timeEntry: { hours: string; accountProjectId: any; accountTimeOffTypeId: any }) => {
				const projectName = assignedProjects?.find(e => e.accountProjectId == timeEntry.accountProjectId);

				let auxTimeEntry = {
					id: timeEntry?.accountProjectId,
					label: projectName?.projectName,
					value: parseFloat(timeEntry.hours)
				};
				const alreadyExists = cardsInfo?.find(e => e.id === timeEntry.accountProjectId);
				if (alreadyExists != null) {
					cardsInfo.forEach(e => {
						if (alreadyExists.id == e.id) {
							e.value += parseFloat(timeEntry.hours);
						}
					});
				} else {
					cardsInfo.push(auxTimeEntry);
				}
				totalHours += convertTimeToDecimal(timeEntry.totalTime);
				if (!totalProjects.includes(timeEntry.accountProjectId)) {
					totalProjects.push(timeEntry.accountProjectId);
				}
			}
		);

		return [cardsInfo, totalHours, totalProjects.length];
	};

	useEffect(() => {
		setChartCardInfo(getInfoToChart());
	}, [weekViewData]);

	//Reload After Copy
	useEffect(() => {
		if (copySaveMessage?.message) {
			setClearCopyMessage();
		}
	}, [copySaveMessage]);

	//Reload After Save TimeSheet
	useEffect(() => {
		if (saveTimesheetMessage?.message) {
			const startWeekDay = startOfWeek(selectedDate, { weekStartsOn: 1 }); // 1 represents monday
			startWeekDay.setHours(startWeekDay.getHours() + 1);
			// Gets the final day of the week
			const endWeekDay = endOfWeek(selectedDate, { weekStartsOn: 1 }); // 1
			setLoadAfterMove(true);

			getWeekViewData({
				username: filterUser,
				periodStartDate: startWeekDay,
				periodEndDate: endWeekDay
			});

			// Reset changesMade state
			setChangesMade(false);
			dispatch(setSaveTimesheet(null));
		}
	}, [saveTimesheetMessage]);

	const handleCloseEventAction = () => {
		setAnchorElEventAction(null);
	};
	const handleCloseHeaderAction = () => {
		setAnchorElHeaderAction(null);
	};
	//Actions of an event
	let eventActions = [
		{
			id: 'editAction',
			label: 'Edit',
			onClick: () => {
				setEdit(true);
				setOpen(true);
				setAnchorElEventAction(null);
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'viewDetails',
			label: 'View Details',
			onClick: () => {
				setIsViewDetails(true);
				setAnchorElEventAction(null);
			}
		},
		{
			id: 'moveAction',
			label: 'Move',
			onClick: (e: any) => {
				setOpenModalTimeEntryMover(true);
				setAnchorElEventAction(null);
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'copyAction',
			label: 'Copy',
			onClick: (e: any) => {
				setOpenModalTimeEntryCopy(true);
				setAnchorElEventAction(null);
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'deleteAction',
			color: 'secondary',
			label: 'Delete',
			onClick: e => {
				setConfirmDeleteModal(true);
				setAnchorElEventAction(null);
			}
			// ,disabled: isDifferentUserOpened
		}
	];

	//Remove move or copy action when necessary (when it is submitted or approved or is an absence)
	if (eventInformation) {
		if (eventInformation?.approved || eventInformation?.submitted) {
			eventActions = eventActions.filter(e => e.id != 'deleteAction');
			eventActions = eventActions.filter(e => e.id != 'editAction');
		}
		if (
			eventInformation?.approved ||
			eventInformation?.submitted ||
			XTRACKER_ABSENCE_PROJECTS.includes(eventInformation?.accountProjectId)
		) {
			eventActions = eventActions.filter(e => e.id != 'moveAction');
			eventActions = eventActions.filter(e => e.id != 'editAction');
		}
		if (XTRACKER_ABSENCE_PROJECTS.includes(eventInformation?.accountProjectId)) {
			eventActions = eventActions.filter(e => e.id != 'copyAction');
		}
	}

	// Print timesheet data
	const preparePrintTimesheetData = () => {
		const hours = Math.floor(weekSum);
		const minutes = getPossibleMinutes(weekSum);
		let startWeekDay = null;
		let modifiedArray = null;
		// Gets the final day of the week
		let endWeekDay = null;

		let removeHoursFromDate = new Date();
		modifiedArray = mergedArray.map(e => {
			removeHoursFromDate = new Date(e.totalTime);
			return {
				...e,
				hours: (new Date(e.totalTime).getHours() + new Date(e.totalTime).getMinutes() / 60).toFixed(2),
				projectName: e.title,
				timeEntryDate: format(new Date(e.totalTime), 'yyyy-MM-dd'),
				description: e.description.replace(/\n/g, ' ')
			};
		});
		startWeekDay = startOfWeek(removeHoursFromDate, { weekStartsOn: 1 }); // 1 represents monday

		// Gets the final day of the week
		endWeekDay = endOfWeek(startWeekDay, { weekStartsOn: 1 }); // 1

		printTimesheet({
			timesheet: modifiedArray != null ? modifiedArray : mergedArray,
			totalHours: hours,
			totalMinutes: minutes,
			employeeName: weekViewData?.results?.viewAccountEmployees?.employeeName,
			periodDate:
				weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.timeEntryStartDate !== null
					? weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.timeEntryStartDate +
					  ' - ' +
					  weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.timeEntryEndDate
					: startWeekDay.toISOString().split('T')[0] + ' - ' + endWeekDay.toISOString().split('T')[0],
			timesheetDescription: weekViewData?.results?.accountEmployeeTimeEntryPeriod?.periodDescription || ''
		});
	};

	// Export timesheet data
	const exportTimesheetData = () => {
		exportTimesheet({
			timesheet: mergedArray,
			employeeName: weekViewData?.results?.viewAccountEmployees?.employeeName,
			employeeCode: weekViewData?.results?.viewAccountEmployees?.employeeCode,
			eMailAddress: weekViewData?.results?.viewAccountEmployees?.eMailAddress,
			periodDate:
				weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.timeEntryStartDate +
				'_' +
				weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.timeEntryEndDate
		});
	};

	// Save timesheet data
	const saveTimesheetData = (buttonClicked: string) => {
		// Array to store timesheet entries
		const timesheet: ITimeSheetSave[] = [];

		mergedArray.forEach(entry => {
			// Check if there's an existing entry with the same project task
			let existingEntry;
			if (entry?.projectCode?.includes('ABSENCE')) {
				existingEntry = timesheet?.find(
					t => t.accountProject === entry.accountProjectId && t.timeOffTypeId === entry.accountTimeOffTypeId
				);
			} else {
				existingEntry = timesheet?.find(
					t =>
						t.projTask === entry.accountProjectTaskId &&
						t.costCenter?.toString() === entry.accountCostCenterId?.toString() &&
						t.workType?.toString() === entry.accountWorkTypeId?.toString()
				);
			}

			// Convert start and end dates to Date objects
			const startDate = new Date(entry.start);
			const endDate = new Date(entry.end);
			const originalDate = format(startDate, 'yyyy-MM-dd HH:mm:ss').split(' ');

			// Calculate time difference between start and end dates
			const time = calculateDiferenceHoursMinutes(startDate, endDate);

			// Check if the entry spans multiple dates
			startDate.setHours(0, 0, 0);
			endDate.setHours(0, 0, 0);
			const entrySpansMultipleDates = startDate.getTime() != endDate.getTime();

			// Create a time entry object
			const timeEntry: ITimeEntrySave = {
				id: entry.accountEmployeeTimeEntryId ? entry.accountEmployeeTimeEntryId : 0,
				date: originalDate[0],
				description: entry.description ? entry.description : '',
				time:
					originalDate[0] +
					' ' +
					time.hours.toString().padStart(2, '0') +
					':' +
					time.minutes.toString().padStart(2, '0') +
					':00'
			};

			// If there's an existing entry for the project task
			if (existingEntry) {
				const existingTimeEntry = existingEntry.timeEntries?.find(te => te.date === timeEntry.date);

				if (entrySpansMultipleDates) {
					addNotification('info', 'Time entry must start and end in the same date!');
				} else {
					// Check if there's an existing time entry for the same date
					if (existingTimeEntry) {
						const existingTime = new Date(existingTimeEntry.time);
						existingTime.setHours(
							existingTime.getHours() + time.hours,
							existingTime.getMinutes() + time.minutes
						);
						existingTimeEntry.time = format(existingTime, 'yyyy-MM-dd HH:mm:ss');
						existingTimeEntry.description = existingTimeEntry.description.concat(
							'; ',
							timeEntry.description
						);
					} else {
						// If no existing time entry for the date, add the new time entry
						existingEntry.timeEntries.push(timeEntry);
					}
				}
			} else {
				// If there's no existing entry for the project task
				const newTimeSheetEntry: ITimeSheetSave = {
					percentage: entry.percentage ? entry.percentage : 0,
					projTask: entry.accountProjectTaskId,
					projectTaskName: entry.projectTaskName || entry.accountTimeOffType,
					costCenter:
						entry.isTimeOff || XTRACKER_ABSENCE_PROJECTS.includes(entry.accountProjectId)
							? 0
							: Number(entry.accountCostCenterId),
					workType:
						entry.isTimeOff || XTRACKER_ABSENCE_PROJECTS.includes(entry.accountProjectId)
							? 0
							: Number(entry.accountWorkTypeId),
					accountProject: entry.accountProjectId,
					isTimeOff: entry.isTimeOff
						? entry.isTimeOff
						: XTRACKER_ABSENCE_PROJECTS.includes(entry.accountProjectId),
					isExternalTimeOff: XTRACKER_EXTERNAL_TIMEOFF_TASKS.includes(entry.projectTaskName) ? true : false,
					timeOffTypeId: entry.timeOffTypeId ? entry.timeOffTypeId : '',
					timeOffRequestId: entry.timeOffRequestId ? entry.timeOffRequestId : 0,
					accountParty: entry.accountParty
						? entry.accountParty
						: weekViewData?.results?.accountPartiesForTimeEntry[0].accountPartyId
						? weekViewData?.results?.accountPartiesForTimeEntry[0].accountPartyId
						: 0,
					approved: entry.approved ? entry.approved : false,
					rejected: entry.rejected ? entry.rejected : false,
					accountEmployeeTimeEntryApprovalProjectId: entry.accountEmployeeTimeEntryApprovalProjectId
						? entry.accountEmployeeTimeEntryApprovalProjectId
						: '',
					projectApproved: entry.projectApproved ? entry.projectApproved : false,
					timeEntries: [timeEntry]
				};

				// If the entry spans multiple dates, show a notification
				if (entrySpansMultipleDates) {
					addNotification('info', 'Time entry must start and end in the same date!');
				} else {
					timesheet.push(newTimeSheetEntry);
				}
			}
		});

		saveTimesheet({
			accountEmployeeTimeEntryPeriodId: weekViewData?.results?.accountEmployeeTimeEntryPeriod
				?.accountEmployeeTimeEntryPeriodId
				? weekViewData?.results?.accountEmployeeTimeEntryPeriod?.accountEmployeeTimeEntryPeriodId
				: '',
			accountEmployeeId: weekViewData?.results?.viewAccountEmployees?.accountEmployeeId,
			isCopyFromDate: false,
			dtStartDate: weekViewData?.results?.periodStartDate,
			dtEndDate: weekViewData?.results?.periodEndDate,
			buttonClicked: buttonClicked,
			periodDescription: periodDescriptionValue,
			timeEntryViewType: 'Weekly',
			employeeNameWithCode: weekViewData?.results?.viewAccountEmployees?.employeeNameWithCode,
			timesheet: timesheet,
			workingDaysCount: 7,
			sessionEmployeeId: weekViewData?.results?.viewAccountEmployees?.accountEmployeeId,
			loggedUserCode: userInfo?.username
		});
	};
	//Actions of header
	const headerActions = [
		{
			id: 'requestChange',
			label: (
				<>
					<PublishedWithChangesOutlined htmlColor="#718FA2" />
					&nbsp;Request Change
				</>
			),
			onClick: () => {
				handleRequestChangeClick();
			},
			disabled: !(
				weekViewData?.results?.accountEmployeeTimeEntryPeriod?.approved == true ||
				(weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted == true &&
					weekViewData?.results?.listTimeEntriesByPeriodId?.some(
						entry => entry.approved === true && entry.isTimeOff === false
					))
			)
		},
		{
			id: 'approvalDetails',
			label: (
				<>
					<CheckIcon htmlColor="#718FA2" />
					&nbsp;Approval Details
				</>
			),
			onClick: () => {
				setOpenApprovalDetailsModal(true);
				handleCloseHeaderAction();
			}
		},
		{
			id: 'printTimesheet',
			label: (
				<>
					<Print />
					&nbsp;Print timesheet
				</>
			),
			disabled: mergedArray?.length <= 0,
			// || isDifferentUserOpened
			onClick: () => {
				preparePrintTimesheetData();
				handleCloseHeaderAction();
			}
		},
		{
			id: 'exportTimesheet',
			label: (
				<>
					<Export />
					&nbsp; Export Timesheet
				</>
			),
			onClick: () => {
				exportTimesheetData();
				handleCloseHeaderAction();
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'importTimesheet',
			label: (
				<>
					<Import />
					&nbsp; Import Timesheet
				</>
			),
			onClick: e => {
				setModalImportOpen(true);
				handleCloseHeaderAction();
			}
			// ,disabled: isDifferentUserOpened
		},
		{
			id: 'editPeriodDescription',
			label: (
				<>
					<Edit color="secondary" />
					&nbsp; Edit Period Description
				</>
			),
			onClick: e => {
				setModalPeriodDescription(true);
				handleCloseHeaderAction();
			},
			disabled:
				mergedArray.length == 0 ||
				// isDifferentUserOpened ||
				weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted == true ||
				weekViewData?.results?.accountEmployeeTimeEntryPeriod?.approved == true
		},
		{
			id: 'copyTimesheet',
			label: (
				<>
					<ContentCopyIcon />
					&nbsp; Copy Timesheet
				</>
			),
			onClick: () => {
				setOpenCopyTimesheetModal(true);
				setSelectedDateForCopy(selectedDate);
				handleCloseHeaderAction();
			},
			disabled:
				// isDifferentUserOpened ||
				weekViewData?.results?.listTimeEntriesByPeriodId?.some(entry => entry.submitted === true)
		}
	];

	// Changes the view depending on the selected view
	const changeView = event => {
		switch (event?.target?.value || event) {
			case 'workWeek':
				if (!areChangesMade) {
					setMyTimesheetsFilter('workWeek');
					setTypeView('workWeek');
					setView({
						schedule: {
							type: 'week',
							allDay: false,
							startDay: 1,
							endDay: 5,
							startTime: '00:00',
							endTime: '24:00',
							timeCellStep: 30,
							timeLabelStep: 30,
							currentTimeIndicator: false
						}
					});
					setCurrentView(event?.target?.value || event);
					loadCurrentPeriodDate(selectedDate.toISOString());
				} else {
					setSaveWarningView('workWeek');
					setChangePageAction('View');
					setShowSaveWarningModal(true);
				}

				break;

			case 'week':
				if (!areChangesMade) {
					setMyTimesheetsFilter('week');
					setTypeView('week');
					setView({
						schedule: {
							type: 'week',
							allDay: false,
							startDay: 1,
							endDay: 0,
							startTime: '00:00',
							endTime: '24:00',
							timeCellStep: 30,
							timeLabelStep: 30,
							currentTimeIndicator: false
						}
					});
					setCurrentView(event?.target?.value || event);
					loadCurrentPeriodDate(selectedDate.toISOString());
				} else {
					setSaveWarningView('week');
					setChangePageAction('View');
					setShowSaveWarningModal(true);
				}
				break;
			case 'day':
				if (!areChangesMade) {
					setMyTimesheetsFilter('day');
					setTypeView('day');
					setView({
						schedule: {
							type: 'day',
							allDay: false,
							size: 1,
							startTime: '00:00',
							endTime: '24:00',
							timeCellStep: 30,
							timeLabelStep: 30,
							currentTimeIndicator: false
						}
					});
					//  Gets the initial date of the week
					const startWeekDay = startOfWeek(selectedDate, { weekStartsOn: 1 }); // 1 represents monday

					setSelectedDate(startWeekDay);
					setCurrentView(event?.target?.value || event);
					loadCurrentPeriodDate(selectedDate.toISOString());
				} else {
					setSaveWarningView('day');
					setChangePageAction('View');
					setShowSaveWarningModal(true);
				}

				break;
		}
	};

	//Used when save warning pop up appears and confirm is pressed
	const changeViewSaveWarning = event => {
		switch (event?.target?.value || event) {
			case 'workWeek':
				setMyTimesheetsFilter('workWeek');
				setTypeView('workWeek');
				setView({
					schedule: {
						type: 'week',
						allDay: false,
						startDay: 1,
						endDay: 5,
						startTime: '00:00',
						endTime: '24:00',
						timeCellStep: 30,
						timeLabelStep: 30,
						currentTimeIndicator: false
					}
				});
				setCurrentView(event?.target?.value || event);
				loadCurrentPeriodDate(selectedDate.toISOString());
				break;

			case 'week':
				setMyTimesheetsFilter('week');
				setTypeView('week');
				setView({
					schedule: {
						type: 'week',
						allDay: false,
						startDay: 1,
						endDay: 0,
						startTime: '00:00',
						endTime: '24:00',
						timeCellStep: 30,
						timeLabelStep: 30,
						currentTimeIndicator: false
					}
				});
				setCurrentView(event?.target?.value || event);
				loadCurrentPeriodDate(selectedDate.toISOString());
				break;
			case 'day':
				setMyTimesheetsFilter('day');
				setTypeView('day');
				setView({
					schedule: {
						type: 'day',
						allDay: false,
						size: 1,
						startTime: '00:00',
						endTime: '24:00',
						timeCellStep: 30,
						timeLabelStep: 30,
						currentTimeIndicator: false
					}
				});
				//  Gets the initial date of the week
				const startWeekDay = startOfWeek(selectedDate, { weekStartsOn: 1 }); // 1 represents monday

				setSelectedDate(startWeekDay);
				setCurrentView(event?.target?.value || event);
				loadCurrentPeriodDate(selectedDate.toISOString());
				break;
		}
	};

	const btnFiltersOnClick = () => {
		setOpenModalFilters(true);
	};

	// Updates date when Arrows are used to change week or day
	const updateTimesheetsArrows = (type: String) => {
		// Reset column/total week values and events on week change.
		setWeekSum(0);
		setDailySums({ Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0 });
		setMergedArray([]);
		setPeriodDescriptionValue('');
		//Case is week view it will get the first day of the week and the last day of the week and loads the events of the week
		if (currentView == 'week' || currentView == 'workWeek') {
			setSelectedDate(type == 'Back' ? removeWeeks(selectedDate, 1) : addWeeks(selectedDate, 1));

			//  Gets the initial date of the week
			const startWeekDay = startOfWeek(selectedDate, { weekStartsOn: 1 }); // 1 represents monday
			startWeekDay.setHours(startWeekDay.getHours() + 1);

			// Gets the final day of the week
			const endWeekDay = endOfWeek(selectedDate, { weekStartsOn: 1 }); // 1
			getWeekViewData({
				username: filterUser,
				periodStartDate: startWeekDay,
				periodEndDate: endWeekDay
			});

			//Case is day view it will load the events for that day
		} else {
			let auxSelectedDate = selectedDate;
			auxSelectedDate = type == 'Back' ? removeDay(auxSelectedDate) : addDay(auxSelectedDate);
			auxSelectedDate.setHours(auxSelectedDate.getHours() + 1);
			const transformSelectedStringToDate = new Date(auxSelectedDate.toISOString().split('T')[0]);
			setSelectedDate(transformSelectedStringToDate);

			const startWeekDay = startOfWeek(transformSelectedStringToDate, { weekStartsOn: 1 }); // 1 represents monday
			startWeekDay.setHours(startWeekDay.getHours() + 1);

			// Gets the final day of the week
			const endWeekDay = endOfWeek(transformSelectedStringToDate, { weekStartsOn: 1 }); // 1

			getWeekViewData({
				username: filterUser,
				periodStartDate: startWeekDay,
				periodEndDate: endWeekDay
			});
		}
		// Reset changesMade state
		setChangesMade(false);
	};
	// Updates date when date pickers or today button is used
	const updateTimesheetTodayDatePicker = (date = selectedDate) => {
		setPeriodDescriptionValue('');

		//Case is week view it will get the first day of the week and the last day of the week and loads the events of the week

		//  Gets the initial date of the week
		const startWeekDay = startOfWeek(date, { weekStartsOn: 1 }); // 1 represents monday
		startWeekDay.setHours(startWeekDay.getHours() + 1);
		// Gets the final day of the week
		const endWeekDay = endOfWeek(date, { weekStartsOn: 1 }); // 1

		getWeekViewData({
			username: filterUser,
			periodStartDate: startWeekDay,
			periodEndDate: endWeekDay
		});

		// Reset changesMade state
		setChangesMade(false);
	};
	// Check if changes were made in current week
	const checkChangesBeforeLeaveTimesheet = (leaveCurrentTimeSheetAction: string, cardInfo = '') => {
		if (!areChangesMade) {
			leaveCurrentTimeSheet(leaveCurrentTimeSheetAction, cardInfo);
		} else {
			setChangePageAction(leaveCurrentTimeSheetAction);
			setShowSaveWarningModal(true);
		}
	};

	// Leave Current TimeSheet for each diferent action
	const leaveCurrentTimeSheet = (leaveCurrentTimeSheetAction: string, cardInfo: any) => {
		if (leaveCurrentTimeSheetAction == 'BackArrow') {
			updateTimesheetsArrows('Back');
		} else if (leaveCurrentTimeSheetAction == 'FowardArrow') {
			updateTimesheetsArrows('Foward');
		} else if (leaveCurrentTimeSheetAction == 'DatePicker') {
			setSelectedDate(selectedDateForSaveWarning);
			updateTimesheetTodayDatePicker(updateTimesheetTodayDatePickerForSaveWarning);
		} else if (leaveCurrentTimeSheetAction == 'GoToCard') {
			goToCardRedirect(cardInfo);
		} else if (leaveCurrentTimeSheetAction == 'FilterSelector') {
			setSelectedDate(selectedDateForSaveWarning);
			loadCurrentPeriodDate(loadCurrentPeriodDateForSaveWarning);
			setChangesMade(false);
			setOpenModalFilters(false);
		} else if (leaveCurrentTimeSheetAction == 'View') {
			changeViewSaveWarning(saveWarningView);
			setSaveWarningView(null);
			setChangesMade(false);
		}
	};

	const isWeekend = (date: any) => {
		return date.getDay() === 0 || date.getDay() === 6;
	};

	// Navigation buttons for graph chart
	const customWithNavButtonsGraphChart = () => (
		<>
			<Grid container direction="column" justifyContent="center" alignItems="space-between">
				<Grid
					container
					justifyContent="space-between"
					alignItems="flex-end"
					className={classes.gridRow}
					id={'toolbarID'}>
					<Grid className={classes.displayFlex}>
						<Grid item className={classes.displayFlex}>
							<Grid item className={classes.displayFlex} id={'toolbarTodayID'}>
								<CalendarTodayIcon className={classes.alignCenter} />
								<Button
									className={classes.todayButton}
									onClick={e => {
										// Check if changes were in current week
										if (!areChangesMade) {
											setSelectedDate(new Date(new Date().setHours(0, 0, 0, 0)));
											updateTimesheetTodayDatePicker(new Date());
										} else {
											setSelectedDateForSaveWarning(new Date(new Date().setHours(0, 0, 0, 0)));
											setUpdateTimesheetTodayDatePickerForSaveWarning(new Date());
											setChangePageAction('DatePicker');
											setShowSaveWarningModal(true);
										}
									}}>
									Today
								</Button>
							</Grid>
							<Grid id={'toolbarArrowsID'}>
								<IconButton
									color="primary"
									style={{ marginRight: '20px' }}
									onClick={e => {
										// Check if changes were in current week
										checkChangesBeforeLeaveTimesheet('BackArrow');
									}}>
									<ArrowLeft />
								</IconButton>
								<IconButton
									color="primary"
									onClick={e => {
										// Check if changes were in current week
										checkChangesBeforeLeaveTimesheet('FowardArrow');
									}}>
									<ArrowRight />
								</IconButton>
							</Grid>
						</Grid>
						<Grid ref={datePickerRef} item id={'toolbarCalendarPickID'}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<MuiDatePicker
									open={openCalendarHeader}
									views={['year', 'month', 'day']}
									format="MMMM yyyy"
									value={
										new Date(
											selectedDate.getFullYear(),
											selectedDate.getMonth(),
											selectedDate.getDate()
										)
									}
									onAccept={newValue => {
										// Check if changes were in current week
										if (!areChangesMade) {
											setSelectedDate(
												new Date(
													newValue?.getFullYear(),
													newValue?.getMonth(),
													newValue?.getDate()
												)
											);
											updateTimesheetTodayDatePicker(newValue);
										} else {
											setSelectedDateForSaveWarning(
												new Date(
													newValue?.getFullYear(),
													newValue?.getMonth(),
													newValue?.getDate()
												)
											);
											setUpdateTimesheetTodayDatePickerForSaveWarning(newValue);
											setShowSaveWarningModal(true);
										}
										setOpenCalendarHeader(false);
										setAnchorEl(null);
									}}
									slots={{
										openPickerButton: () => <ArrowDropDown></ArrowDropDown>,
										textField: params => <TextField ref={params.inputRef} {...params}></TextField>
									}}
									shouldDisableDate={isWeekend}
									//remove slotProps to remove customization
									slotProps={{
										popper: {
											anchorEl: anchorEl
										},
										textField: {
											InputProps: {
												size: 'medium',
												readOnly: true,
												sx: {
													fontSize: 20,
													maxWidth: 225
												},
												disableUnderline: true
											},

											variant: 'standard',
											onClick: e => {
												setAnchorEl(datePickerRef.current);
												setOpenCalendarHeader(!openCalendarHeader);
											}
										}
									}}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Grid item id={'toolbarViewTypeID'}>
						<Select
							autoWidth
							variant="standard"
							disableUnderline
							sx={{
								height: '30px',
								boxShadow: 'none',
								'.MuiOutlinedInput-notchedOutline': { border: 0 }
							}}
							value={currentView}
							onChange={changeView}
							renderValue={value => {
								const label = value == 'day' ? 'Day' : value == 'week' ? 'Week' : 'Work Week';
								return (
									<Grid container direction="row" spacing={2} onClick={e => {}}>
										<Grid item>
											{value == 'day' && <CalendarDay fontSize="small"></CalendarDay>}
											{value == 'week' && <CalendarWeek fontSize="small"></CalendarWeek>}
											{value == 'workWeek' && (
												<CalendarWorkWeek fontSize="small"></CalendarWorkWeek>
											)}
										</Grid>
										<Grid item>
											{value == 'day' ? 'Day' : value == 'week' ? 'Week' : 'Work Week'}
										</Grid>
									</Grid>
								);
							}}>
							<MenuItem value="day">
								<Grid container direction="row" spacing={2} onClick={e => {}}>
									<Grid item>
										<CalendarDay fontSize="small"></CalendarDay>
									</Grid>
									<Grid item>Day</Grid>
									{currentView == 'day' && (
										<Grid item>
											<CheckIcon fontSize="small"></CheckIcon>
										</Grid>
									)}
								</Grid>
							</MenuItem>
							<MenuItem value="week">
								<Grid container direction="row" spacing={2} onClick={e => {}}>
									<Grid item>
										<CalendarWeek fontSize="small"></CalendarWeek>
									</Grid>
									<Grid item>Week</Grid>
									{currentView == 'week' && (
										<Grid item>
											<CheckIcon fontSize="small"></CheckIcon>
										</Grid>
									)}
								</Grid>
							</MenuItem>
							<MenuItem value="workWeek">
								<Grid container direction="row" spacing={2} onClick={e => {}}>
									<Grid item>
										<CalendarWorkWeek fontSize="small"></CalendarWorkWeek>
									</Grid>
									<Grid item>Work Week</Grid>
									{currentView == 'workWeek' && (
										<Grid item>
											<CheckIcon fontSize="small"></CheckIcon>
										</Grid>
									)}
								</Grid>
							</MenuItem>
						</Select>
					</Grid>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Grid item id={'toolbarGraphSwitchID'}>
						<Toggler
							disabled={false}
							size="small"
							orientation="horizontal"
							defaultValue={currentToggle}
							additionalOnChange={e => {
								setCurrentToggle(e);
								if (e == 'chart') setNeedsCalendarToUpdateHours(true);
								if (e == 'weekly') setNeedsCalendarToUpdateHours(false);
							}}
							options={[
								{
									id: 0,
									value: 'weekly',
									icon: <CalendarTodayIcon />
								},
								{
									id: 1,
									value: 'chart',
									icon: <AccessTimeIcon />
								}
							]}
						/>
					</Grid>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Grid item className={classes.displayFlex}>
						<Button
							id={'toolbarFiltersID'}
							className={classes.filterButtonSelected}
							variant="outlined"
							style={{
								padding: '7px 35px 7px 35px'
							}}
							onClick={btnFiltersOnClick}
							// disabled={isDifferentUserOpened}
						>
							Filters
						</Button>
					</Grid>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Grid item id={'toolbarExtraActionsID'}>
						<IconButton onClick={handleClickHeaderAction} id="header-Actions-button">
							<MoreVert />
						</IconButton>
						<Menu
							id="header-Actions-menu"
							aria-labelledby="header-Actions-button"
							open={Boolean(anchorElHeaderAction)}
							anchorEl={anchorElHeaderAction}
							onClose={handleCloseHeaderAction}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}>
							{headerActions.map(e => (
								<MenuItem key={`headerAction_${e.id}`} onClick={e.onClick} disabled={e.disabled}>
									{e.label}
								</MenuItem>
							))}
						</Menu>
					</Grid>
				</Grid>
				<Grid container direction="row" className={classes.gridRow} id={'toolbarTotalHoursID'}>
					<Grid item className={classes.totalHours}>
						Total {Math.floor(weekSum)}h {getPossibleMinutes(weekSum).toString().padStart(2, '0')}m
					</Grid>
				</Grid>
			</Grid>
		</>
	);

	const scrollToElement = event => {
		// Prevent the default behavior of the anchor tag (redirecting)
		event.preventDefault();
		event.stopPropagation();

		// Get the target element's ID from the href attribute
		const targetId = event.target.getAttribute('href').substring(1);

		// Find the target element
		const targetElement = document.getElementById(targetId);

		// Scroll to the target element
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	// Text for missing timesheets (has 2 missing has hardcoded later changed with external info)
	const missingTimesheetsTypography = (
		<Typography style={{ marginLeft: '40px' }}>
			<p style={{ fontSize: '20px' }}>
				<span style={{ fontSize: '20px', fontWeight: 'bold' }}>Missing</span> Timesheets
			</p>
			{weekViewData && weekViewData?.results?.missingTimesheetsDays?.NotSaved !== 0 && (
				<p>
					You have{' '}
					<span>
						<a
							href="#idCard"
							onClick={scrollToElement}
							style={{ color: '#FAC15F', cursor: 'pointer', textDecoration: 'none' }}>
							{weekViewData?.results?.missingTimesheetsDays?.NotSaved} missing{' '}
						</a>
					</span>{' '}
					timesheet periods to fill and submit.
				</p>
			)}
			{weekViewData && weekViewData?.results?.missingTimesheetsDays?.Saved !== 0 && (
				<p>
					You have{' '}
					<span style={{ color: '#FAC15F', cursor: 'pointer' }}>
						<a
							href="#idCard"
							onClick={scrollToElement}
							style={{ color: '#FAC15F', cursor: 'pointer', textDecoration: 'none' }}>
							{weekViewData?.results?.missingTimesheetsDays?.Saved} saved (not submitted)
						</a>
					</span>{' '}
					timesheet periods.
				</p>
			)}
			{weekViewData && weekViewData?.results?.missingTimesheetsDays?.Rejected !== 0 && (
				<p>
					You have{' '}
					<span style={{ color: '#D15E5E', cursor: 'pointer' }}>
						<a
							href="#idCard"
							onClick={scrollToElement}
							style={{ color: '#D15E5E', cursor: 'pointer', textDecoration: 'none' }}>
							{weekViewData?.results?.missingTimesheetsDays?.Rejected} rejected
						</a>
					</span>{' '}
					timesheet periods.
				</p>
			)}
		</Typography>
	);

	//Prepares the data to the go to cards
	const prepareGoToCardInfo = () => {
		const missingTimesheets = weekViewData?.results?.missingTimesheetsPeriod;

		let cardInfos: { id: any; title: any; description: string; period: any }[][] = [];

		missingTimesheets?.forEach((element, index) => {
			if (element.status == 'Missing') {
				cardInfos.push([
					{
						id: index,
						title: element.status,
						description: '40h 00m',
						period: element.period
					}
				]);
			} else {
				const hours = decimalToHours(element.totalSavedHours);

				// Total minutes in 40 hours and minutes worked
				const remainingMinutes = 40 * 60 - (hours.hours * 60 + hours.minutes);

				// Calculate remaining hours and minutes
				const remainingHours = Math.floor(remainingMinutes / 60);
				const remainingMins = remainingMinutes % 60;

				if (element.totalSavedHours < 40) {
					cardInfos.push([
						{
							id: index,
							title: element.status,
							description:
								hours.hours.toString().padStart(2, '0') +
								'h' +
								' ' +
								hours.minutes.toString().padStart(2, '0') +
								'm',
							period: element.period
						},
						{
							id: 'missing_' + index,
							title: 'Missing',
							description:
								remainingHours.toString().padStart(2, '0') +
								'h' +
								' ' +
								(hours.minutes === 0 ? '00' : remainingMins.toString().padStart(2, '0')) +
								'm',
							period: element.period
						}
					]);
				} else {
					cardInfos.push([
						{
							id: index,
							title: element.status,
							description:
								hours.hours.toString().padStart(2, '0') +
								'h' +
								' ' +
								hours.minutes.toString().padStart(2, '0') +
								'm',
							period: element.period
						}
					]);
				}
			}
		});

		return cardInfos;
	};

	//Redirects to the specific week
	const goToCardRedirect = cardInfo => {
		changeView('workWeek');
		const period = cardInfo[0].period;
		//Start Date
		const date = period.split(' ');
		var dateParts = date[0].split('/');
		const newDateStartDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
		newDateStartDate.setDate(newDateStartDate.getDate() + 1);
		const startWeekDay = startOfWeek(newDateStartDate, { weekStartsOn: 1 }); // 1 represents monday
		startWeekDay.setHours(startWeekDay.getHours() + 1);
		// Gets the final day of the week
		const endWeekDay = endOfWeek(newDateStartDate, { weekStartsOn: 1 }); // 1

		//Loads the new week
		getWeekViewData({
			username: filterUser,
			periodStartDate: startWeekDay,
			periodEndDate: endWeekDay
		});
		startWeekDay.setHours(startWeekDay.getHours() - 1);
		setSelectedDate(startWeekDay);

		// Reset changesMade state
		setChangesMade(false);
	};

	//Prepares data for XpandedDatePicker (Right side bar)
	const prepareXpandedDatePickerData = () => {
		const missingTimesheets = weekViewData?.results?.missingTimesheetsPeriod;

		let missingTimesheetsDate: any[] = [{}];
		let currentDate = startOfDay(new Date());
		missingTimesheets?.forEach(timesheet => {
			const period = timesheet.period;
			//Start Date
			const date = period.split(' ');
			var dateParts = date[0].split('/');
			const newDateStartDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

			//End Date
			var dateParts = date[2].split('/');
			const newDateEndDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

			//Get Dates between start Date and end Date
			currentDate = newDateStartDate;
			while (currentDate <= newDateEndDate) {
				missingTimesheetsDate.push({
					date: startOfDay(new Date(currentDate)),
					badgeColor: timesheet.status == 'Rejected' ? 'warning' : 'warningOrange'
				});
				currentDate.setDate(currentDate.getDate() + 1);
				currentDate = startOfDay(currentDate);
			}
		});

		return missingTimesheetsDate;
	};

	//Xpanded Date picker with dummy information (will be later be changed by external info)
	const rightSideBarXpandedDatePicker = (
		<div style={{ marginTop: '-60px', transform: 'scale(0.87)' }} id={'rightSidebarCalendarID'}>
			<XpandedDatePicker
				dates={prepareXpandedDatePickerData()}
				name="exampleDate"
				value={selectedDate}
				control={control as any}
				errors={errors as any}
				isSideBar={true}
				additionalOnChange={e => {
					// Check if changes were in current week
					if (!areChangesMade) {
						setSelectedDate(new Date(e));
						updateTimesheetTodayDatePicker(new Date(e));
					} else {
						setSelectedDateForSaveWarning(new Date(e));
						setUpdateTimesheetTodayDatePickerForSaveWarning(new Date(e));
						setChangePageAction('DatePicker');
						setPeriodDescriptionValue('');
						setShowSaveWarningModal(true);
					}
				}}
				hasInitialValue={true}
			/>
		</div>
	);

	//GoToCards with hardcoded information (will be later added external information)
	const missingTimesheetsCards = prepareGoToCardInfo().map((cardInfo, index) => (
		<div key={cardInfo.id} id="idCard" style={{ marginBottom: '40px' }}>
			From {cardInfo[0].period}
			<GoToCard
				id={index}
				info={cardInfo}
				onClick={e => {
					// Check if changes were in current week
					setCardInfoForSaveWarning(cardInfo);
					checkChangesBeforeLeaveTimesheet('GoToCard', cardInfo);
				}}
				borderColor={cardInfo[0].title == 'Rejected' ? '#D1345E' : '#FAC15F'}></GoToCard>
		</div>
	));

	//Allows to open and close the right side bar
	const openCloseRightSideBar = () => {
		setIsRightBarOpen(isRightBarOpen ? false : true);
	};

	// Calendar event Categories
	const getCategory = (state: any) => {
		switch (state) {
			case 1:
				return {
					name: 'Not Submitted',
					color: '#FAC15F'
				};
			case 2:
				return {
					name: 'Submitted',
					color: '#2BAAFF'
				};
			case 3:
				return {
					name: 'Approved',
					color: '#21BA45'
				};
			case 4:
				return {
					name: 'Rejected',
					color: '#D1345E'
				};
			default:
				return {
					name: 'Not Submitted',
					color: '#FAC15F'
				};
		}
	};

	// Custom calendar event
	const customEvent = useCallback(data => {
		const cat = getCategory(data.original.state);
		let startTime = moment(data.startDate.m);
		let endTime = moment(data.endDate.m);
		let duration = moment.duration(endTime.diff(startTime));

		let hours = parseInt(duration.asHours());
		let minutes = parseInt(duration.asMinutes()) - hours * 60;
		const auxDay = data.startDate.toString().split(' ');
		const lessThan2hourAndHalf = hours == 2 && minutes < 30;
		const moreThan2hourAndHalf = hours == 2 && minutes >= 30;
		const lessThan2hours = hours < 2;
		const lessThan1hourAndHalf = hours == 1 && minutes < 30;
		const lessThan1hour = hours < 1;
		const isAbsence =
			XTRACKER_ABSENCE_PROJECTS.includes(data.original.accountProjectId) && !isNaN(data.original.id);

		const abreviatedCostCenter = data?.original?.costCenterName?.includes('Remote')
			? data?.original?.costCenterName?.replace(/Remote/g, 'RMT')
			: data?.original?.costCenterName?.includes('Braga')
			? data?.original?.costCenterName?.replace(/Braga/g, 'BRG')
			: data?.original?.costCenterName?.includes('Viana')
			? data?.original?.costCenterName?.replace(/Viana do Castelo/g, 'VC')
			: data?.original?.costCenterName?.includes('London')
			? data?.original?.costCenterName?.replace(/London/g, 'LON')
			: data?.original?.costCenterName?.includes('Lisboa')
			? data?.original?.costCenterName?.replace(/Lisboa/g, 'LX')
			: data?.original?.costCenterName?.includes('Porto')
			? data?.original?.costCenterName?.replace(/Porto/g, 'POR')
			: data?.original?.costCenterName?.includes('Customer')
			? data?.original?.costCenterName?.replace(/Customer/g, 'CUST')
			: data?.original?.costCenterName;
		// Check if hours and minutes are valid numbers
		if (isNaN(hours)) hours = 0;
		if (isNaN(minutes)) minutes = 0;

		switch (true) {
			case lessThan1hour:
				// less than 1 hour
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row" justifyContent="space-between">
								{isAbsence ? (
									<>
										<Grid item xs={8.5}>
											<div className="md-custom-event-details-leftOfActions">
												<div className="md-custom-event-title-1line-ellipsis">
													{data.title == 'New event' ? 'New Time Entry' : data.title}
												</div>
												<div className={classes.customEventTitle2_OneLineEllipsis}>
													{data.original.projectTaskName}
												</div>
												<div className="md-custom-event-time">
													{hours}h {minutes.toString().padStart(2, '0')}m{' '}
												</div>
											</div>
										</Grid>
										<Grid item style={{ marginLeft: '-35px' }} xs={1}>
											<IconButton onClick={e => setOpenAttachModal(true)}>
												<AttachFile />
											</IconButton>
										</Grid>
									</>
								) : (
									<Grid item xs={9}>
										<div className="md-custom-event-details-leftOfActions">
											<div className="md-custom-event-title-1line-ellipsis">
												{data.title == 'New event' ? 'New Time Entry' : data.title}
											</div>
											<div className={classes.customEventTitle2_OneLineEllipsis}>
												{data.original.projectTaskName}
											</div>
											<div className="md-custom-event-time">
												{hours}h {minutes.toString().padStart(2, '0')}m{' '}
											</div>
										</div>
									</Grid>
								)}
								<Grid item xs={1}>
									<IconButton
										onMouseEnter={() => setIsIconButtonHovered(true)}
										onMouseLeave={() => setIsIconButtonHovered(false)}
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
						</div>
					</div>
				);
			case lessThan1hourAndHalf:
				// less than 1 hour and half
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row" justifyContent="space-between">
								{isAbsence ? (
									<>
										<Grid item xs={8.5}>
											<div className="md-custom-event-details-leftOfActions">
												<div className="md-custom-event-title-2lines-ellipsis">
													{data.title == 'New event' ? 'New Time Entry' : data.title}
												</div>
												<div className={classes.customEventTitle2_OneLineEllipsis}>
													{data.original.projectTaskName}
												</div>

												<div className="md-custom-event-time">
													{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''}
													{hours}h {minutes.toString().padStart(2, '0')}m{' '}
												</div>
											</div>
										</Grid>
										<Grid item style={{ marginLeft: '-30px' }} xs={1}>
											<IconButton onClick={e => setOpenAttachModal(true)}>
												<AttachFile />
											</IconButton>
										</Grid>
									</>
								) : (
									<Grid item xs={9}>
										<div className="md-custom-event-details-leftOfActions">
											<div className="md-custom-event-title-2lines-ellipsis">
												{data.title == 'New event' ? 'New Time Entry' : data.title}
											</div>
											<div className={classes.customEventTitle2_OneLineEllipsis}>
												{data.original.projectTaskName}
											</div>
											<div className="md-custom-event-time">
												{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''}
												{hours}h {minutes.toString().padStart(2, '0')}m{' '}
											</div>
										</div>
									</Grid>
								)}
								<Grid item xs={1}>
									<IconButton
										onMouseEnter={() => setIsIconButtonHovered(true)}
										onMouseLeave={() => setIsIconButtonHovered(false)}
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
						</div>
					</div>
				);
			case lessThan2hours:
				// less than 2 hours
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row" justifyContent="space-between">
								{isAbsence ? (
									<>
										<Grid item xs={9}>
											<div className="md-custom-event-details-leftOfActions">
												<div className="md-custom-event-title-3lines-ellipsis">
													{data.title == 'New event' ? 'New Time Entry' : data.title}
												</div>
												<div className={classes.customEventTitle2_OneLineEllipsis}>
													{data.original.projectTaskName}
												</div>
												<div className="md-custom-event-time">
													{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''} {hours}h{' '}
													{minutes.toString().padStart(2, '0')}m{' '}
												</div>
											</div>
										</Grid>
										<Grid item style={{ marginLeft: '-35px' }} xs={1}>
											<IconButton onClick={e => setOpenAttachModal(true)}>
												<AttachFile />
											</IconButton>
										</Grid>
									</>
								) : (
									<Grid item xs={9}>
										<div className="md-custom-event-details-leftOfActions">
											<div className="md-custom-event-title-3lines-ellipsis">
												{data.title == 'New event' ? 'New Time Entry' : data.title}
											</div>
											<div className={classes.customEventTitle2_OneLineEllipsis}>
												{data.original.projectTaskName}
											</div>
											<div className="md-custom-event-time">
												{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''}
												{hours}h {minutes.toString().padStart(2, '0')}m{' '}
											</div>
										</div>
									</Grid>
								)}
								<Grid item xs={1}>
									<IconButton
										onMouseEnter={() => setIsIconButtonHovered(true)}
										onMouseLeave={() => setIsIconButtonHovered(false)}
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
						</div>
					</div>
				);
			case lessThan2hourAndHalf:
				// less than 3 hours
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row">
								<Grid item xs={9}>
									{isAbsence && (
										<IconButton onClick={e => setOpenAttachModal(true)}>
											<AttachFile />
										</IconButton>
									)}
								</Grid>
								<Grid item xs={3}>
									<IconButton
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
							<div className="md-custom-event-details-bellowOfActions">
								<div className="md-custom-event-title-3lines-ellipsis">
									{data.title == 'New event' ? 'New Time Entry' : data.title}
								</div>
								<div className={classes.customEventTitle2_TwoLinesEllipsis_MarginTop}>
									{data.original.projectTaskName}
								</div>
								<div className="md-custom-event-time">
									{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''}
									{hours}h {minutes.toString().padStart(2, '0')}m{' '}
								</div>
							</div>
						</div>
					</div>
				);
			case moreThan2hourAndHalf:
				// less than 3 hours
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row">
								<Grid item xs={9}>
									{isAbsence && (
										<IconButton onClick={e => setOpenAttachModal(true)}>
											<AttachFile />
										</IconButton>
									)}
								</Grid>
								<Grid item xs={3}>
									<IconButton
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
							<div className="md-custom-event-details-bellowOfActions">
								<div className="md-custom-event-title-3lines-ellipsis">
									{data.title == 'New event' ? 'New Time Entry' : data.title}
								</div>
								<div className={classes.customEventTitle2_TwoLinesEllipsis_MarginTop}>
									{data.original.projectTaskName}
								</div>
								<div className="md-custom-event-time">
									{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''}
									{hours}h {minutes.toString().padStart(2, '0')}m{' '}
								</div>
							</div>
						</div>
					</div>
				);

			default:
				// Bigger than 3 hours
				return (
					<div className="md-custom-event-cont" style={{ borderTop: '5px solid ' + cat.color }}>
						<div className="md-custom-event-wrapper">
							<Grid container direction="row">
								<Grid item xs={9}>
									{isAbsence && (
										<IconButton onClick={e => setOpenAttachModal(true)}>
											<AttachFile />
										</IconButton>
									)}
								</Grid>
								<Grid item xs={3}>
									<IconButton
										onMouseEnter={() => setIsIconButtonHovered(true)}
										onMouseLeave={() => setIsIconButtonHovered(false)}
										onClick={handleClickEventAction}
										id="actionsMenu"
										className={classes.eventActions}>
										<MoreVert />
									</IconButton>
								</Grid>
							</Grid>
							<div className="md-custom-event-details-bellowOfActions">
								<div className="md-custom-event-title">
									{data.title == 'New event' ? 'New Time Entry' : data.title}
								</div>
								<div className={classes.customEventTitle2_TwoLinesEllipsis_MarginTop}>
									{data.original.projectTaskName}
								</div>
								<div className="md-custom-event-time">
									{abreviatedCostCenter ? abreviatedCostCenter + ' - ' : ''} {hours}h{' '}
									{minutes.toString().padStart(2, '0')}m{' '}
								</div>
							</div>
						</div>
					</div>
				);
		}
	}, []);
	//Checks if the day of the customer header is an holiday
	const checkDayIsHoliday = date => {
		const weekDay = new Date(date);
		const allUserHolidays = weekViewData?.results?.vueAccountEmployeeHolidayDetails;
		//Run all holidays that the user has and if the current week day of the calendar is an holiday then return true, otherwise returns false
		if (allUserHolidays != undefined) {
			for (let holiday of allUserHolidays) {
				const holidayToDate = new Date(holiday.holidayDate);
				if (holidayToDate.getMonth() == weekDay.getMonth() && holidayToDate.getDate() == weekDay.getDate()) {
					return true;
				}
			}
		}

		return false;
	};

	//Custom header that personalize the indication of days and hours
	const customHeader = useCallback(
		(args: { date: any }) => {
			const date = args.date;
			const auxDay = date.toString().split(' ');
			const isHoliday = checkDayIsHoliday(date);
			return (
				<Card className={clsx(classes.cardBase)}>
					<Grid
						container
						direction="column"
						{...sizes[8]}
						className={classes.columnHeader}
						id={'calendarHeaderID'}>
						<Grid item {...sizes[4]} className={classes.topGrid}>
							<Grid
								container
								direction="row"
								columnSpacing={{ xs: 5, sm: 5, md: isRightBarOpen ? 1 : 4 }}
								justifyContent="space-between"
								alignItems="center">
								<Grid item xs={5}>
									<Grid container direction="column">
										<Grid item {...sizes[6]}>
											<Typography className={classes.weekDay}>
												{formatDate('DDDD', date)}
											</Typography>
										</Grid>
										<Grid item {...sizes[6]}>
											<Typography className={classes.dayMonth}>
												{formatDate('DD/MM', date)}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={isRightBarOpen ? 5 : 3} style={{ marginBottom: '-45px' }}>
									{isHoliday && <Palm></Palm>}
								</Grid>
							</Grid>
						</Grid>
						<Grid item {...sizes[8]} className={classes.bottomGrid}>
							<Grid item {...sizes[6]}>
								<Typography className={classes.hoursClass}>Hours</Typography>
							</Grid>
							<Grid item {...sizes[6]}>
								{auxDay[0] === 'Sun' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Sun'])}h{' '}
										{getPossibleMinutes(dailySums['Sun']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Mon' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Mon'])}h{' '}
										{getPossibleMinutes(dailySums['Mon']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Tue' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Tue'])}h{' '}
										{getPossibleMinutes(dailySums['Tue']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Wed' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Wed'])}h{' '}
										{getPossibleMinutes(dailySums['Wed']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Thu' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Thu'])}h{' '}
										{getPossibleMinutes(dailySums['Thu']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Fri' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Fri'])}h{' '}
										{getPossibleMinutes(dailySums['Fri']).toString().padStart(2, '0')}m
									</Typography>
								)}
								{auxDay[0] === 'Sat' && (
									<Typography className={classes.sumHoursClass}>
										{Math.floor(dailySums['Sat'])}h{' '}
										{getPossibleMinutes(dailySums['Sat']).toString().padStart(2, '0')}m
									</Typography>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Card>
			);
		},
		[weekSum, dailySums, mergedArray, hasChanged, currentToggle]
	);

	//Custom header that personalize the indication of days and hours
	const customHeaderDay = useCallback(
		(args: { date: any }) => {
			const date = args.date;
			const auxDay = date.toString().split(' ');

			const isHoliday = checkDayIsHoliday(date);

			const targetDay = selectedDate?.toString().slice(0, 3);

			if (auxDay[0] === targetDay) {
				return (
					<Card className={clsx(classes.cardBase)}>
						<Grid container direction="column" {...sizes[8]} className={classes.columnHeader}>
							<Grid item {...sizes[4]} className={classes.topGrid}>
								<Grid
									container
									direction="row"
									columnSpacing={{ xs: 5, sm: 5, md: isRightBarOpen ? 1 : 4 }}
									justifyContent="space-between"
									alignItems="center">
									<Grid item xs={5}>
										<Grid container direction="column">
											<Grid item {...sizes[6]}>
												<Typography className={classes.weekDay}>
													{formatDate('DDDD', date)}
												</Typography>
											</Grid>
											<Grid item {...sizes[6]}>
												<Typography className={classes.dayMonth}>
													{formatDate('MM/DD', date)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={isRightBarOpen ? 5 : 3} style={{ marginBottom: '-45px' }}>
										{isHoliday && <Palm></Palm>}
									</Grid>
								</Grid>
							</Grid>
							<Grid item {...sizes[8]} className={classes.bottomGridDay}>
								<Grid item {...sizes[6]}>
									<Typography className={classes.hoursClass}>Hours</Typography>
								</Grid>
								<Grid item {...sizes[6]}>
									<Typography className={classes.sumHoursClassDay}>
										{Math.floor(dailySums[date.toLocaleDateString('en-US', { weekday: 'short' })])}h{' '}
										{getPossibleMinutes(
											dailySums[date.toLocaleDateString('en-US', { weekday: 'short' })]
										)}
										m
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Card>
				);
			} else {
				return <Card className={clsx(classes.emptyCardBase)}></Card>; // Return null for other days to hide the card
			}
		},
		[weekSum, dailySums, mergedArray, hasChanged]
	);

	// Load popup form whenever it is needed
	const loadPopupForm = React.useCallback(event => {
		setEventID(event.id);
		setEventTitle(event.title);
		setEventDescription(event.description);
		setEventDate([event.start, event.end]);
	}, []);

	const onEventCreated = React.useCallback(
		args => {
			// Check if the event crosses dates and adjust if necessary
			const eventStart = new Date(args.event.start);
			const eventEnd = new Date(args.event.end);

			if (eventStart.toDateString() !== eventEnd.toDateString()) {
				addNotification('info', 'Time entry cannot have multiple dates!');

				// Remove the event by filtering it out from mergedArray
				setMergedArray(prevEvents => prevEvents.filter(event => event.id !== args.event.id));

				// Return early to prevent further execution
				return;
			}
			setEdit(false);
			setTempEvent({
				id: args.event.id,
				title: args.event.title,
				start: moment(args.event.start).utcOffset(0, true).format(),
				end: moment(args.event.end).utcOffset(0, true).format(),
				describe: 'Hello'
			});
			// fill popup form with event data
			loadPopupForm({
				id: args.event.id,
				title: 'TESTE',
				start: moment(args.event.start).utcOffset(0, true).format(),
				end: moment(args.event.end).utcOffset(0, true).format(),
				describe: 'Hello'
			});
			setAnchor(args.target);
			// open the popup
			setOpen(true);
		},
		[loadPopupForm]
	);

	const hasOverlap = useCallback((args, inst) => {
		const event = args.event;
		const events = inst.getEvents(event.start, event.end).filter(e => e.id !== event.id);
		return events.length > 0;
	}, []);

	const onEventCreate = useCallback((args, inst) => {
		if (hasOverlap(args, inst)) {
		}
	}, []);

	const onEventUpdate = useCallback((args, inst) => {
		setEventUpdatedWithDrag(args.event);
		setChangesMade(true);
		setHasUpdateWithDrag(true);
	}, []);

	const onEventUpdated = useCallback((args, inst) => {
		const { event, oldEvent } = args;

		setChangesMade(true);
		// Cannot update Submitted or Approved time entries
		if (event.state === 2 || event.state === 3) {
			event.start = oldEvent.start;
			event.end = oldEvent.end;
			const updatedEvents = inst._events.filter(e => e.id !== oldEvent.id).concat(oldEvent);
			inst.setEvents(updatedEvents);

			addNotification('info', 'Submitted or Approved Time Entries cannot be changed!');
			setChangesMade(false);
		}
	}, []);

	//Saves the event locally (just saves on db in case of copy to other timesheet)
	const saveEvent = React.useCallback(
		(payloadForm: any) => {
			// Create Date objects for the start and end of the opened event
			const startDateEvent = isEdit ? new Date(eventInformation?.start) : new Date(popupEventDate[0]);
			const endDateEvent = isEdit ? new Date(eventInformation?.end) : new Date(popupEventDate[1]);

			if (!isEdit) {
				// If it's a new time entry, adjust start date to last position of entries in that day
				startDateEvent.setHours(
					Math.floor(
						dailySumsWithRounded30mins[startDateEvent.toLocaleDateString('en-GB', { weekday: 'short' })]
					)
				);
				startDateEvent.setMinutes(
					getPossibleMinutes(
						dailySumsWithRounded30mins[startDateEvent.toLocaleDateString('en-GB', { weekday: 'short' })]
					)
				);
				endDateEvent.setHours(startDateEvent.getHours() + payloadForm.hours);
				endDateEvent.setMinutes(startDateEvent.getMinutes() + payloadForm.minutes);
			} else {
				// If it's an edit, entry starts in the same hour and calculate end date based on event duration
				endDateEvent.setHours(startDateEvent.getHours() + payloadForm.hours);
				endDateEvent.setMinutes(startDateEvent.getMinutes() + payloadForm.minutes);
			}
			// Define the properties of the opened event
			let newEvents: ITimeEntry[] = [];
			const id = payloadForm.id ? payloadForm?.id : tempEvent?.id;
			const costCenter = weekViewData?.results?.costCenters?.find(
				e => e.accountCostCenterId == payloadForm.costCenter
			);
			const workType = weekViewData?.results?.workTypes?.find(e => e.accountWorkTypeId == payloadForm.workType);
			const dateString = format(startDateEvent, 'yyyy-MM-dd');
			const newEvent: ITimeEntry = {
				id: id,
				title: payloadForm.assignedAccountProjectDetails.projectName,
				timeEntryDate: format(startDateEvent, 'yyyy-MM-dd'),
				projectName: payloadForm.assignedAccountProjectDetails.projectName,
				projectTaskName:
					payloadForm?.assignedProjectTaskDetails?.taskName ||
					payloadForm?.assignedTimeOffType?.accountTimeOffType,
				accountTypeOffType: payloadForm?.assignedTimeOffType?.accountTimeOffType,
				accountTypeOffTypeId: payloadForm?.assignedTimeOffType?.accountTimeOffTypeId,
				description: payloadForm.description || '',
				start: startDateEvent.toISOString(),
				end: endDateEvent.toISOString(),
				accountProjectId: payloadForm.projectName,
				projectCode: payloadForm.assignedAccountProjectDetails.projectCode,
				accountProjectTaskId: !XTRACKER_ABSENCE_PROJECTS.includes(payloadForm.projectName)
					? payloadForm.projectTask
					: undefined,
				projectTaskCode: payloadForm?.assignedProjectTaskDetails?.taskCode,
				accountCostCenterId: payloadForm.costCenter,
				isTimeOff: XTRACKER_ABSENCE_PROJECTS.includes(payloadForm.projectName),
				accountWorkTypeId: payloadForm.workType,
				hours: payloadForm.hours,
				minutes: payloadForm.minutes,
				accountEmployeeId: weekViewData?.results?.viewAccountEmployees?.accountEmployeeId,
				isCopiedTimeEntry: false,
				costCenterName: costCenter?.accountCostCenterName,
				workTypeName: workType?.accountWorkTypeName,
				totalTime:
					dateString +
					' ' +
					payloadForm.hours.toString().padStart(2, '0') +
					':' +
					payloadForm.minutes.toString().padStart(2, '0') +
					':' +
					'00'
			};
			setDateToMoveAfterCopy(startDateEvent);

			// Check if the entry spans multiple dates
			const startDate = new Date(newEvent.start);
			const endDate = new Date(newEvent.end);
			startDate.setHours(0, 0, 0);
			endDate.setHours(0, 0, 0);
			const entrySpansMultipleDates = startDate.getTime() != endDate.getTime();

			if (entrySpansMultipleDates) {
				addNotification('info', 'Time entry cannot have multiple dates!');
			}

			// Check if it's an edit operation or a new event creation
			if (isEdit) {
				const index = mergedArray.findIndex(x => x.id == id);
				const newEventList = [...mergedArray];
				// Replace the old event with the new event
				newEventList.splice(index, 1, newEvent);
				if (!entrySpansMultipleDates) {
					// Helps to update dailySumsWithRounded30mins value
					const updatedDailySumsAux = {
						Sun: 0,
						Mon: 0,
						Tue: 0,
						Wed: 0,
						Thu: 0,
						Fri: 0,
						Sat: 0
					};
					newEventList.map(event => {
						const helperHours = dateDifferenceHelper(event.start, event.end);
						const adjustedDate = adjustStartDateAndEndDateForEventsWithLessThan30mins(
							updatedDailySumsAux,
							event.start,
							event.end,
							event.totalTime
						);
						event.start = adjustedDate.start;
						event.end = adjustedDate.end;
					});

					setMergedArray(newEventList);
				}
			} else {
				if (!entrySpansMultipleDates) {
					// Add the new event to the list of new events
					newEvents.push(newEvent);
				}
			}

			if (payloadForm.isCopySelected) {
				if (!entrySpansMultipleDates) {
					// Add(if current week) or Save(if other week) new TimeEntries for each selected date when copying
					newEvents = copyOnModal(payloadForm.selectedDatesOnCopy, newEvents, newEvent);
				}
			}

			if (isToMove) moveModalMove(isEdit ? eventInformation : newEvent);
			// Update mergedArray with the new changes
			setMergedArray(prevMergedArray => [...prevMergedArray, ...newEvents]);

			setHasChanged(true);
			setSelectedDateTODO(popupEventDate[0]);

			// Set changesMade flag to true for Save Warning implemented this week
			setChangesMade(true);
			setEdit(false);
			setOpen(false);
		},
		[isEdit, mergedArray, popupEventDate, popupEventDescription, popupEventTitle, tempEvent, isToMove, dateToMove]
	);

	//Prepares the necessary data base on the event to show on the tooltip
	const prepareToolTipData = e => {
		if (!isIconButtonHovered) {
			setIsOpenToolTip(true);
			setAnchor(e.domEvent.target);
			//Obter dados do evento
			//setStatusToolTip(e.)
			setStatusToolTip(
				e.event.state == 3
					? 'Approved'
					: e.event.state == 2
					? 'Submitted'
					: e.event.state == 4
					? 'Rejected'
					: 'Not Submitted'
			);
			setProjectToolTip(e.event.projectName || e.event.title);
			setProjectTaskToolTip(e.event.projectTaskName);
			setCostCenterName(e.event.costCenterName);
			let startTime = moment(e.event.start);
			let endTime = moment(e.event.end);
			let duration = moment.duration(endTime.diff(startTime));

			let hours = parseInt(duration.asHours());
			let minutes = parseInt(duration.asMinutes()) - hours * 60;

			setHoursToolTip(hours + 'h ' + minutes.toString().padStart(2, '0') + 'm');
		}
	};

	// Function to copy events to selected dates
	const copyOnModal = (selectedDatesParam: any[], newEvents: ITimeEntry[], newEvent = {}) => {
		let otherWeeksEvents: ITimeEntry[] = [];
		// Check if there are selected dates
		if (selectedDatesParam?.length > 0 || selectedDatesParam != null) {
			// Use the provided newEvent or fallback to eventInformation
			const eventAux = Object.keys(newEvent).length === 0 ? eventInformation : newEvent;
			for (let i = 0; i < selectedDatesParam.length; i++) {
				// Create Date objects for the copied event
				const date = selectedDatesParam[i];
				const startDateCopyEvent = new Date(date);
				const endDateCopyEvent = new Date(date);

				// Check if the day belongs to another week
				const dayBelongsToOtherWeek =
					startDateCopyEvent < new Date(weekViewData?.results?.periodStartDate) ||
					startDateCopyEvent > new Date(weekViewData?.results?.periodEndDate);

				// Adjust start and end dates if the day belongs to another week
				if (dayBelongsToOtherWeek) {
					startDateCopyEvent.setHours(new Date(eventAux?.start).getHours() + 1);
					startDateCopyEvent.setMinutes(new Date(eventAux?.start).getMinutes());
					endDateCopyEvent.setHours(new Date(eventAux?.end).getHours() + 1);
					endDateCopyEvent.setMinutes(new Date(eventAux?.end).getMinutes());
				} else {
					// Adjust start date to last position of entries in that day
					startDateCopyEvent.setHours(
						Math.floor(
							dailySumsWithRounded30mins[
								startDateCopyEvent.toLocaleDateString('en-US', { weekday: 'short' })
							]
						)
					);
					startDateCopyEvent.setMinutes(
						getPossibleMinutes(
							dailySumsWithRounded30mins[
								startDateCopyEvent.toLocaleDateString('en-US', { weekday: 'short' })
							]
						)
					);
					// Calculate end date based on event duration

					if (eventAux.hours != null && eventAux.minutes != null) {
						endDateCopyEvent.setHours(startDateCopyEvent.getHours() + eventAux.hours);
						endDateCopyEvent.setMinutes(startDateCopyEvent.getMinutes() + eventAux.minutes);
					} else if (eventAux.hours != null && eventAux.minutes == null) {
						const hoursMinutes = decimalToHours(eventAux.hours);
						endDateCopyEvent.setHours(startDateCopyEvent.getHours() + hoursMinutes.hours);
						endDateCopyEvent.setMinutes(startDateCopyEvent.getMinutes() + hoursMinutes.minutes);
					}
				}
				const diffHours = calculateDiferenceHoursMinutes(startDateCopyEvent, endDateCopyEvent);

				//Total time with the date to move
				let newTotalTime = new Date(eventAux?.totalTime);
				newTotalTime.setFullYear(new Date(date).getFullYear());
				newTotalTime.setMonth(new Date(date).getMonth());
				newTotalTime.setDate(new Date(date).getDate());
				// Define the properties of the copied event
				const newCopyEvent: ITimeEntry = {
					// Set temporary id to prevent collisions

					title: eventAux?.title,
					projectTaskName: eventAux?.projectTaskName,
					description: eventAux?.description || '',
					start: startDateCopyEvent.toISOString(),
					end: endDateCopyEvent.toISOString(),
					accountProjectId: eventAux?.accountProjectId,
					accountProjectTaskId: eventAux?.accountProjectTaskId,
					accountCostCenterId: eventAux?.accountCostCenterId,
					accountWorkTypeId: eventAux?.accountWorkTypeId,
					costCenterName: eventAux?.costCenterName,
					workTypeName: eventAux?.workTypeName,
					hours: diffHours.hours,
					minutes: diffHours.minutes,
					accountEmployeeId: eventAux?.accountEmployeeId,
					isCopiedTimeEntry: true,
					totalTime: format(new Date(newTotalTime), 'yyyy-MM-dd HH:mm:ss')
				};

				// Save copied entry on db if date belongs to other week
				if (dayBelongsToOtherWeek) {
					otherWeeksEvents.push(newCopyEvent);
				} else {
					// If copied entry date belongs current week, just pushes to local array of entries
					newEvents.push(newCopyEvent);
					// Set changesMade flag to true for Save Warning implemented this week
					setChangesMade(true);
				}
			}
			copySave(otherWeeksEvents);
			copyModalClose();
		} else {
			addNotification('danger', 'Please select at least a date to copy the time entry!');
		}
		return newEvents;
	};

	const moveModalMove = (newEvent = {}) => {
		// Auxiliar start and end dates of the initial week to restore data in case of error
		const auxOriginalStartWeekDay = startOfWeek(new Date(eventInformation?.timeEntryDate), { weekStartsOn: 1 }); // 1 represents monday
		auxOriginalStartWeekDay.setHours(originalStartWeekDay.getHours() + 1);
		setOriginalStartWeekDay(auxOriginalStartWeekDay);
		// Gets the final day of the week
		const auxOriginalEndWeekDay = endOfWeek(new Date(eventInformation?.timeEntryDate), { weekStartsOn: 1 }); // 1
		setOriginalEndWeekDay(auxOriginalEndWeekDay);

		if (dateToMove) {
			//Save event in aux variable
			let eventAux = Object.keys(newEvent).length === 0 ? eventInformation : newEvent;
			let toDateMove = new Date(dateToMove);
			//Changes the current start date of the event the the date that will be moved
			let eventStartDate = new Date(eventAux.start);
			eventStartDate.setDate(toDateMove.getDate());
			eventStartDate.setMonth(toDateMove.getMonth());
			eventStartDate.setFullYear(toDateMove.getFullYear());
			eventStartDate.setHours(eventStartDate.getHours(), eventStartDate.getMinutes());

			//Changes the current end date of the event the the date that will be moved
			let eventEndDate = new Date(eventAux.end);
			eventEndDate.setDate(toDateMove.getDate());
			eventEndDate.setMonth(toDateMove.getMonth());
			eventEndDate.setFullYear(toDateMove.getFullYear());
			eventEndDate.setHours(eventEndDate.getHours(), eventEndDate.getMinutes());

			//Change totalTime date with new moved date and total hours of the time entry
			let eventTotalTime = new Date();
			eventTotalTime.setDate(toDateMove.getDate());
			eventTotalTime.setMonth(toDateMove.getMonth());
			eventTotalTime.setFullYear(toDateMove.getFullYear());
			if (typeof eventAux.id == 'string') {
				eventTotalTime.setHours(eventAux.hours, eventAux.minutes ? eventAux.minutes : 0, 0, 0);
			} else {
				const time = calculateDiferenceHoursMinutes(eventStartDate, eventEndDate);
				eventTotalTime.setHours(time.hours, time.minutes, 0, 0);
			}
			//updates the event with the updated hours
			eventAux!.start = format(eventStartDate, 'yyyy-MM-dd HH:mm:ss');
			eventAux!.end = format(eventEndDate, 'yyyy-MM-dd HH:mm:ss');
			eventAux!.totalTime = format(eventTotalTime, 'yyyy-MM-dd HH:mm:ss');
			eventAux!.timeEntryDate = format(eventTotalTime, 'yyyy-MM-dd');

			setOpenModalTimeEntryMover(false);

			//  Gets the initial date of the week
			const startWeekDay = startOfWeek(toDateMove, { weekStartsOn: 1 }); // 1 represents monday
			startWeekDay.setHours(startWeekDay.getHours() + 1);

			// Gets the final day of the week
			const endWeekDay = endOfWeek(toDateMove, { weekStartsOn: 1 }); // 1

			moveTimeEntry({
				timeEntry: eventAux,
				employeeId: weekViewData?.results?.viewAccountEmployees?.accountEmployeeId,
				startDate: startWeekDay,
				endDate: endWeekDay
			});
		} else {
			addNotification('danger', 'Please select a date to move the time entry!');
		}
	};

	//Change the week to where the time entry was moved
	useEffect(() => {
		if (isMoved && isMoved.includes('successfully') && dateToMove) {
			let toDateMove = new Date(dateToMove);
			//  Gets the initial date of the week
			const startWeekDay = startOfWeek(toDateMove, { weekStartsOn: 1 }); // 1 represents monday
			startWeekDay.setHours(startWeekDay.getHours() + 1);
			// Gets the final day of the week
			const endWeekDay = endOfWeek(toDateMove, { weekStartsOn: 1 }); // 1
			setLoadAfterMove(true);

			getWeekViewData({
				username: filterUser,
				periodStartDate: startWeekDay,
				periodEndDate: endWeekDay
			});
			// Reset changesMade state
			setChangesMade(false);
			setSelectedDate(new Date(dateToMove));
			setDateToMove(undefined);
			setMoveTimeEntryMessage(null);
			setIsToMove(false);
			setDeleteMoveTimeEntryMessage();
		}
		if (isMoved && isMoved.includes('error')) {
			getWeekViewData({
				username: filterUser,
				periodStartDate: originalStartWeekDay,
				periodEndDate: originalEndWeekDay
			});
			setDateToMove(undefined);
			setDeleteMoveTimeEntryMessage();
			setIsToMove(false);
		}
	}, [isMoved, loading, dateToMove]);

	const confirmActionsDelete = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setConfirmDeleteModal(false)
			// disabled: isDifferentUserOpened
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setChangesMade(true);
				deleteEvent(popupEventID);
				setConfirmDeleteModal(false);
			}
			// disabled: isDifferentUserOpened
		}
	];

	const submitTimesheetActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setSaveSubmitUnSubmitTimesheetModal(false);
			}
			// disabled: isDifferentUserOpened
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				if (isSave) {
					saveTimesheetData('');
				} else {
					if (isSubmit) {
						saveTimesheetData('Submit');
					} else {
						saveTimesheetData('UnSubmit');
					}
				}
				setSaveSubmitUnSubmitTimesheetModal(false);
			}
			// disabled: isDifferentUserOpened
		}
	];

	const copyTimesheetActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setOpenCopyTimesheetModal(false);
			}
			// disabled: isDifferentUserOpened
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				loadTimesheetForCopy(selectedDateForCopy.toISOString());
				setChangesMade(true);
				setOpenCopyTimesheetModal(false);
			}
			// ,disabled: isDifferentUserOpened
		}
	];

	//Actions for attach modal
	const attachModalAction = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setInputValue('');
				setOpenAttachModal(false);
			}
		}
	];

	//Actions for Save Warning
	const saveWarningActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setShowSaveWarningModal(false)
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				leaveCurrentTimeSheet(changePageAction);
				// Reset changesMade state
				setChangesMade(false);
				setPeriodDescriptionValue('');
				setShowSaveWarningModal(false);
			}
		}
	];
	//Actions week sum Less Than 40 hours
	const weekSumLessThan40hoursAction = [
		{
			id: 'ok',
			label: 'OK',
			color: 'primary',
			variant: 'contained',
			onClick: () => setWeekSumLessThan40(false)
		}
	];

	// TABLE COMPONENT - columns for attach table
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Id',
				id: 'id',
				width: '5%'
			},
			{
				label: 'Attachment Name',
				id: 'attachmentName',
				width: '25%'
			},
			{
				label: 'File Name',
				id: 'fileName',
				width: '25%'
			}
		],
		[]
	);

	//Actions for attachment table rows
	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'downloadAttachment',
				type: 'button',
				icon: <Download />,
				render: () => true,
				disabled: () => false,
				onClick: row =>
					downloadAttachment({
						timeEntryId: row.timeEntryId,
						employeeId: row.createdByEmployeeId,
						attachmentName: row.attachmentLocalPath
					})
			},
			{
				id: 'deleteAttachment',
				type: 'button',
				icon: <Delete />,
				render: () => true,
				disabled: () => false,
				onClick: row => {
					deleteAttachment({ attachmentId: row.id, timeEntryId: row.timeEntryId });
				}
			}
		],
		[attachList]
	);

	// TABLE DATA - for attachments dialog table
	const tableData = useMemo(
		() =>
			attachList?.results?.listAttachment?.map(e => ({
				...e,
				id: e.timeOffAttachmentId,
				attachmentName: e.attachmentName,
				fileName: e.attachmentLocalPath
			})),
		[attachList]
	);

	//Gets input file
	const changeHandler = event => {
		setInputValue(event.target.value);
		formDataAttachment = new FormData();
		formDataAttachment.append('attachment', event.target.files[0]);
	};

	//Adds a attachment to time entry
	const saveAttachment = () => {
		if (formDataAttachment.has('attachment')) {
			const fileSize = formDataAttachment.get('attachment');
			const MaxFileSize = 4097000;
			if (fileSize.size <= MaxFileSize) {
				formDataAttachment.append('sessionEmployeeId', eventInformation.accountEmployeeId);
				formDataAttachment.append('timeEntryId', eventInformation.id);
				addAttach(formDataAttachment);
				formDataAttachment = new FormData();
				setInputValue('');
			} else {
				addNotification('danger', 'File size exceeds the maximum limit (4Mb).');
			}
		} else {
			addNotification('danger', 'Please insert a file.');
		}
	};

	useEffect(() => {
		if (openAttachModal) attachmentList({ timeEntryId: eventInformation?.accountEmployeeTimeEntryId });
	}, [openAttachModal]);

	const confirmActionsApprovalDetails = [
		{
			id: 'close',
			label: 'Close',
			color: 'primary',
			variant: 'contained',
			onClick: () => setOpenApprovalDetailsModal(false)
		}
	];

	const tableColumnHeaders: ITableColumn<any>[] = [
		{
			label: 'Approver Name',
			id: 'approverName',
			width: '10%'
		},
		{
			label: 'Approved On',
			id: 'approvedOn',
			width: '10%',
			format: (row, text: string) => (text ? text.split(' ')[0] : '')
		},
		{
			label: 'Status',
			id: 'status',
			width: '10%'
		},
		{
			label: 'Comments',
			id: 'comments',
			width: '35%'
		},
		{
			label: 'Projects',
			id: 'projects',
			width: '35%'
		}
	];

	/**
	 * Get the photo URL for the user when the timesheet belongs to another employee.
	 * Returns the photo URL or an empty string if no employee code is available.
	 */
	const getPhoto = () => {
		if (weekViewData?.results?.viewAccountEmployees?.employeeCode) {
			return `${START_API_PATH}/admin/users/${weekViewData?.results?.viewAccountEmployees?.employeeCode}/photo`;
		} else {
			return '';
		}
	};

	const moveModalClose = () => {
		setOpenModalTimeEntryMover(false);
		setDateToMove(undefined);
	};

	const copyModalClose = () => {
		setOpenModalTimeEntryCopy(false);
		setSelectedDatesOnCopy([]);
	};

	//	Adjusts position of event in case of having time entries with less than 30 minutws in the same day
	const adjustStartDateAndEndDateForEventsWithLessThan30mins = (
		updatedDailySumsAux: any,
		startDate: string,
		endDate: string,
		totalTime: any
	) => {
		const helperHours = dateDifferenceHelper(startDate, endDate);
		const dayOfWeek = new Date(startDate).toLocaleDateString('en-US', { weekday: 'short' });
		const timeDailySum = decimalToHours(updatedDailySumsAux[dayOfWeek]);

		//	Adjusts event start to the last place of the day (considering entries rounded to 30 min)
		const start = calculateEndTime(
			new Date(startDate).setHours(0, 0, 0, 0),
			timeDailySum.hours,
			timeDailySum.minutes
		).toISOString();
		const [date, time] = totalTime.split(/[ T]/);
		const [hours, minutes] = time.split(':').map(Number);
		const end = calculateEndTime(start, hours, minutes).toISOString();

		//	If event with less than 30 minutes
		if (helperHours < 0.5) {
			updatedDailySumsAux[dayOfWeek] += 0.5;
		} else {
			updatedDailySumsAux[dayOfWeek] += helperHours;
		}

		setDailySumsWithRounded30mins(updatedDailySumsAux);
		return { start, end };
	};

	const handleRequestChangeClick = () => {
		window.open('https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/3/create/24', '_blank');
	};

	//Happens everytime the user has errors in this page
	if (error) {
		return <ErrorPage code={error?.code} message={(error as OriginalResponse)?.payload?.message} />;
	}
	const isLoading =
		weekViewData === null ||
		loading ||
		loadAfterMove ||
		weekViewData === undefined ||
		userGuide === undefined ||
		guide === undefined;
	return (
		<>
			{isLoading && <LoadingOverlay />}
			{isDifferentUserOpened && (
				<BackPage
					label="Return to Timesheet"
					path="/time-tracking/my-timesheets"
					noMarginBottom
					action={refreshPage}
				/>
			)}
			<Grid container direction="row" justifyContent="space-around">
				{userGuide?.data?.userGuide?.active ? (
					<Joyride
						continuous
						callback={handleJoyrideCallback}
						run={run}
						steps={steps}
						hideCloseButton
						showSkipButton
						showProgress
						styles={{
							options: {
								primaryColor: '#3F80EF'
							}
						}}
					/>
				) : (
					''
				)}

				<Grid
					item
					xs={12}
					className={classes.periodDescription}
					style={{ minHeight: '15px', maxHeight: '45px' }}>
					{showPeriodDescription && (
						<div
							className={classes.periodDescription}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							style={{ maxHeight: 'none', width: '85%' }}>
							<Typography ref={anchorDescriptionPeriodRef} className={classes.periodDescription}>
								{periodDescriptionValue}
							</Typography>
						</div>
					)}
				</Grid>
				<Grid
					item
					xs={isRightBarOpen ? (isTablet ? 8.5 : 9) : 11}
					style={{ height: `${height}px`, zIndex: '0' }}
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: isRightBarOpen,
						[classes.drawerClose]: !isRightBarOpen
					})}>
					{currentToggle == 'weekly' ? (
						<Eventcalendar
							height="90%"
							calendarHeight="90%"
							dataTimezone="utc"
							displayTimezone="Europe/London"
							timezonePlugin={momentTimezone}
							ref={schedulerRef}
							theme="windows"
							themeVariant="light"
							renderHeader={customWithNavButtonsGraphChart}
							showEventTooltip={false}
							clickToCreate="single"
							dragToCreate={true}
							dragToMove={true}
							dragToResize={true}
							onEventDragEnd={e => {
								setHasChanged(true);
								if (e.event.accountProjectId) {
									setHasUpdateWithDrag(true);
								}
							}}
							dragTimeStep={30}
							selectedDate={selectedDate}
							onSelectedDateChange={e => {
								selectedDate.setHours(0, 0, 0, 0);
							}}
							renderScheduleEvent={customEvent}
							renderDay={args =>
								currentView === 'day'
									? customHeaderDay({ ...args, hasChanged })
									: customHeader({ ...args, hasChanged })
							}
							onEventCreated={onEventCreated}
							onEventCreate={onEventCreate}
							onEventUpdate={onEventUpdate}
							onEventUpdated={onEventUpdated}
							onEventDoubleClick={e => {
								setIsViewDetails(true);
							}}
							onEventHoverIn={e => {
								setEventID(e.event.id.toString());
								if (e.event.hours <= 2) prepareToolTipData(e);
								setEventInformation(e.event);
							}}
							onEventHoverOut={e => {
								setIsOpenToolTip(false);
							}}
							view={view}
							data={mergedArray}
						/>
					) : (
						<Grid container direction="column">
							<Grid item className={classes.paddingNavBar}>
								{customWithNavButtonsGraphChart()}
							</Grid>
							<Grid item>
								<Grid
									container
									direction="row"
									justifyContent="space-evenly"
									display="block"
									spacing={2}>
									{weekViewData ? (
										<Typography paddingLeft="20px" gutterBottom>
											{format(new Date(weekViewData?.results?.periodStartDate), 'dd-MMMM')} to{' '}
											{format(new Date(weekViewData?.results?.periodEndDate), 'dd-MMMM')}
										</Typography>
									) : (
										''
									)}
									<Grid container direction="row" justifyContent="space-evenly" spacing={2}>
										<Grid item xs={7}>
											{isRightBarOpen && (
												<Pie
													data={chartCardInfo[0]}
													width={800}
													height={500}
													innerRadius={0.8}
													enableArcLabels={false}
													enableArcLinkLabels={false}
													showCenterLegends={chartCardInfo[1] != 0}
													totalProjects={chartCardInfo[2]}
													totalHours={decimalToHours(chartCardInfo[1])}
													rotationOn={false}
													toolTipValueAsPercentage={true}
												/>
											)}
											{!isRightBarOpen && (
												<Pie
													data={chartCardInfo[0]}
													width={900}
													height={600}
													innerRadius={0.8}
													enableArcLabels={false}
													enableArcLinkLabels={false}
													showCenterLegends={chartCardInfo[1] != 0}
													totalProjects={chartCardInfo[2]}
													totalHours={decimalToHours(chartCardInfo[1])}
													rotationOn={false}
													toolTipValueAsPercentage={true}
												/>
											)}
										</Grid>
										<Grid item xs={5}>
											<ChartCard data={chartCardInfo[0]} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid item id={'rightSidebarID'}>
					<RightSidebar
						onClick={openCloseRightSideBar}
						isOpen={isRightBarOpen}
						showMissingTimesheetsIcon={rightBarClosedWithMissingTimesheets}
						headerComponent={missingTimesheetsTypography}
						bodyComponent={rightSideBarXpandedDatePicker}
						footerComponent={missingTimesheetsCards}
					/>
				</Grid>
			</Grid>
			{/* Footer of the page */}
			<Grid
				container
				direction="row"
				id={'footerButtonsID'}
				style={{
					position: 'sticky',
					bottom: '0',
					left: '0',
					backgroundColor: 'white',
					paddingLeft: '30px',
					paddingRight: '30px',
					paddingBottom: '10px',
					paddingTop: '20px'
				}}
				alignItems="center">
				<Grid item xs={8} id={'footerLegendID'}>
					<Subtitles subtitles={subtitles} title="Status Legend:"></Subtitles>
				</Grid>
				<Grid item xs={2}>
					{!weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted &&
						!getLSField('impersonate_userInfo') && (
							<Button
								size="large"
								style={{
									color:
										// isDifferentUserOpened ? 'lightgrey' :
										'black',
									border:
										// isDifferentUserOpened ? '1px solid lightgrey' :
										'1px solid grey',
									borderRadius: '10px',
									width: '150px'
								}}
								onClick={() => {
									if (isDifferentUserOpened) {
										setIsSave(true);
										setIsSubmit(false);
										setSaveSubmitUnSubmitTimesheetModal(true);
									} else {
										saveTimesheetData('');
									}
								}}
								// disabled={isDifferentUserOpened}
							>
								Save <Save style={{ marginLeft: '10px' }} />
							</Button>
						)}
				</Grid>
				{!weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted &&
					!getLSField('impersonate_userInfo') && (
						<Grid item xs={2}>
							<Button
								color="primary"
								size="large"
								variant="contained"
								style={{
									borderRadius: '10px',
									opacity: '0.8',
									width: '150px',
									marginRight: '10px'
								}}
								onClick={() => {
									setIsSave(false);
									setIsSubmit(true);
									if (weekSum < 40) {
										setWeekSumLessThan40(true);
									} else {
										setSaveSubmitUnSubmitTimesheetModal(true);
									}
								}}
								// disabled={isDifferentUserOpened}
							>
								Submit <CheckIcon style={{ marginLeft: '10px' }} />
							</Button>
						</Grid>
					)}
				{weekViewData?.results?.accountEmployeeTimeEntryPeriod?.submitted &&
					!weekViewData?.results?.accountEmployeeTimeEntryPeriod?.approved &&
					!weekViewData?.results?.listTimeEntriesByPeriodId?.some(
						entry => entry.approved === true && entry.isTimeOff === false
					) &&
					!getLSField('impersonate_userInfo') && (
						<Grid item xs={2}>
							<Button
								size="large"
								style={{
									color:
										// isDifferentUserOpened ? 'lightgrey' :
										'black',
									border:
										// isDifferentUserOpened ? '1px solid lightgrey' :
										'1px solid grey',
									borderRadius: '10px',
									width: '150px'
								}}
								onClick={() => {
									setIsSave(false);
									setIsSubmit(false);
									setSaveSubmitUnSubmitTimesheetModal(true);
								}}
								// disabled={isDifferentUserOpened}
							>
								Un-submit <Save style={{ marginLeft: '10px' }} />
							</Button>
						</Grid>
					)}
			</Grid>
			{/* Create/Edit Popup */}
			<NewEntryModal
				isOpen={isOpen}
				setOpen={setOpen}
				deleteEvent={deleteEvent}
				popupEventID={popupEventID}
				saveEvent={saveEvent}
				weekViewData={weekViewData}
				popupEventDate={popupEventDate}
				mergedArray={mergedArray}
				isEdit={isEdit}
				setEdit={setEdit}
				setDateToMove={setDateToMove}
				dateToMove={dateToMove}
				isToMove={isToMove}
				setIsToMove={setIsToMove}
				selectedDatesOnCopy={selectedDatesOnCopy}
				setSelectedDatesOnCopy={setSelectedDatesOnCopy}
				eventInformation={eventInformation}
				selectedDate={selectedDate}
			/>
			<FiltersModal
				setOpenModalFilters={setOpenModalFilters}
				loggedUser={loggedUser.username}
				openModalFilters={openModalFilters}
				filterUser={filterUser}
				setFilterUser={setFilterUser}
				employeesList={employeesList}
				filterUserFullName={filterUserFullName}
				setPeriodStartDate={setPeriodStartDate}
				setPeriodEndDate={setPeriodEndDate}
				loadCurrentPeriodDate={loadCurrentPeriodDate}
				setSelectedDate={setSelectedDate}
				xtrackerRole={xtrackerRole}
				setDifferentUserOpened={setDifferentUserOpened}
				checkChangesBeforeLeaveTimesheet={checkChangesBeforeLeaveTimesheet}
				setSelectedDateForSaveWarning={setSelectedDateForSaveWarning}
				areChangesMade={areChangesMade}
				setChangePageAction={setChangePageAction}
				setShowSaveWarningModal={setShowSaveWarningModal}
				setLoadCurrentPeriodDateForSaveWarning={setLoadCurrentPeriodDateForSaveWarning}
			/>
			{/* Move */}
			<TimeEntryMoverModal
				setOpenModalTimeEntryMover={setOpenModalTimeEntryMover}
				openModalTimeEntryMover={openModalTimeEntryMover}
				event={eventInformation}
				onClose={moveModalClose}
				onCopy={e => moveModalMove()}
				setDateToMove={setDateToMove}
				dateToMove={dateToMove}
				selectedDate={selectedDate}
			/>
			{/* Copy */}
			<TimeEntryCopyModal
				setOpenModalTimeEntryCopy={setOpenModalTimeEntryCopy}
				openModalTimeEntryCopy={openModalTimeEntryCopy}
				event={eventInformation}
				onClose={copyModalClose}
				onCopy={e => {
					const newEvents = copyOnModal(selectedDatesOnCopy, []);
					setMergedArray([...mergedArray, ...newEvents]);
				}}
				selectedDatesOnCopy={selectedDatesOnCopy}
				setSelectedDatesOnCopy={setSelectedDatesOnCopy}
				selectedDate={selectedDate}
			/>
			<Popup
				display="anchored"
				isOpen={isOpenToolTip}
				anchor={anchor}
				touchUi={false}
				showOverlay={false}
				contentPadding={false}
				closeOnOverlayClick={false}
				width={150}
				cssClass="md-tooltip">
				<Grid container direction="column" spacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
					<Grid item className="md-tooltip-header">
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<Badge
									variant="dot"
									className={classes.selectedBadge}
									color={
										statusToolTip == 'Submitted'
											? 'primary'
											: statusToolTip == 'Approved'
											? 'success'
											: statusToolTip == 'Rejected'
											? 'warning'
											: 'warningOrange'
									}
								/>
							</Grid>
							<Grid item>
								<div style={{ marginBottom: '-7px', fontSize: '10px' }}>{statusToolTip}</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item className="md-tooltip-title">
						{projectToolTip}
					</Grid>
					<Grid item className="md-tooltip-text">
						{projectTaskToolTip}
					</Grid>
					<Grid item className="md-tooltip-text">
						{costCenterName}
					</Grid>
					<Grid item className="md-tooltip-info">
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<AccessTimeIcon />
							</Grid>
							<Grid item>
								<div className={classes.hoursToolTip}>{hoursToolTip}</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Popup>
			<Popup
				display="anchored"
				isOpen={isOpenToolTipDescriptionPeriod}
				anchor={anchorDescriptionPeriodRef.current}
				touchUi={false}
				showOverlay={false}
				contentPadding={false}
				closeOnOverlayClick={false}
				// width={150}
				cssClass="md-tooltip-period">
				<Grid container direction="column">
					<Grid item className="md-tooltip-header">
						<Grid container direction="row" spacing={2}>
							<Grid item></Grid>
							<Grid item>
								<div style={{ marginBottom: '-7px', fontSize: '10px' }}>Period Description</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item className="md-tooltip-title-period">
						{periodDescriptionValue}
					</Grid>
				</Grid>
			</Popup>
			<Menu
				id="event-Actions-menu"
				aria-labelledby="event-Actions-menu"
				open={Boolean(anchorElEventAction)}
				anchorEl={anchorElEventAction}
				onClose={handleCloseEventAction}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}>
				{eventActions.map(e => (
					<MenuItem key={`event${e.id}`} onClick={e.onClick} disabled={e.disabled}>
						{e.label}
					</MenuItem>
				))}
			</Menu>
			{/* Dialog for Delete Entry */}
			<Dialog
				modal={{
					open: Boolean(confirmDeleteModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmDeleteModal(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this entry?</Typography>
						</>
					)
				}}
				title="Delete Entry"
				actions={confirmActionsDelete}
				scroll="body"
			/>
			{/* Dialog for the import */}
			<Dialog
				maxWidth="lg"
				fullWidth
				modal={{
					open: Boolean(modalImportOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setModalImportOpen(false);
					},
					content: (
						<form encType="multipart/form-data" id="form-new-file-template">
							<Grid container style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<InfoField label="" value="Select a file to upload" />
									<Input
										onChange={changeHandlerImportTimesheet as unknown as ChangeEventHandler}
										name="file"
										type="file"
									/>
								</Grid>
								<div>
									<Typography align="justify" style={{ marginTop: '30px' }}>
										<span style={{ fontWeight: 'bold' }}>
											You can download the following template to facilitate the import:{' '}
										</span>
										<a
											style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
											onClick={e => downloadImportTemplate()}>
											Template
										</a>
									</Typography>

									<Typography gutterBottom align="justify" style={{ marginTop: '10px' }}>
										<span style={{ color: 'red', fontWeight: 'bold' }}>Notes:</span>
									</Typography>

									<Typography gutterBottom align="justify">
										The modifications should be confirmed by pressing{' '}
										<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Save</span>{' '}
										button,{' '}
										<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
											after import.
										</span>
									</Typography>

									<Typography align="justify">
										Please{' '}
										<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
											use a notepad
										</span>{' '}
										application to{' '}
										<span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
											open the file
										</span>
										. We do not recommend you use Excel as it might affect your data and the import
										result.
									</Typography>
								</div>
							</Grid>
						</form>
					)
				}}
				title="Uploading File"
				actions={newTemplateActions}
				scroll="body"
			/>
			{/* Dialog for the edit period description */}
			<Dialog
				maxWidth="lg"
				fullWidth
				modal={{
					open: Boolean(modalPeriodDescription),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setModalPeriodDescription(false);
					},
					content: (
						<Grid>
							<LibInput
								label="Period Description"
								name="periodDescription"
								required
								multiline
								minRows={4}
								maxRows={8}
								maxLength={2000}
								control={control}
								errors={errors}
							/>
						</Grid>
					)
				}}
				title="Edit Period Description"
				actions={editPeriodDescriptionActions}
				scroll="body"
			/>
			{/*Dialog for view event details*/}
			<ViewDetailsModal
				eventDetails={eventInformation}
				setIsViewDetails={setIsViewDetails}
				isViewDetails={isViewDetails}
			/>
			{/* Dialog for timesheet submittion */}
			<Dialog
				modal={{
					open: Boolean(saveSubmitUnSubmitTimesheetModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setSaveSubmitUnSubmitTimesheetModal(false);
					},
					content: (
						<>
							{isSave && isDifferentUserOpened ? (
								<Typography gutterBottom>
									Are you sure you want to save a timesheet for another user?
								</Typography>
							) : isSubmit ? (
								weekSum > 40 ? (
									isDifferentUserOpened ? (
										<Typography gutterBottom>
											Timesheet has more than 40 hours. Are you sure you want to submit a
											timesheet for another user to approval?
										</Typography>
									) : (
										<Typography gutterBottom>
											Timesheet has more than 40 hours. Are you sure you want to submit your
											timesheet for approval?
										</Typography>
									)
								) : isDifferentUserOpened ? (
									<Typography gutterBottom>
										Are you sure you want to submit a timesheet for another user to approval?
									</Typography>
								) : (
									<Typography gutterBottom>
										Are you sure you want to submit your timesheet for approval?
									</Typography>
								)
							) : isDifferentUserOpened ? (
								<Typography gutterBottom>
									Are you sure you want to un-submit a timesheet for another user?
								</Typography>
							) : (
								<Typography gutterBottom>Are you sure you want to un-submit your timesheet?</Typography>
							)}
						</>
					)
				}}
				title={isSave ? 'Save Timesheet' : isSubmit ? 'Submit Timesheet' : 'Un-submit Timesheet'}
				actions={submitTimesheetActions}
				scroll="body"
			/>
			{/* Dialog for copy timesheet modal */}
			<Dialog
				modal={{
					open: Boolean(openCopyTimesheetModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenCopyTimesheetModal(false);
					},
					content: (
						<>
							<Grid
								container
								direction="column"
								justifyContent="flex-end"
								spacing={6}
								alignItems="center">
								<Grid item xs={1}>
									<Typography gutterBottom>
										Please select the week you want to copy to the current week you're viewing. The
										timeoffs will not be copied.
									</Typography>
								</Grid>
								<Grid item xs={3} style={{ transform: 'scale(1.1)' }}>
									<XpandedDatePicker
										name="exampleDate"
										value={selectedDateForCopy}
										control={control as any}
										errors={errors as any}
										isSideBar={false}
										additionalOnChange={e => {
											setSelectedDateForCopy(new Date(e));
										}}
									/>
								</Grid>
							</Grid>
						</>
					)
				}}
				title={'Copy Timesheet'}
				actions={copyTimesheetActions}
			/>
			{/* Dialog for Time Entry Attachments */}
			<Dialog
				maxWidth="lg"
				fullWidth
				modal={{
					open: Boolean(openAttachModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenAttachModal(false);
					},
					content: (
						<>
							{isLoading && <LoadingOverlay />}
							<Table
								tableData={tableData}
								columns={tableColumns}
								actions={tableActions}
								defaultSortColumn="name"
								handleSearch={{ searchValue, setSearchValue }}
							/>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<CoreInput
									value={inputValue}
									onChange={changeHandler}
									type="file"
									inputProps={{ accept: 'file' }}
								/>
								<Button onClick={saveAttachment} variant="contained" style={{ marginLeft: '20px' }}>
									Save Attachment
								</Button>
							</Grid>
						</>
					)
				}}
				title="Time Entry Attachments"
				actions={attachModalAction}
				scroll="body"
			/>
			{/* Dialog for Approval Details */}
			<Dialog
				maxWidth="lg"
				modal={{
					open: Boolean(openApprovalDetailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenApprovalDetailsModal(false);
					},
					content: (
						<>
							<Typography gutterBottom style={{ marginLeft: 15 }}>
								<b>Submitted By: </b>{' '}
								{weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.submittedBy}
							</Typography>
							<Typography gutterBottom style={{ marginLeft: 15 }}>
								<b>Submitted On: </b>{' '}
								{weekViewData?.results?.vueAccountEmployeeTimeEntryPeriod?.submittedDate}
							</Typography>
							<Table
								hideToolBar={true}
								tableData={approvalDetailsData}
								columns={tableColumnHeaders}
								defaultSortColumn="approvedOn"
								defaultOrder="desc"
								lineless={true}
								abc={true}
							/>
						</>
					)
				}}
				title="Approval Details"
				actions={confirmActionsApprovalDetails}
				scroll="body"
			/>
			{/* Dialog for Save Warning */}
			<Dialog
				modal={{
					open: Boolean(showSaveWarningModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setShowSaveWarningModal(false);
					},
					content: (
						<>
							<Typography gutterBottom>
								Are you sure you want to leave this timesheet without saving?
							</Typography>
						</>
					)
				}}
				title="Save Warning"
				actions={saveWarningActions}
				scroll="body"
			/>
			{/* Dialog for 40 hours warning*/}
			<Dialog
				modal={{
					open: Boolean(weekSumLessThan40),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setSaveSubmitUnSubmitTimesheetModal(false);
					},
					content: <Typography gutterBottom>You may not enter less than 40 hours in a week</Typography>
				}}
				title={'Submit warning'}
				actions={weekSumLessThan40hoursAction}
				scroll="body"
			/>
			<Prompt when={areChangesMade} message="Are you sure you want to leave this timesheet without saving?" />
		</>
	);
};

export default withLayout(MyTimesheets);
