/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import { Dispatch } from 'redux';

import {
	fetchEmailTemplates,
	postChangeEmailTemplateStatus,
	postTestEmailTemplate,
	postNewEmailTemplate,
	fetchFileTemplates,
	postChangeFileTemplateStatus,
	fetchDownloadFileTemplate,
	postUploadFileTemplate,
	fetchCachesList,
	deleteSingleCache,
	deleteAllCaches,
	fetchAccessControlList,
	removeAllRolesAndAccesses,
	fetchAccessControlListAddUser,
	fetchAccessControlEditUser,
	putAccessControl,
	authorizationCodeRenewal,
	deleteAllUserCaches,
	deleteAllSingleUserCaches,
	fetchReleasesList,
	fetchReleaseNotesList,
	fetchReleaseNotesEmail,
	sendReleaseNotesEmail,
	deleteAllCompaniesCaches,
	deleteSingleCacheByName,
	resetAllNewReleasePopups,
	getJobExecutionsList,
	fetchDeleteJobExecution,
	fetchDeleteAllUnfinishedJobExecutions,
	fetchAppRolesList,
	deleteAppRole,
	getAppLinksList,
	deleteApp,
	postAppLink,
	postAddAppLink,
	postAppLinkNoImage,
	fetchJobExecution,
	fetchAllJobs,
	fetchAllGuides,
	postUploadGuides
} from 'lib/api/administration/administration';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	IEmailTemplates,
	INewEmailTemplate,
	IFileTemplates,
	ICachesListInfo,
	IAccessControl,
	IGrantAccessControlInfo,
	IGrantAccessControl,
	IReleaseNotesEmail,
	IJobExecution,
	IAppRoleData,
	IAppRole
} from 'typings/store/admin/administration';

import {
	setLoading,
	setAdministrationError,
	setEmailTemplates,
	setPostChangeEmailTemplateStatus,
	setPostTestEmailTemplate,
	setPostNewEmailTemplate,
	setFileTemplates,
	setPostChangeFileTemplateStatus,
	setDownloadFileTemplate,
	setPostUploadFileTemplate,
	setCachesList,
	setSendDeleteSingleCache,
	setSendDeleteAllCaches,
	setAccessControlList,
	setSendRemoveAllRolesAndAccesses,
	setAccessControlListAddUser,
	setAccessControlEditUser,
	setPutAccessControl,
	setClearEditUser,
	setAuthorizationCodeRenewal,
	setSendDeleteAllUserCaches,
	setSendDeleteAllSingleUserCaches,
	setReleasesList,
	setReleaseNotesList,
	setReleaseNotesEmail,
	setPostReleaseNotesEmail,
	setSendDeleteAllCompaniesCaches,
	setJobExecutions,
	setDeleteJobExecution,
	setAppRoles,
	setDeleteAppRole,
	setAppLinks,
	setAllJobs,
	setAllGuides,
	setPostUploadGuides
} from './actions';
import { setUsersList } from '../users/actions';

export const getEmailTemplates =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IEmailTemplates } | OriginalResponse = await fetchEmailTemplates();

			if (response.data) {
				dispatch(setEmailTemplates(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmailTemplates(null));
		}
	};

export const sendPostChangeEmailTemplateStatus =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postChangeEmailTemplateStatus(payload);
			const newData: { data?: IEmailTemplates } = await fetchEmailTemplates();
			dispatch(setPostChangeEmailTemplateStatus(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setPostChangeEmailTemplateStatus(null, null));
		}
	};

export const sendPostTestEmailTemplate =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postTestEmailTemplate(payload);
			dispatch(setPostTestEmailTemplate(response?.data || null));
		} catch (error) {
			dispatch(setPostTestEmailTemplate(null));
		}
	};

export const sendPostNewEmailTemplate =
	(payload: INewEmailTemplate) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postNewEmailTemplate(payload);
			const newData: { data?: IEmailTemplates } = await fetchEmailTemplates();

			dispatch(setPostNewEmailTemplate(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setPostNewEmailTemplate(null, null));
		}
	};

export const getFileTemplates =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IFileTemplates } | OriginalResponse = await fetchFileTemplates();

			if (response.data) {
				dispatch(setFileTemplates(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setFileTemplates(null));
		}
	};

export const sendPostChangeFileTemplateStatus =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postChangeFileTemplateStatus(payload);
			const newData: { data?: IFileTemplates } = await fetchFileTemplates();
			dispatch(setPostChangeFileTemplateStatus(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setPostChangeFileTemplateStatus(null, null));
		}
	};

export const downloadFileTemplate =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await fetchDownloadFileTemplate(payload);
			dispatch(setDownloadFileTemplate(response.data));
		} catch (error) {
			dispatch(setDownloadFileTemplate(null));
		}
	};

export const sendPostUploadFileTemplate =
	(payload: Document | FormData | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await postUploadFileTemplate(payload);
			const newData: { data?: IFileTemplates } = await fetchFileTemplates();
			dispatch(setPostUploadFileTemplate(data, newData.data || null));
		} catch (error) {
			dispatch(setPostUploadFileTemplate(null, null));
		}
	};

export const getCachesList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: ICachesListInfo } | OriginalResponse = await fetchCachesList();

			if (response.data) {
				dispatch(setCachesList(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setCachesList(null));
		}
	};

export const sendDeleteSingleCache =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteSingleCache(payload);
			const newData: { data?: ICachesListInfo } = await fetchCachesList();
			dispatch(setSendDeleteSingleCache(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setSendDeleteSingleCache(null, null));
		}
	};

export const sendDeleteSingleCacheByName =
	(payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteSingleCacheByName(payload);
			dispatch(setSendDeleteSingleCache(response?.data, null || null));
			dispatch(setUsersList(null));
		} catch (error) {
			dispatch(setSendDeleteSingleCache(null, null));
		}
	};

export const sendDeleteAllCaches =
	(payload: []) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteAllCaches(payload);
			const newData: { data?: ICachesListInfo } = await fetchCachesList();
			dispatch(setSendDeleteAllCaches(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setSendDeleteAllCaches(null, null));
		}
	};

export const sendDeleteAllUserCaches =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteAllUserCaches();
			const newData: { data?: ICachesListInfo } = await fetchCachesList();
			dispatch(setSendDeleteAllUserCaches(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setSendDeleteAllUserCaches(null, null));
		}
	};

export const sendDeleteAllCompaniesCaches =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteAllCompaniesCaches();
			const newData: { data?: ICachesListInfo } = await fetchCachesList();
			dispatch(setSendDeleteAllCompaniesCaches(response?.data, newData?.data || null));
		} catch (error) {
			dispatch(setSendDeleteAllCompaniesCaches(null, null));
		}
	};

export const sendDeleteAllSingleUserCaches =
	(username: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteAllSingleUserCaches(username);
			dispatch(setSendDeleteAllSingleUserCaches(response || null));
		} catch (error) {
			dispatch(setSendDeleteAllSingleUserCaches(null));
		}
	};

export const getAccessControlList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IAccessControl } | OriginalResponse = await fetchAccessControlList();
			if (response.data) {
				dispatch(setAccessControlList(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAccessControlList(null));
		}
	};
export const getAccessControlListAddUser =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IAccessControl } | OriginalResponse = await fetchAccessControlListAddUser();
			if (response.data) {
				dispatch(setAccessControlListAddUser(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAccessControlListAddUser(null));
		}
	};

export const sendRemoveAllRolesAndAccesses =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await removeAllRolesAndAccesses(payload);
			const newData: { data?: IAccessControl } = await fetchAccessControlList();
			const newData2: { data?: IAccessControl } = await fetchAccessControlListAddUser();
			dispatch(
				setSendRemoveAllRolesAndAccesses(response.data || null, newData.data || null, newData2.data || null)
			);
		} catch (error) {
			dispatch(setSendRemoveAllRolesAndAccesses(null, null, null));
		}
	};

export const getAccessControlEditUser =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGrantAccessControlInfo } | OriginalResponse = await fetchAccessControlEditUser(
				payload
			);
			if (response.data) {
				dispatch(setAccessControlEditUser(response.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAccessControlEditUser(null));
		}
	};

export const sendPutAccessControl =
	(payload: IGrantAccessControl, userId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setAccessControlList(null));
			const response: Success = await putAccessControl(payload, userId);
			const newData: { data?: IAccessControl } = await fetchAccessControlListAddUser();
			const newData2: { data?: IAccessControl } = await fetchAccessControlList();
			dispatch(setPutAccessControl(response?.data || null, newData?.data || null, newData2?.data || null));
			dispatch(setClearEditUser());
		} catch (error) {
			dispatch(setPutAccessControl(null, null, null));
		}
	};

export const clearEditUser =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearEditUser());
	};

export const submitAuthorizationCodeRenewal =
	(code: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const data = await authorizationCodeRenewal(code);
			dispatch(setAuthorizationCodeRenewal(data));
		} catch (error) {
			dispatch(setAuthorizationCodeRenewal(null));
		}
	};

export const clearAdministrationError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setAdministrationError(null));
	};

export const getReleasesList =
	(projectKey: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } | OriginalResponse = await fetchReleasesList(projectKey);
			if (response.data) {
				dispatch(setReleasesList(JSON.parse(response?.data?.releases) || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setReleasesList(null));
		}
	};

export const getReleasesNotesList =
	(id: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } | OriginalResponse = await fetchReleaseNotesList(id);
			if (response.data) {
				dispatch(setReleaseNotesList(JSON.parse(response?.data.releaseNotes) || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setReleaseNotesList(null));
		}
	};

export const getReleaseNotesEmail =
	(id: string, name: string, projName: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IReleaseNotesEmail } | OriginalResponse = await fetchReleaseNotesEmail(
				id,
				name,
				projName
			);
			if (response.data) {
				dispatch(setReleaseNotesEmail(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setReleaseNotesEmail(null));
		}
	};

export const sendPostReleaseNotesEmail =
	(payload: IReleaseNotesEmail) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await sendReleaseNotesEmail(payload);
			dispatch(setPostReleaseNotesEmail(response.data || null));
		} catch (error) {
			dispatch(setPostReleaseNotesEmail(null));
		}
	};

export const sendResetAllNewReleasePopups =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await resetAllNewReleasePopups();
			dispatch(setPostReleaseNotesEmail(response.data || null));
		} catch (error) {
			dispatch(setPostReleaseNotesEmail(null));
		}
	};

export const getJobExecutions =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await getJobExecutionsList();
			if (response.data) {
				dispatch(setJobExecutions(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setJobExecutions(null));
		}
	};

//Responsible to delete a singled job execution
export const deleteJobExecution =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await fetchDeleteJobExecution(id);
			if (response.data) {
				dispatch(setDeleteJobExecution(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setDeleteJobExecution(null));
		}
	};

//Responsible to delete all unfinished job executions
export const deleteAllUnfinishedJobExecutions =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await fetchDeleteAllUnfinishedJobExecutions();
			if (response.data) {
				dispatch(setDeleteJobExecution(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setDeleteJobExecution(null));
		}
	};

//Executes a job
export const executeJob =
	(jobId: string, withError: boolean) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await fetchJobExecution(jobId, withError);
			if (response.data) {
				dispatch(setJobExecutions(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setJobExecutions(null));
		}
	};

//Executes a job
export const getAllJobs =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await fetchAllJobs();
			if (response.data) {
				dispatch(setAllJobs(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAllJobs(null));
		}
	};

export const getAppLinks =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await getAppLinksList();
			if (response.data) {
				dispatch(setAppLinks(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppLinks(null));
		}
	};

export const deleteAppLink =
	appLinkId =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await deleteApp(appLinkId);
			if (response.data) {
				dispatch(setAppLinks(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppLinks(null));
		}
	};

export const editAppLink =
	formData =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await postAppLink(formData);
			if (response.data) {
				dispatch(setAppLinks(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppLinks(null));
		}
	};

export const editAppLinkNoImage =
	formData =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await postAppLinkNoImage(formData);
			if (response.data) {
				dispatch(setAppLinks(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppLinks(null));
		}
	};

export const createAppLink =
	formData =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IJobExecution } | OriginalResponse = await postAddAppLink(formData);
			if (response.data) {
				dispatch(setAppLinks(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppLinks(null));
		}
	};

export const getAppRoles =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IAppRoleData } | OriginalResponse = await fetchAppRolesList();
			if (response.data) {
				dispatch(setAppRoles(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAppRoles(null));
		}
	};

//Responsible to delete a singled app role
export const deleteAppRoleById =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IAppRole } | OriginalResponse = await deleteAppRole(id);
			if (response.data) {
				dispatch(setDeleteAppRole(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setDeleteAppRole(null));
		}
	};

export const getGuidesData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchAllGuides();
			if (response.data) {
				dispatch(setAllGuides(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setAllGuides(null));
		}
	};

export const postUploadGuidesData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setAllGuides(null));
			const { data } = await postUploadGuides(payload);
			dispatch(setPostUploadGuides(data));

			const response = await fetchAllGuides();
			if (response.data) {
				dispatch(setAllGuides(response?.data || null));
			} else {
				dispatch(setAdministrationError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setPostUploadGuides(null));
		}
	};
